import { useSWRConfig } from 'swr';
import { drawReq } from '../..';
import useSWRMutation from 'swr/mutation';

export interface UseDrawCreateProps {
  drawResult: any;
  isErrorDraw: any;
  triggerDraw: any;
}

export function useDrawCreate(locId: any): UseDrawCreateProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL'];
  const { fetcher } = useSWRConfig();

  async function sendRequest(url, { arg }: { arg: drawReq}) {
    return fetcher && fetcher(url, {method: 'POST', body: JSON.stringify(arg)});
  };

  const { data, error, trigger } = useSWRMutation(`${apiUrl}/v1/locs/${locId}/draw/create`, sendRequest);

  return {
    drawResult: data,
    isErrorDraw: error,
    triggerDraw: trigger
  };
}

export default useDrawCreate;
