import { Alert, AlertProps, Button, Col, Row } from 'react-bootstrap';
import { FormattedMessageDiv } from '@odmonorepo/core-components';
import { useNavigate } from 'react-router';

interface IFEligibleSignUpProps extends AlertProps {}

export function IFEligibleSignUp({ ...props }: IFEligibleSignUpProps) {
  const navigate = useNavigate();

  return (
    <Alert variant="success" {...props}>
      <Row>
        <Col>
          <FormattedMessageDiv
            className="fs-6 fw-bold"
            id="loc.instantFundingEligibleHeader"
          />
          <FormattedMessageDiv id="loc.instantFundingEligiblePara" />
        </Col>
        <Col xs={12} sm={4} className="pt-3 text-center text-sm-end">
          <Button
            onClick={() => navigate('/instantfunding')}
            variant="outline-primary"
          >
            <FormattedMessageDiv
              id="loc.instantFundingEligibleSignUpBtn"
              className="fs-7"
            />
          </Button>
        </Col>
      </Row>
    </Alert>
  );
}
