import { useEffect } from 'react';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

export interface getTransactionCSVProps {
  isError: any;
  openCSV: () => void;
  isLoading: boolean;
}

export function getTransactionCSV(): getTransactionCSVProps {
  const { fetcher } = useSWRConfig();
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL'] + '/v1/loans/transactions';

  const sendRequest = (url) => fetcher && fetcher(url, { returnType: 'TEXT' });
  const { data: jwt, error, isMutating, trigger } = useSWRMutation(apiUrl + '/token', sendRequest);

  const openNewWindow = () => window.open(`${apiUrl}/csv/jwt/${jwt}`, '_blank');
  const openCSV = () => (jwt ? openNewWindow() : trigger());
  useEffect(() => {jwt && openNewWindow()}, [jwt]);

  return {
    isError: error,
    isLoading: isMutating,
    openCSV,
  };
}

export default getTransactionCSV;
