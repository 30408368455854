import {
  FormattedMessageDiv,
  FormattedNumberDiv,
} from '@odmonorepo/core-components';
import { CardProps, Button, Range, Tooltip } from '@odmonorepo/core-components';
import { Row, Col, Stack } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export type LoanDetailsProps = CardProps & {
  loanId: string;
  title: string;
  loanAmount: number;
  leftPosNumber: number;
  rightPosNumber: number;
  showRangePercentage?: boolean;
  percentage?: number;
  btnOnClick?: () => void;
};

export const LoanDetails = ({
  loanId,
  title,
  loanAmount,
  leftPosNumber,
  rightPosNumber,
  percentage,
  showRangePercentage = false,
  btnOnClick,
  ...props
}: LoanDetailsProps) => {
  const intl = useIntl();
  const formattedAmount = intl.formatNumber(loanAmount, {
    currency: 'USD',
    style: 'currency',
    maximumFractionDigits: 0,
  });

  return (
    <Stack gap={5} {...props}>
      <Row xs={1} sm={2}>
        <h4 className="h6 col fw-bold d-flex">
          {formattedAmount + ' ' + title}
        </h4>
        <FormattedMessageDiv
          className="col small text-start text-sm-end"
          id="loanDetails.loanId.display"
          suffix={loanId}
        />
      </Row>
      <Row>
        <Col xs={12} md={btnOnClick ? 8 : 12}>
          <Range
            value={leftPosNumber}
            max={leftPosNumber + rightPosNumber}
            label={showRangePercentage}
            percentage={percentage}
          />
          <div className="d-flex justify-content-between">
            <div>
              <FormattedNumberDiv
                style="currency"
                currency="USD"
                value={leftPosNumber}
              />
              {title === 'Term Loan' ? (
                <FormattedMessageDiv
                  className="small text-muted"
                  id="loanDetails.totalPaid.message"
                />
              ) : (
                <FormattedMessageDiv
                  className="small text-muted"
                  id="loc.availableCredit.message"
                />
              )}
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <FormattedNumberDiv
                  style="currency"
                  currency="USD"
                  value={rightPosNumber}
                />
                <Tooltip>
                  {title === 'Term Loan' ? (
                    <FormattedMessageDiv id="loanDetails.currentBalance.tooltip.message" />
                  ) : (
                    <FormattedMessageDiv id="loc.currentBalance.tooltip.message" />
                  )}
                </Tooltip>
              </div>
              <FormattedMessageDiv
                className="small text-muted"
                id="loanDetails.currentBalance.message"
              />
            </div>
          </div>
        </Col>
        {loanId && btnOnClick && (
          <Col xs={12} md={4} className="mt-5 mt-md-0 text-center text-md-end">
            <Button onClick={btnOnClick}>
              <FormattedMessage id="loc.drawFunds" />
            </Button>
          </Col>
        )}
      </Row>
    </Stack>
  );
};

export default LoanDetails;
