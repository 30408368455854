export const pricingSuccessMock = {
    "lineAmount": 125000.0,
    "disbursementAmount": 125000.0,
    "totalAmountPaidBack": 153750.24,
    "averageMonthlyPayment": 6500.24,
    "loanOriginationFee": 100.24,
    "totalLoanCost": 153758.34,
    "term": 12,
    "paymentFrequency": "Daily",
    "trueAPR": 42.7907,
    "trueCentsOnDollar": 1.23,
    "payment": 610.12,
    "numberOfPayments": 252,
    "modelId": "zdfd2234-dfdsf56-676hjhg-78hjjh"
  }
