import React, {useEffect, useState} from 'react';
import {
  useParties,
  useOffers,
  getSalesAgentInfo,
  useLoanValidation,
  DUPLICATE_TAX_ID_ERROR,
  TAX_ID_MISMATCH_ERROR
} from 'merchant-lib';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  BlueBlob,
  ErrorDiv,
  FormattedMessageDiv,
  FormattedNumberDiv,
  LoadingAnimation
} from '@odmonorepo/core-components';
import {Collapse, Stack, Button, Alert} from 'react-bootstrap';
import { formatPhone } from '@odmonorepo/odfunctions';
import { offerHelper } from '../../helpers';
import { useNavigate } from 'react-router-dom';

export const OfferNotification = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ hasValidTaxId, setHasValidTaxId ] = useState<boolean>(true);

  const { showNotification, navigateToCheckout } = offerHelper()
  const { isLoadingParties, isErrorParties, isOptSalesAgentEnabled, optimizely } = useParties();
  const { tlOffer, locOffer, product, selectedOffer, isLoadingOffers, isErrorOffers, isPartnerChannel } = useOffers();
  const { salesAgentInfo, isSalesAgentInfoError} = getSalesAgentInfo();
  const { data, isLoadingLoanValidation } = useLoanValidation();

  const formattedPhone = salesAgentInfo?.phone ? formatPhone(salesAgentInfo.phone) : formatPhone(`${8889983483}`)

  useEffect(() => {
    if (!isLoadingLoanValidation && data) {
      setHasValidTaxId(data?.valid ? true : !data.errorMessage?.filter(error => error.description === DUPLICATE_TAX_ID_ERROR || error.description === TAX_ID_MISMATCH_ERROR).length);
    }
  }, [isLoadingLoanValidation, data]);

  if (isLoadingLoanValidation)
    return (
      <Stack>
        <LoadingAnimation className="mx-auto" />
      </Stack>
    );
  if (isErrorParties || isErrorOffers) return null;
  if (isLoadingParties || isLoadingOffers) return null;

  const MaxOffer = (offer) => (
    <>
      <h4 className="text-secondary text-center">
        <FormattedNumberDiv
          prefix={intl.formatMessage({ id: 'notification.title.openOffer' })}
          currency="USD"
          style="currency"
          value={offer?.maxLineAmount}
          suffix="."
          maximumFractionDigits={0}
        />
      </h4>
      {hasValidTaxId &&
        <div>
          <Button onClick={() => optimizely?.monorepoOfferPresentment ? navigate('/offer') : navigateToCheckout()}>
            <FormattedMessage id="notification.callToAction.openOffer"/>
          </Button>
        </div>
      }
    </>
  );

  const DualOrLOCOffer = (locOffer) => (
    <>
      <h4 className="text-secondary text-center">
        {locOffer?.maxLineAmount ? (
          <FormattedNumberDiv
            prefix={intl.formatMessage({ id: 'notification.title.locOffer' })}
            currency="USD"
            style="currency"
            value={locOffer?.maxLineAmount}
            suffix="."
            maximumFractionDigits={0}
          />
        ) : (
          <FormattedMessage id="notification.title.dualOffer" />
        )}
      </h4>
      <div>
        {isSalesAgentInfoError ?
          <ErrorDiv className="p-6 text-center" errorMsg={intl.formatMessage({ id: 'salesAgentInfo.error.loading' })} /> :
          isOptSalesAgentEnabled && hasValidTaxId  ?
            <Button className='text-white'>
              <FormattedMessageDiv id="salesAgentInfo.phone" values={{
                phoneNumber: <a className='text-decoration-none' href={'tel:' + formattedPhone}>{formattedPhone}</a>}}/>
            </Button> :
            <Button className='text-white' href={intl.formatMessage({id: 'notification.csNumber.helpline'})}>
              <FormattedMessage id="notification.callToAction.csNumber" />
            </Button>}
      </div>
    </>
  );

  const RenderNotification = () => {
    if (selectedOffer) return <MaxOffer {...selectedOffer} />;
    if (!isPartnerChannel) {
      switch (product) {
        case 'DualProduct': return <DualOrLOCOffer />;
        case 'LineOfCredit': return <DualOrLOCOffer {...locOffer} />;
        case 'TermLoan': return <MaxOffer {...tlOffer} />;
        default: return null;
      }
    }
  };

  return (
    <Collapse in={showNotification && !(isPartnerChannel && !selectedOffer)}>
      <div>
        <BlueBlob>
          { !hasValidTaxId &&
            <Alert className="text-start" variant={"danger"} dismissible={false}>
              <FormattedMessageDiv className="alert-heading text-start" id="notification.alert.title" />
              <FormattedMessageDiv
                id="notification.alert.body"
                values={{
                  agentName: salesAgentInfo?.name ? salesAgentInfo.name : "us",
                  agentPhoneNumber: <a className="text-decoration-underline" href={'tel:' + formattedPhone}>{formattedPhone}</a>
              }}/>
            </Alert>
          }
          <Stack gap={3}>
            <RenderNotification />
            { !isSalesAgentInfoError && hasValidTaxId && <div
              role="button"
              className="text-primary fw-semibold fs-7"
              onClick={() => sessionStorage.setItem('dismiss', 'notificationDismiss')}
            >
              <FormattedMessageDiv id="notification.dismissMessage.openOffer" />
            </div>
            }
          </Stack>
        </BlueBlob>
      </div>
    </Collapse>
  );
};

export default OfferNotification;
