import { ListCardProps } from '@odmonorepo/core-components';
import { BusinessProfile } from 'merchant-lib';
import { findObjWithType, formatAddress } from '@odmonorepo/odfunctions';

export const rawBusinessToList = (rawBusiness: BusinessProfile) => {
  const businessAddress = findObjWithType(
    rawBusiness.addresses,
    'PHYSICAL_ADDRESS'
  );
  const formattedAddress = businessAddress && formatAddress(businessAddress);
  const phoneNumber = findObjWithType(rawBusiness.contacts, 'PHONE_NUMBER');
  const taxID = findObjWithType(rawBusiness.identifiers, 'TAX_ID');
  const bankAcc = findObjWithType(rawBusiness.identifiers, 'ABN');
  const formattedLegalEntityType =
    rawBusiness.legalEntityType &&
    rawBusiness.legalEntityType
      .split('_')
      .map((segment) =>
        ['LLC', 'LLP', 'LP'].includes(segment)
          ? segment
          : `${segment[0]}${segment.slice(1).toLowerCase()}`
      )
      .join(' ');

  return {
    title: 'Business Info',
    listObj: {
      'Legal Business Name': rawBusiness.legalName
        ? rawBusiness.legalName
        : '-',
      'Tax ID': taxID ? taxID.value : '-',
      'Entity Type': formattedLegalEntityType ? formattedLegalEntityType : '-',
      Phone: phoneNumber ? phoneNumber?.value : '-',
      'Business Address': formattedAddress ? formattedAddress : '-',
      'Business Bank Account': bankAcc ? bankAcc?.value : '-',
    },
  } as ListCardProps;
};
