import { useEffect, useState } from 'react';
import styles from './loginServer.module.css';
import { LoadingAnimation, OnDeckLogo } from '@odmonorepo/core-components';
import { Col, Row, Card, Stack, Spinner, Alert } from 'react-bootstrap';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'login-page': any;
    }
  }
}

export const LoginServer = () => {
  const [wcLoading, setWcLoading] = useState(true);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.NX_PUBLIC_LOGIN_PAGE_JS_SRC!;
    document.body.appendChild(script);

    script.onload = () => setWcLoading(false);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Row className="vh-100">
      <Col xs={12} md={6} className="m-auto" style={{ width: '516px' }}>
        <h1 className="h2 mb-6 text-center">Welcome Back!</h1>
        <Card className="p-7 pb-2">
          <OnDeckLogo width="200px" className="mx-auto mb-0 text-center" />
          {wcLoading ? (
            <Stack className="py-5 mb-2">
              <LoadingAnimation className="mx-auto py-8 my-8" />
            </Stack>
          ) : (
            <login-page
              className={styles.container}
              captcha-engine="RECAPTCHA"
              captcha-key={process.env.NX_PUBLIC_LOGIN_SERVER_CAPTCHA_KEY}
              tenant-id={process.env.NX_PUBLIC_LOGIN_SERVER_CLIENT_ID}
              login-server-uri={process.env.NX_PUBLIC_LOGIN_SERVER_URL}
              redirect-uri={window.location.href}
              response-mode="form_post"
              login-label="Sign In"
              forgot-password-url={process.env.NX_PUBLIC_LOGIN_RESET_PASSWORD_URL}
            />
          )}
        </Card>
        <div className="text-center mt-6">
          <a
            href={process.env.NX_PUBLIC_LOGIN_RESET_PASSWORD_URL}
            title="Forgot Password"
            id="link-reset"
          >
            Forgot your password?
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default LoginServer;
