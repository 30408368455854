import useSWR from 'swr';
import { Profile } from '../../Helpers/types';

export interface PersonProps {
  isPersonLoading: boolean;
  personDetails: Profile;
  isPersonError: any;
}

export function getPerson(id: string): PersonProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/persons/${id}`)
  return {
    personDetails: data,
    isPersonLoading: isLoading,
    isPersonError: error
  }
}

export default getPerson;
