export const detailedOffersMock = {
  "customerData": {
    "businessCountry": "USA",
    "businessState": "MN",
    "legalEntity": "Corporation"
  },
  "loanDetails": {
    "remainingPrincipal": 4739.43,
    "remainingInterest": 89.05,
    "loanAmount": 18000,
    "disbursementAmount": 17460,
    "paymentFrequency": "Daily",
    "term": 3
  },
  "applicationOffer": {
    "experienceType": "configurable",
    "selectedOffer": "cec84de6-e1c8-11ec-9231-059b111d1fa2",
    "offers": [
      {
        "offerId": "cec84de6-e1c8-11ec-9231-059b111d1fa2",
        "term": 12,
        "maxLineAmount": 65000,
        "minLineAmount": 10000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.45,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 65000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 31.8532,
          "paymentFrequency": "Daily",
          "payment": 128.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 6,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57,
          "monthlyFee": 20
        },
        "presentableDisbursementAmt": 44010.57,
        "requiredInitialDraw": 100.45,
        "returnedPaymentFee": 25.01,
        "lateFee": 10.15,
        "prePaymentDiscountPct": 100,
        "prePaymentDiscountRelatedOfferIds": {
          "25": "8de564ec-3e5d-432f-b062-c4d6f7bbe97b"
        }
      },
      {
        "offerId": "dec84de6-e1c8-ed32-1234-059b111d1fa3",
        "term": 9,
        "maxLineAmount": 45000,
        "minLineAmount": 8000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.35,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 45000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 32.8532,
          "paymentFrequency": "Daily",
          "payment": 228.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 9,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57
        },
        "presentableDisbursementAmt": 44010.57,
        "prePaymentDiscountPct": 100,
        "prePaymentDiscountRelatedOfferIds": {
          "25": "b497f856-7dfd-4294-ae92-1890bdf22771"
        }
      },
      {
        "offerId": "fec84de6-e1c8-ad32-8542-059b111d1fa4",
        "term": 6,
        "maxLineAmount": 35000,
        "minLineAmount": 5000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.25,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 35000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 33.8532,
          "paymentFrequency": "Daily",
          "payment": 328.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 12,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57,
          "monthlyFee": 20
        },
        "presentableDisbursementAmt": 44010.57,
        "returnedPaymentFee": 25.0,
        "requiredInitialDraw": 0.0,
        "lateFee": 10.0,
        "prePaymentDiscountPct": null,
        "prePaymentDiscountRelatedOfferIds": {}
      },
      {
        "offerId": "8de564ec-3e5d-432f-b062-c4d6f7bbe97b",
        "term": 12,
        "maxLineAmount": 65000,
        "minLineAmount": 10000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.45,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 65000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 31.8532,
          "paymentFrequency": "Daily",
          "payment": 128.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 6,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57,
          "monthlyFee": 20
        },
        "presentableDisbursementAmt": 44010.57,
        "requiredInitialDraw": 100.45,
        "returnedPaymentFee": 25.01,
        "lateFee": 10.15,
        "prePaymentDiscountPct": null,
        "prePaymentDiscountRelatedOfferIds": {
          "100": "cec84de6-e1c8-11ec-9231-059b111d1fa2"
        }
      }
    ]
  }
}

export const detailedOffersLOCMock = {
  "customerData": {
    "businessCountry": "USA",
    "businessState": "MN",
    "legalEntity": "Corporation"
  },
  "loanDetails": {
    "remainingPrincipal": 4739.43,
    "remainingInterest": 89.05,
    "loanAmount": 18000,
    "disbursementAmount": 17460,
    "paymentFrequency": "Daily",
    "term": 3
  },
  "applicationOffer": {
    "experienceType": "configurable",
    "selectedOffer": "cec84de6-e1c8-11ec-9231-059b111d1fa2",
    "offers": [
      {
        "offerId": "cec84de6-e1c8-11ec-9231-059b111d1fa2",
        "term": 12,
        "maxLineAmount": 65000,
        "minLineAmount": 10000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.45,
        "product": "LineOfCredit",
        "pricedDetails": {
          "lineAmount": 65000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 31.8532,
          "paymentFrequency": "Daily",
          "payment": 128.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 6,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57,
          "monthlyFee": 20
        },
        "presentableDisbursementAmt": 44010.57,
        "requiredInitialDraw": 100.45,
        "returnedPaymentFee": 25.01,
        "lateFee": 10.15,
        "prePaymentDiscountPct": 100,
        "prePaymentDiscountRelatedOfferIds": {
          "25": "8de564ec-3e5d-432f-b062-c4d6f7bbe97b"
        }
      },
      {
        "offerId": "dec84de6-e1c8-ed32-1234-059b111d1fa3",
        "term": 9,
        "maxLineAmount": 45000,
        "minLineAmount": 8000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.35,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 45000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 32.8532,
          "paymentFrequency": "Daily",
          "payment": 228.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 9,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57
        },
        "presentableDisbursementAmt": 44010.57,
        "prePaymentDiscountPct": 100,
        "prePaymentDiscountRelatedOfferIds": {
          "25": "b497f856-7dfd-4294-ae92-1890bdf22771"
        }
      },
      {
        "offerId": "fec84de6-e1c8-ad32-8542-059b111d1fa4",
        "term": 6,
        "maxLineAmount": 35000,
        "minLineAmount": 5000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.25,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 35000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 33.8532,
          "paymentFrequency": "Daily",
          "payment": 328.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 12,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57,
          "monthlyFee": 20
        },
        "presentableDisbursementAmt": 44010.57,
        "returnedPaymentFee": 25.0,
        "requiredInitialDraw": 0.0,
        "lateFee": 10.0,
        "prePaymentDiscountPct": null,
        "prePaymentDiscountRelatedOfferIds": {}
      },
      {
        "offerId": "8de564ec-3e5d-432f-b062-c4d6f7bbe97b",
        "term": 12,
        "maxLineAmount": 65000,
        "minLineAmount": 10000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.45,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 65000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 31.8532,
          "paymentFrequency": "Daily",
          "payment": 128.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 6,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57,
          "monthlyFee": 20
        },
        "presentableDisbursementAmt": 44010.57,
        "requiredInitialDraw": 100.45,
        "returnedPaymentFee": 25.01,
        "lateFee": 10.15,
        "prePaymentDiscountPct": null,
        "prePaymentDiscountRelatedOfferIds": {
          "100": "cec84de6-e1c8-11ec-9231-059b111d1fa2"
        }
      }
    ]
  }
}

export const no100PPDMock = {
  "customerData": {
    "businessCountry": "USA",
    "businessState": "MN",
    "legalEntity": "Corporation"
  },
  "loanDetails": {
    "remainingPrincipal": 4739.43,
    "remainingInterest": 89.05,
    "loanAmount": 18000,
    "disbursementAmount": 17460,
    "paymentFrequency": "Daily",
    "term": 3
  },
  "applicationOffer": {
    "experienceType": "configurable",
    "selectedOffer": "cec84de6-e1c8-11ec-9231-059b111d1fa2",
    "offers": [
      {
        "offerId": "cec84de6-e1c8-11ec-9231-059b111d1fa2",
        "term": 12,
        "maxLineAmount": 35000,
        "minLineAmount": 6000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.45,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 35000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 31.8532,
          "paymentFrequency": "Daily",
          "payment": 128.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 6,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57,
          "monthlyFee": 20
        },
        "presentableDisbursementAmt": 44010.57,
        "requiredInitialDraw": 100.45,
        "returnedPaymentFee": 25.01,
        "lateFee": 10.15,
        "prePaymentDiscountPct": 25,
        "prePaymentDiscountRelatedOfferIds": {
        }
      },
      {
        "offerId": "dec84de6-e1c8-ed32-1234-059b111d1fa3",
        "term": 9,
        "maxLineAmount": 45000,
        "minLineAmount": 8000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.35,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 45000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 32.8532,
          "paymentFrequency": "Daily",
          "payment": 228.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 9,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57
        },
        "presentableDisbursementAmt": 44010.57,
        "prePaymentDiscountPct": null,
        "prePaymentDiscountRelatedOfferIds": {
        }
      },
      {
        "offerId": "fec84de6-e1c8-ad32-8542-059b111d1fa4",
        "term": 6,
        "maxLineAmount": 50000,
        "minLineAmount": 9000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.25,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 50000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 33.8532,
          "paymentFrequency": "Daily",
          "payment": 328.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 12,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57,
          "monthlyFee": 20
        },
        "presentableDisbursementAmt": 44010.57,
        "returnedPaymentFee": 25.0,
        "requiredInitialDraw": 0.0,
        "lateFee": 10.0,
        "prePaymentDiscountPct": null,
        "prePaymentDiscountRelatedOfferIds": {}
      },
      {
        "offerId": "8de564ec-3e5d-432f-b062-c4d6f7bbe97b",
        "term": 12,
        "maxLineAmount": 35000,
        "minLineAmount": 6000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "centsOnDollar": 1.45,
        "product": "TermLoan",
        "pricedDetails": {
          "lineAmount": 35000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 31.8532,
          "paymentFrequency": "Daily",
          "payment": 128.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 6,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57,
          "monthlyFee": 20
        },
        "presentableDisbursementAmt": 44010.57,
        "requiredInitialDraw": 100.45,
        "returnedPaymentFee": 25.01,
        "lateFee": 10.15,
        "prePaymentDiscountPct": null,
        "prePaymentDiscountRelatedOfferIds": {
        }
      },
      {
        "offerId": "b497f856-7dfd-4294-ae92-1890bdf22771",
        "term": 9,
        "maxLineAmount": 45000,
        "minLineAmount": 8000,
        "defaultPaymentFrequency": "Daily",
        "presentationStatus": "ACTIVE",
        "originationFeePct": 2.5,
        "product": "TermLoan",
        "centsOnDollar": 1.35,
        "pricedDetails": {
          "lineAmount": 45000,
          "trueCentsOnDollar": 1.15,
          "trueAPR": 32.8532,
          "paymentFrequency": "Daily",
          "payment": 228.17,
          "disbursementAmount": 48750,
          "totalAmountPaidBack": 57498.84,
          "loanOriginationFee": 1250,
          "term": 9,
          "totalLoanCost": 8748.84,
          "modelId": "onDeck",
          "averageMonthlyPayment": 4791.57
        },
        "presentableDisbursementAmt": 44010.57,
        "prePaymentDiscountPct": null,
        "prePaymentDiscountRelatedOfferIds": {
        }
      }
    ]
  }
}

