import {
  FormattedMessageDiv,
  LoadingAnimation,
  MoneyInput,
} from '@odmonorepo/core-components';
import { getOTPData } from 'merchant-lib';
import {
  dollarFormat,
  formatDate,
  formatLastFour,
} from 'libs/odfunctions/src/lib/odfunctions';
import { useEffect, useState } from 'react';
import { Alert, Col, Collapse, FormCheck, Row, Stack } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

interface onChangeProps {
  loanId: string;
  paymentAmount: number;
}

interface OTPTabProps {
  type: 'TL' | 'LOC';
  id: string;
  onChange: (onChangeProps, valid: boolean) => void;
}

export const OTPTab = ({ type, id, onChange }: OTPTabProps) => {
  const { OPTData, OPTDataLoading, OPTDataError } = getOTPData(id);
  const [valid, setValid] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState<any>();
  const intl = useIntl();

  const handleChange = (checked) =>
    checked && OPTData?.remainingBalance !== 0
      ? setValid(true)
      : setValid(false);

  useEffect(() => {
    onChange({ loanId: id, paymentAmount }, valid);
  }, [valid, paymentAmount]);

  if (OPTDataError || OPTData?.error)
    return intl.formatMessage({ id: 'OTP.both.error' });

  return (
    <>
      {OPTDataLoading ? (
        <Stack>
          <div className="mx-auto">
            <LoadingAnimation />
          </div>
        </Stack>
      ) : null}
      <Collapse in={!OPTDataLoading}>
        <div>
          {OPTData && (
            <Stack gap={3}>
              <Row>
                <Col xs={12} sm={8}>
                  <FormattedMessageDiv
                    id="OTP.both.remaining"
                    values={{
                      amount: <b>{dollarFormat(OPTData.remainingBalance)}</b>,
                    }}
                  />
                </Col>
                <Col xs="auto" sm={4}>
                  <FormattedMessageDiv
                    className="text-end fs-8"
                    id="OTP.both.loanId"
                    values={{ id }}
                  />
                </Col>
              </Row>
              {type === 'LOC' && (
                <FormattedMessageDiv className="fs-8" id="OTP.LOC.remaining" />
              )}
              <hr />

              <FormattedMessageDiv className="fw-bold" id="OTP.both.payment" />
              <Stack direction="horizontal" gap={2}>
                <input readOnly type="radio" checked={true} />
                <FormattedMessageDiv id="OTP.both.custom" className="fs-7" />
              </Stack>
              <MoneyInput
                min={OPTData?.lowerDraw}
                max={OPTData?.upperDraw}
                onChange={(v) => setPaymentAmount(v)}
                decimalScale={2}
                upperErrorMessage={intl.formatMessage({
                  id: `OTP.overpayment.${type}`,
                })}
              />
              {type === 'TL' && paymentAmount >= OPTData?.upperDraw && (
                <Alert variant="warning">
                  <FormattedMessage
                    id={'OTP.overPaymentAlert.TL'}
                    values={{
                      tel: (txt: any) => (
                        <a href={`tel:${txt}`} className="fw-bold">
                          {txt}
                        </a>
                      ),
                    }}
                  />
                </Alert>
              )}
              {type === 'LOC' && (
                <FormattedMessageDiv className="fs-8" id="OTP.LOC.customInfo" />
              )}
              <hr />
              {OPTData?.nextBusinessDay && (
                <Row xs={1} sm={2} className="gy-5">
                  <Stack gap={1} className="col">
                    <FormattedMessageDiv
                      className="fw-bold"
                      id="OTP.both.date"
                    />
                    <FormattedMessageDiv id="OTP.both.nextBusinessDay" />
                  </Stack>
                  <Stack gap={1} className="col">
                    <FormattedMessageDiv
                      className="fw-bold"
                      id="OTP.both.from"
                    />
                    {(OPTData?.bankName ? OPTData?.bankName : '') +
                      ' ' +
                      formatLastFour(OPTData.bankAccount)}
                  </Stack>
                </Row>
              )}

              {OPTData?.nextPaymentDate && (
                <Alert variant="info">
                  <FormattedMessageDiv
                    id={`OTP.${type}.info`}
                    values={{
                      amount: <b>{dollarFormat(OPTData.nextPaymentAmount)}</b>,
                      date: <b>{formatDate(OPTData.nextPaymentDate)}</b>,
                    }}
                  />
                </Alert>
              )}

              <FormattedMessageDiv
                className="fs-8"
                id={`OTP.${type}.disclaimer`}
              />

              <FormCheck
                label={
                  <FormattedMessageDiv
                    className="fs-8"
                    id={`OTP.both.checkText`}
                  />
                }
                onChange={(e) => handleChange(e.target.checked)}
              />
            </Stack>
          )}
        </div>
      </Collapse>
    </>
  );
};

export default OTPTab;
