import { useEffect, useState } from 'react';
import { Stack, Badge, Collapse, Button } from 'react-bootstrap';
import {
  Card,
  Caret,
  ErrorCard,
  FormattedMessageDiv,
  LoadingCard,
  ToggleCardGroup,
} from '@odmonorepo/core-components';
import { useLOC, useParties } from 'merchant-lib';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

export interface FundingMethodCardProps {
  fundingMethod?: string;
  drawAmount: number;
  updateFundingMethod: (val: string) => void;
}

export const FundingMethodCard = ({
  drawAmount,
  updateFundingMethod,
  fundingMethod,
}: FundingMethodCardProps) => {
  const [open, setOpen] = useState(false);
  const { ifOptEnabled } = useParties();
  const { activeLOC, locError, locLoading, ifEnabled } = useLOC();
  const intl = useIntl();
  const navigate = useNavigate();
  const card = activeLOC?.instantFunding?.cardInfo;

  useEffect(() => {
    ifEnabled && updateFundingMethod('INSTANT_FUNDING');
  }, [ifEnabled]);

  if (locError)
    return <ErrorCard name={intl.formatMessage({ id: 'loading.error' })} />;
  if (locLoading) return <LoadingCard />;

  return (
    <Card bodyClassName="p-4 user-select-none">
      <Stack
        gap={4}
        className="d-flex justify-content-between"
        direction="horizontal"
        onClick={() => setOpen((o) => !o)}
        role="button"
      >
        <div className="d-flex">
          <Caret className="mx-3" direction={open ? 'up' : 'down'} />
          <FormattedMessageDiv
            className="fw-bold fs-6"
            id="drawFunds.reviewFundingMethod"
          />
        </div>
        <div className="justify-content-end fs-8">
          {fundingMethod === 'ACH' ? 'ACH' : 'Instant Funding'}
        </div>
      </Stack>
      <Collapse in={open}>
        <Stack className="fs-7 mt-3 text-start">
          <ToggleCardGroup
            selectedIdx={
              fundingMethod === 'INSTANT_FUNDING' && drawAmount <= 10000 ? 0 : 1
            }
            value={['INSTANT_FUNDING','ACH']}
            disabledIndex={drawAmount > 10000 ? [0] : []}
            className="gap-4 p-4 d-flex flex-column flex-md-row"
            cardClassName="h-100 p-3 shadow-none"
            flexDirection="row"
            onChange={(e: any) => {updateFundingMethod(e)}}
            hideIdx={ifEnabled ? undefined : 0}
          >
            <Stack>
              <div className="d-flex justify-content-between">
                <FormattedMessageDiv
                  className="fw-bold"
                  id="drawFunds.instantFunding"
                />
                <Badge
                  bg="info"
                  pill
                  className="d-flex justify-content-center align-items-center"
                >
                  Fastest
                </Badge>
              </div>
              <FormattedMessageDiv
                id={
                  drawAmount > 10000
                    ? 'drawFunds.instantFunding.unableToDraw'
                    : 'drawFunds.instantFunding.description'
                }
              />
              {ifOptEnabled && card?.registered && (
                <FormattedMessageDiv
                  className="fs-8 mt-3"
                  id="instantfunding.draw.cardInfo"
                  values={{
                    network: (
                      <>
                        {card.network &&
                          card.network[0].toUpperCase() +
                            card.network.substring(1).toLowerCase()}
                      </>
                    ),
                    lastFour: <>{card.account}</>,
                  }}
                />
              )}
              {ifOptEnabled && !card?.registered && (
                <Button
                  onClick={() => navigate('/instantfunding')}
                  className="col-lg-4 mt-3 fs-7"
                  variant="outline-primary"
                >
                  Sign Up
                </Button>
              )}
            </Stack>
            <Stack>
              <FormattedMessageDiv className="fw-bold" id="drawFunds.ach" />
              <FormattedMessageDiv id="drawFunds.ach.description" />
            </Stack>
          </ToggleCardGroup>
        </Stack>
      </Collapse>
    </Card>
  );
};

export default FundingMethodCard;
