import { Documents } from 'merchant-lib';
import { DocumentData } from '../Components/DocumentFilterList';

export const filterRawDocuments = (rawDocuments: Documents[], validDocumentTypes: string[]) => {
  const filteredDocuments = rawDocuments.reduce<DocumentData[]>(
    (documentList: DocumentData[], currDocument: Documents) => {
      if (!validDocumentTypes.includes(currDocument?.documentType)) {
        return documentList; // Exclude document if type is not valid
      }


      documentList.push({
        loanId: currDocument?.loanId?.toString().slice(-4),
        loanType:
          currDocument?.loanType === 'FIXED_TERM'
            ? 'Term Loan'
            : currDocument?.loanType === 'LINE_OF_CREDIT'
              ? 'Line of Credit'
              : '',
        documentType: currDocument?.documentType,
        fileName: currDocument?.fileName.split('.')[0],
        createdOn: new Date(currDocument?.createdOn),
        documentId: currDocument?.documentId.toString(),
      });
      return documentList;
    },
    []
  );

  return filteredDocuments as DocumentData[];
};
