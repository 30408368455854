import { Range } from 'react-range';
import { RenderTrack } from '../Range/Range';

type SteppedRangeProps = JSX.IntrinsicElements['div'] & {
  steps: string[];
  stepIndex: number;
}

export const SteppedRange = ({
  className,
  steps,
  stepIndex = 0,
}: SteppedRangeProps) => {

  return (
    <div className={"fs-7 " + className}>

    <SmallSteppedRange steps={steps} stepIndex={stepIndex}/>
    <div className='pt-5 d-none d-md-block'>
      <Range
      min={0}
      max={steps.length - 1}
      values={[stepIndex]}
      renderTrack={({ props, children }) => (
        <RenderTrack props={props} sliderValues={[stepIndex]} min={-.1} max={steps.length - 1}>
          {children}
        </RenderTrack>
      )}
      renderMark={({ props, index }) => (
        <div {...props}
          className='text-center'
          style={{
            ...props.style,
            marginTop: '-40px',
            whiteSpace: 'nowrap',
            transform: index === 0 ? 'translateX(50%)' : index === steps.length-1 ? 'translateX(-50%)' : ''
          }}>
            {steps[index]}
        </div>
      )}
      renderThumb={() => null}
      onChange={()=>{}}
      />
      </div>
    </div>
  );
};

export const SmallSteppedRange = ({
  steps,
  stepIndex = 0,
}: SteppedRangeProps) => {

  return (
    <div className="d-block d-md-none">
      <div>{steps[stepIndex]}</div>
      <Range
      min={0}
      max={steps.length - 1}
      values={[stepIndex]}
      renderTrack={({ props, children }) => (
        <RenderTrack props={props} sliderValues={[stepIndex]} min={-.5} max={steps.length - 1}>
          {children}
        </RenderTrack>
      )}
      renderThumb={() => null}
      onChange={()=>{}}
      />
    </div>
  );
};
