import { combineClassNames } from "@odmonorepo/odfunctions";
import ToggleElement from "libs/core-components/src/lib/ToggleElement/ToggleElement";
import { Stack, ButtonGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";

export const CalculatorPaymentFrequency = () => (
    <Stack>
        <div className="fw-bold">
          <FormattedMessage id="calculator.paymentFreqChoices" />
        </div>
        <Controller
          name="frequencyChoices"
          render={({ field: { onChange, value, name } }) => (
            <ButtonGroup>
              {['Daily', 'Weekly'].map((f) => (
                <ToggleElement
                  value={f}
                  name={name}
                  onChange={onChange}
                  className={combineClassNames(
                    'btn btn-outline-accent w-100',
                    value === f ? 'fw-bold' : '',
                  )}
                >
                  {f}
                </ToggleElement>
              ))}
            </ButtonGroup>
          )}
        />
      </Stack>
)