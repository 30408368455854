import Loading from './Ellipsis-3.6s-200px.svg';

type ImageProps = JSX.IntrinsicElements['div'] & {
  width?: string;
  alt?: string;
};

export const LoadingAnimation = ({ width = '100px', alt = 'Loading', ...props}: ImageProps) =>
  <div {...props}><img src={Loading} width={width} alt={alt} /></div>;

export default LoadingAnimation;
