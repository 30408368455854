import { Alert, Card, Col, Form, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Button, OnDeckLogo } from '@odmonorepo/core-components';
import { useAuth0 } from '../../Helpers/Auth0Provider';

export const Login = () => {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { login, callbackResponse } = useAuth0();

  useEffect(() => {
    if (callbackResponse) {
      switch (callbackResponse?.code) {
        case 'access_denied':
          setErrorMsg(
            intl.formatMessage({ id: 'login.error.accessDenied.message' })
          );
        case 'too_many_attempts':
          setErrorMsg(
            intl.formatMessage({ id: 'login.error.tooManyAttempts.message' })
          );
        default:
          setErrorMsg(callbackResponse?.description);
      }
    }
  }, [callbackResponse, errorMsg]);

  return (
    <Row className="vh-100">
      <Col xs={12} md={6} className="m-auto" style={{ width: '516px' }}>
        <h1 className="h2 mb-6 text-center">Welcome Back!</h1>
        <Card className="p-7">
          <div className="mx-auto mb-6">
            <OnDeckLogo width="200px" />
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              login(email, password);
            }}
            className="mb-0"
          >
            {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
            <div className="form-floating mb-6">
              <Form.Control
                required
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                id="email"
                type="email"
              />
              <Form.Label htmlFor="email">Email address</Form.Label>
            </div>
            <div className="form-floating mb-6">
              <Form.Control
                required
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                id="password"
                type="password"
              />
              <Form.Label htmlFor="password">Account password</Form.Label>
            </div>
            <div className="captcha-container form-group"></div>
            <div className="text-center">
              <Button type="submit" id="btn-login" className=" mb-0">
                Sign In
              </Button>
            </div>
          </Form>
        </Card>
        <div className="text-center mt-6">
          <a
            href={process.env.NX_PUBLIC_LOGIN_RESET_PASSWORD_URL}
            title="Forgot Password"
            id="link-reset"
          >
            Forgot your password?
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
