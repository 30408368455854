import { Button, Card, ErrorCard, List, LoadingCard } from '@odmonorepo/core-components';
import { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { getBusiness } from 'merchant-lib';
import { useIntl } from 'react-intl';
import BusinessForm from '../Components/BusinessForm';
import { rawBusinessToList } from '../Transformers/rawBusinessToList';

export const BusinessCard = ({ showEdit = false }: { showEdit?: boolean }) => {
  const [editing, setEditing] = useState(false);
  const { businessDetails, isBusinessError, isBusinessLoading } = getBusiness();
  const businessList =
    businessDetails && rawBusinessToList(businessDetails).listObj;
  const intl = useIntl();

  if (isBusinessError)
    return (
      <ErrorCard name={intl.formatMessage({ id: 'loading.error.profile' })} />
    );
  if (isBusinessLoading) return <LoadingCard />;

  const handleSubmit = () => {};

  return (
    <Card>
      <Stack direction="horizontal">
        <h4>Business Details</h4>
        {showEdit && (
          <Button
            variant="link"
            className="ms-auto p-0"
            onClick={() => setEditing((e) => !e)}
          >
            {editing ? 'Cancel' : 'Edit'}
          </Button>
        )}
      </Stack>
      {editing ? (
        <BusinessForm onSubmit={() => handleSubmit()} />
      ) : (
        <List obj={businessList} />
      )}
    </Card>
  );
};

export default BusinessCard;
