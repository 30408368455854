import imageBBB from './images/footer-bbb.png';
import imageILPA from './images/footer-ilpa.png';
import imageForbes from './images/footer-forbes.png';
import logo from './images/logo-footer.svg';
import facebook from './images/social-facebook.svg';
import instagram from './images/social-instagram.svg';
import linkedin from './images/social-linkedin.svg';
import twitter from './images/social-twitter.svg';

import Accordion from 'react-bootstrap/Accordion';

export function Footer() {
  return (
    <footer>
      <div className="container">
        <nav id="menu-footer" className="row" aria-label="Footer Navigation">
          <div className="col-12 d-lg-none">
            <div className="btn-container text-center mb-3">
              <a href="#" className="btn btn-outline-light text-nowrap">Back to Top</a>
            </div>
            <Accordion id="nav-mobile" className="nav">
              <Accordion.Item eventKey="0" className="nav-item w-100">
                <Accordion.Header as="div">Small Business Loans</Accordion.Header>
                <Accordion.Body>
                  <ul className="nav flex-column">
                    <li className="nav-item"><a href="https://ondeck.com/business-line-of-credit">Business Line of Credit</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/short-term-loans">Term Loan</a></li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="nav-item w-100">
                <Accordion.Header as="div">Resources</Accordion.Header>
                <Accordion.Body>
                  <ul className="nav flex-column">
                    <li className="nav-item"><a href="https://ondeck.com/">Small Business Loans Explained</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/small-business-trends">Small Business Trends Report</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/resources">Business Blog</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/support">FAQs</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/support">Contact Us</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/restricted-industries">Restricted Industries</a></li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="nav-item w-100">
                <Accordion.Header as="div">About Us</Accordion.Header>
                <Accordion.Body>
                  <ul className="nav flex-column">
                    <li className="nav-item"><a href="https://ondeck.com/company">Who We Are</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/partner">Partner With Us</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/in-the-news">In The News</a></li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="nav-item w-100">
                <Accordion.Header as="div">Legal</Accordion.Header>
                <Accordion.Body>
                  <ul className="nav flex-column">
                    <li className="nav-item"><a href="https://ondeck.com/privacy">Privacy Policy</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/security-policy">Security Policy</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/">Do Not Sell or Share My Personal Information</a></li>
                    <li className="nav-item"><a href="https://ondeck.com/privacy#notice-to-ca-residents">California Collection Notice</a></li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-12 col-lg-3 d-none d-lg-block">
            <ul className="nav">
              <li className="nav-item">
                Small Business Loans
                <ul className="nav flex-column">
                  <li className="nav-item"><a href="https://ondeck.com/business-line-of-credit">Business Line of Credit</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/short-term-loans">Term Loan</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-3 d-none d-lg-block">
            <ul className="nav">
              <li className="nav-item">
                Resources
                <ul className="nav flex-column">
                  <li className="nav-item"><a href="https://ondeck.com/">Small Business Loans Explained</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/small-business-trends">Small Business Trends Report</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/resources">Business Blog</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/support">FAQs</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/support">Contact Us</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/restricted-industries">Restricted Industries</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-2 d-none d-lg-block">
            <ul className="nav">
              <li className="nav-item">
                About Us
                <ul className="nav flex-column">
                  <li className="nav-item"><a href="https://ondeck.com/company">Who We Are</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/partner">Partner With Us</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/in-the-news">In The News</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-2 d-none d-lg-block">
            <ul className="nav">
              <li className="nav-item">
                Legal
                <ul className="nav flex-column">
                  <li className="nav-item"><a href="https://ondeck.com/privacy">Privacy Policy</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/security-policy">Security Policy</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/">Do Not Sell or Share My Personal Information</a></li>
                  <li className="nav-item"><a href="https://ondeck.com/privacy#notice-to-ca-residents">California Collection Notice</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-2 d-none d-lg-block">
            <a href="#" className="btn btn-outline-light btn-sm text-nowrap">Back to Top</a>
          </div>
        </nav>
        <div id="submenu-footer" className="row align-items-center">
          <div className="col-12 col-lg-3">
            <div id="submenu-logo">
              <a href="https://ondeck.com/">
                <img src={logo} alt="" />
                <span className="visually-hidden">OnDeck</span>
              </a>
            </div>
          </div>
          <nav id="menu-social" className="col-12 col-lg-3" aria-label="OnDeck Social Media">
            <ul className="nav">
              <li className="nav-item">
                <a href="https://www.facebook.com/OnDeckCapital/" target="_blank">
                  <img src={facebook} alt="" />
                  <span className="visually-hidden">OnDeck's Facebook feed</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="https://twitter.com/OnDeckCapital/" target="_blank">
                  <img src={twitter} alt="" />
                  <span className="visually-hidden">OnDeck's Twitter feed</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="https://www.linkedin.com/company/ondeck/" target="_blank">
                  <img src={linkedin} alt="" />
                  <span className="visually-hidden">OnDeck's LinkedIn profile</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="https://www.instagram.com/ondeckcapital/" target="_blank">
                  <img src={instagram} alt="" />
                  <span className="visually-hidden">OnDeck's Instagram feed</span>
                </a>
              </li>
            </ul>
          </nav>
          <nav id="menu-awards" className="col-12 col-lg-6 offset-xl-1 col-xl-5" aria-label="OnDeck Awards and Recognitions">
            <ul className="nav">
              <li className="nav-item">
                <a href="https://www.bbb.org/us/ny/new-york/profile/financial-services/on-deck-capital-inc-0121-101780" target="_blank">
                  <img src={imageBBB} alt="" />
                  <span className="visually-hidden">Better Business Bureau Accedited Business, A+ Rating</span>
                </a>
              </li>
              <li className="nav-item">
                <div className="img-wrapper">
                  <img src={imageILPA} alt="" />
                  <span className="visually-hidden">Innovative Lending Platform Association</span>
                </div>
              </li>
              <li className="nav-item">
                <div className="img-wrapper">
                  <img src={imageForbes} alt="" />
                  <span className="visually-hidden">Forbes Advisor, Best of 2023, Best Small Business Loans</span>
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <div id="footer-disclaimers" className="row">
          <div className="col">
            <ul>
              <li className="small" data-symbol="†">Same-Day Funding is only available in certain states, for term loans up to $100K. Eligibility window is Monday – Friday before 10:30 a.m. ET. If checkout is done before 10:30 a.m. ET, funds will be available by 5 p.m. local time the same day. If checkout is done after 10:30 a.m. ET, or on a weekend or bank holiday, it will not qualify for Same-Day Funding and funds will be deposited within 2 – 3 business days.</li>
              <li className="small" data-symbol="*">Instant Funding requires registration and is subject to the <a href="https://online.ondeck.com/ondeck/terms#instant-funding-terms-and-conditions" target="_blank">Instant Funding Terms & Conditions</a>. Instant Funding is limited to open lines of credit for draws between $1,000 - $10,000, and you can only make one draw request per day. Not all banks or debit card providers participate, and you must register a business debit card matching the information associated with your OnDeck account. Transfers are typically completed within 30 minutes, but may be subject to additional restrictions or delays.</li>
              <li className="small" data-symbol="**">There are some industries we cannot serve (see list of <a href="https://www.ondeck.com/restricted-industries">restricted industries</a>). In addition, OnDeck does not lend to businesses in North Dakota. Other underwriting requirements may apply.</li>
              <li className="small" data-symbol="‡">When you prepay your term loan in full, we will apply a discount on remaining unpaid interest based on the Prepayment Interest Reduction Percentage stated in your loan agreement.</li>
              <li className="small" data-symbol="§">Eligibility for the lowest rates is very limited, available only to businesses with the strongest creditworthiness and cash flows, and typically businesses that have shown an excellent payment history on prior loan products with OnDeck. The average rate for term loans is 56.1% APR and the average rate for lines of credit is 55.9% APR. Averages are based on loans originated in the half-year ending March 31, 2024.</li>
              <li className="small" data-symbol="◊">Depending on your state and other circumstances of your application, you may be required to make a minimum draw of $1,000 at origination.</li>
            </ul>
            <p className="small">Depending on the state where your business is located and other attributes of your business and the loan, your business loan may be issued by a member of the OnDeck family of companies or by Celtic Bank. Your loan agreement will identify the lender prior to your signing. Loans subject to lender approval.</p>
            <p className="small">OnDeck&reg; is a Registered Trademark. All rights reserved.</p>
            <p className="small">4700 W. Daybreak Pkwy., Suite 200, South Jordan, UT 84009</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
