import { ErrorDiv, FormattedMessageDiv, LoadingDiv } from '@odmonorepo/core-components';
import { Stack } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { formatPhone } from '@odmonorepo/odfunctions';
import { getSalesAgentInfo } from 'merchant-lib';


interface SalesAgentInfoCardProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function SalesAgentInfoCard({ ...props }: SalesAgentInfoCardProps) {
  const { salesAgentInfo, isSalesAgentInfoLoading, isSalesAgentInfoError } = getSalesAgentInfo();
  const intl = useIntl();
  const formattedPhone = salesAgentInfo?.phone != null ? formatPhone(`${salesAgentInfo?.phone}`) : null;
  const formattedFax = salesAgentInfo?.fax != null ? formatPhone(`${salesAgentInfo?.fax}`) : null;
  if (isSalesAgentInfoLoading) return <LoadingDiv className="mt-7 text-center" />;
  if (isSalesAgentInfoError)
    return <ErrorDiv className="p-6 text-center border-bottom border-light-medium-gray"
                     errorMsg={intl.formatMessage({ id: 'salesAgentInfo.error.loading' })} />;
  return (
    <div {...props}>
      <div className="m-5">
        <Stack className="fw-bold">
          {salesAgentInfo?.name}
        </Stack>
        <div className="fs-7" >
          <Stack>
            <FormattedMessageDiv id="salesAgent.title.label"></FormattedMessageDiv>
          </Stack>
          <Stack className="text-decoration-none mt-3">
            <a href={'tel:' + formattedPhone}>{formattedPhone}</a>
          </Stack>
          <Stack className="text-decoration-none">
            <a href={'mailto: ' + `${salesAgentInfo?.email}`}>{salesAgentInfo?.email}</a>
          </Stack>
          <Stack>
            <FormattedMessageDiv id="salesAgent.fax.label" values={{ faxNumber: formattedFax }} />
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default SalesAgentInfoCard;
