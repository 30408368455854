import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useEffect, useState } from 'react';
import { useAuth0 } from 'merchant-lib';

export interface ODOProps {
  triggerODOLogin: any;
  postODOError: boolean;
  loadingODO: boolean;
}

export function postODOLogin(): ODOProps {
  const apiUrl = process.env['NX_PUBLIC_ODO_URL'];
  const { fetcher } = useSWRConfig();
  const [loading, setLoading] = useState(false);
  const { getTokenSilently } = useAuth0();

  async function sendRequest(url, { arg }: { arg: any }) {
    const token = await getTokenSilently();
    setLoading(true);
    return (
      fetcher &&
      fetcher(url, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ jwt: token?.toString() }),
      })
    );
  }

  const { data, error, trigger } = useSWRMutation(
    `${apiUrl}/apex/renewal/login`,
    sendRequest
  );

  useEffect(() => {
    (data || error) && setLoading(false);
  }, [data, error]);

  return {
    triggerODOLogin: trigger,
    postODOError: Boolean(error),
    loadingODO: loading,
  };
}
