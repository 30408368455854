import useSWR from 'swr';
import { Application } from '../..';

export interface useBusinessProps {
  isLoadingApplication: boolean;
  applicationData: Application;
  isErrorApplication: any;
}

export function useBusiness(partyId: string): useBusinessProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/applications?partyId=${partyId}&applicationType=RENEWAL`)
 
  return {
    applicationData: data,
    isLoadingApplication: isLoading,
    isErrorApplication: error
  }
}

export default useBusiness;
