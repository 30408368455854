import { useEffect } from 'react';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

export interface useGetDocumentProps {
  isError: any;
  openPDF: () => void;
  isLoading: boolean;
}

export function openDocumentPDF(id): useGetDocumentProps {
  const { fetcher } = useSWRConfig();
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL'] + '/v1/documents';

  const sendRequest = (url) => fetcher && fetcher(url, { returnType: 'TEXT' });

  const { data: jwt, error, isMutating, trigger } = useSWRMutation(
    `${apiUrl}/token/${id}`,
    sendRequest
  );

  const openNewWindow = () => window.open(`${apiUrl}/jwt/${jwt}`, '_blank');

  const openPDF = () => (jwt ? openNewWindow() : trigger());

  useEffect(() => {
    jwt && openNewWindow();
  }, [jwt]);

  return {
    isError: error,
    isLoading: isMutating,
    openPDF,
  };
}

export default openDocumentPDF;
