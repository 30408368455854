import { Button, ErrorCard, ExpandableList, LoadingCard } from '@odmonorepo/core-components';
import { Card, Stack } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDocuments } from 'merchant-lib';
import { useNavigate } from 'react-router';
import { filterRawDocuments } from '../Transformers/filterRawDocuments';
import DocumentLineItem from '../Components/DocumentLineItem';

export function DocumentsCard() {
  const intl = useIntl();
  const { documents, isLoading, isError } = useDocuments();
  const navigate = useNavigate();
  const validDocumentType = [
    'SIGNED_CONTRACT',
    'SIGNED_DISCLOSURE',
    'UNSIGNED_DISCLOSURE',
    'UNSIGNED_CONTRACT',
    'DRAW_DISCLOSURE',
    'BANK_STATEMENT',
    'MONTHLY_STATEMENT',
    'TAX',
    'UNCLASSIFIED']

  if (isError)
    return (
      <ErrorCard name={intl.formatMessage({ id: 'loading.error.documents' })} />
    );
  if (isLoading) return <LoadingCard />;

  return (
    <Card className="p-5 px-0 px-sm-5">
      <Stack>
        <h4 className="px-5 px-sm-0">
          {intl.formatMessage({ id: 'title.documents' })}
        </h4>
        <ExpandableList>
          {filterRawDocuments(documents, validDocumentType).map((item, id) => (
            <DocumentLineItem key={id} {...item} />
          ))}
        </ExpandableList>
        {documents.length > 0 && (
          <Button
            variant="outline-primary"
            className="mx-auto"
            onClick={() => {
              navigate('/documents');
            }}
          >
            {intl.formatMessage({ id: 'btn.viewAll' })}
          </Button>
        )}
      </Stack>
    </Card>
  );
}

export default DocumentsCard;
