import { useIntl, FormattedMessage } from 'react-intl';
import { useState, useEffect } from 'react';
import { Row, Col, Stack, Button, Badge } from 'react-bootstrap';
import {
  Tooltip,
  FormattedMessageDiv,
  FormattedNumberDiv,
} from '@odmonorepo/core-components';
import { useFormContext } from 'react-hook-form';
import { useDetailedOffers } from 'libs/merchant-lib/src/API';
import { useWizard } from 'react-use-wizard';
import { OfferConfigStep } from 'libs/merchant-lib/src/Pages/OfferConfig/OfferConfig';
import { combineClassNames } from '@odmonorepo/odfunctions';
import { useNavigate } from 'react-router-dom';

export function OfferPreview() {
  const intl = useIntl();
  const { goToStep, isLastStep } = useWizard();
  const { watch } = useFormContext();
  const { tlOffers } = useDetailedOffers();
  const navigate = useNavigate();

  useEffect(() => {
  }, [watch('offer')]);

  const selectedOffer = tlOffers.filter(
    (o) => o.offerId === watch('offer.offerId'),
  )[0];
  const loanAmount = watch('offer.loanAmount');
  const simpleView = false;

  const media = window.matchMedia('(max-width: 767px)');
  const [vert, setVert] = useState(media.matches);

  useEffect(() => {
    media.addEventListener('change', function () {
      setVert(media.matches);
    });
    return () =>
      media.removeEventListener('change', function () {
        setVert(media.matches);
      });
  }, [media]);

  return (
    <Stack
      className={combineClassNames("border shadow-none border-secondary important px-6 py-5", vert ? "rounded-bottom-3" : "rounded-end-3 h-100")}
    >
      <FormattedMessageDiv
        className="fw-bold text-center"
        id="offerPreview.header"
      />
      {!simpleView && (
        <Row className="py-4">
          <Col xs className="text-center">
            <FormattedMessageDiv
              className="fw-bold fs-7"
              id="offerPreview.term"
            />
            <FormattedNumberDiv
              className="h5 mb-0"
              value={selectedOffer?.term}
              suffix={` ${intl.formatMessage({ id: 'offerPreview.months' })}`}
            />
          </Col>
          <Col xs className="text-center">
            <FormattedMessageDiv
              className="fw-bold fs-7"
              id="offerPreview.payment"
            />
            <FormattedNumberDiv
              className="h5 mb-0"
              style="currency"
              currency="USD"
              value={selectedOffer?.pricedDetails.payment}
            />
          </Col>
        </Row>
      )}
      <div className="py-4">
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="offerPreview.capital" />
            <Tooltip
              color="dark"
              title={`${intl.formatMessage({ id: 'offerPreview.capital.tooltip' })}`}
            />
          </div>
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            style="currency"
            currency="USD"
            value={selectedOffer?.pricedDetails.totalLoanCost}
          />
        </Stack>
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="offerPreview.apr" />
            <Tooltip
              color="dark"
              title={`${intl.formatMessage({ id: 'offerPreview.apr.tooltip' })}`}
            />
          </div>
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            value={selectedOffer?.pricedDetails.trueAPR}
            style="unit"
            suffix={`%`}
          />
        </Stack>
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 mb-2 pe-2"
            id="offerPreview.cod"
          />
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            style="currency"
            value={selectedOffer?.centsOnDollar * 100}
            suffix="&cent;"
          />
        </Stack>
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="offerPreview.prepaymentDiscount" />
            { watch('offer.ppdDiscount') === "25" ? (
              <Tooltip
                color="dark"
                title={`${intl.formatMessage({ id: 'prepayment.tooltip.ppd.25' })}`}
              />
            ) : null}
            { watch('offer.ppdDiscount') === "100" ? (
              <Tooltip
                color="dark"
                title={`${intl.formatMessage({ id: 'prepayment.tooltip.ppd.100' })}`}
              />
            ) : null}
          </div>
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            value={watch('offer.ppdDiscount')}
            style="unit"
            suffix={`%`}
          />
        </Stack>
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 mb-2 pe-2"
            id="offerPreview.repaymentAmount"
          />
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            style="currency"
            currency="USD"
            value={selectedOffer?.pricedDetails.totalAmountPaidBack}
          />
        </Stack>
      </div>
      <Stack gap={2} className="bg-light-gray p-4 ">
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 pe-2"
            id="offerPreview.loanAmount"
          />
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto"
            style="currency"
            currency="USD"
            value={loanAmount}
          />
        </Stack>
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 pe-2"
            id="offerPreview.principalBalance"
          />
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto"
            style="currency"
            currency="USD"
            value={0}
          />
        </Stack>
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 pe-2"
            id="offerPreview.originationFee"
          />
          {selectedOffer?.pricedDetails.loanOriginationFee ? (
            <FormattedNumberDiv
              className="fw-bold fs-7 ms-auto"
              style="currency"
              currency="USD"
              value={selectedOffer?.pricedDetails.loanOriginationFee}
            />
          ) : (
            <Badge pill bg="success" className="ms-auto">
              {intl.formatMessage({ id: 'offerPreview.waived' })}
            </Badge>
          )}
        </Stack>
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 pe-2"
            id="offerPreview.prevInterest"
          />
          <Badge pill bg="success" className="ms-auto">
            {intl.formatMessage({ id: 'offerPreview.waived' })}
          </Badge>
        </Stack>
        <Stack direction="horizontal">
          <hr className="w-100 border border-bottom-0 border-disabled" />
        </Stack>
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="offerPreview.disbursement" />
            <Tooltip
              color="dark"
              title={`${intl.formatMessage({ id: 'offerPreview.disbursement.tooltip' })}`}
            />
          </div>
          <FormattedNumberDiv
            className="fw-bold ms-auto mb-2"
            style="currency"
            currency="USD"
            value={selectedOffer?.pricedDetails.disbursementAmount}
          />
        </Stack>
      </Stack>
      <div className="pt-6">
        <Button
          className="w-100"
          disabled={false}
          onClick={() => isLastStep ? navigate('/checkout') : goToStep(OfferConfigStep.PPD_OPTION)}
        >
          <FormattedMessageDiv id="common.continue" />
        </Button>
      </div>
    </Stack>
  );
}

export default OfferPreview;
