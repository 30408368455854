export const partiesMock = {
  user: {
    id: '905722e8-a2f9-4daa-bbae-97859a6d635b',
    name: 'Person Name',
    href: `/persons/905722e8-a2f9-4daa-bbae-97859a6d635b`,
  },
  parties: [
    {
      type: 'CUSTOMER',
      id: '34d76920-0fa1-40a8-b605-42ed27994e80',
      people: [
        {
          id: '905722e8-a2f9-4daa-bbae-97859a6d635b',
          name: 'Person Name',
          href: `/persons/905722e8-a2f9-4daa-bbae-97859a6d635b`,
        },
      ],
      businesses: [
        {
          id: '320a748d-594b-431f-adbc-d415dcb9fbab',
          legalName: 'Neighborhood Market',
          href: `/businesses/320a748d-594b-431f-adbc-d415dcb9fbab`,
        },
      ],
      loans: [
        {
          href: `/loans/7534985345734983`,
          id: '7534985345734983',
          loanType: 'FIXED_TERM',
        },
        {
          href: `/loans/7534985345734984`,
          id: '7534985345734984',
          loanType: 'FIXED_TERM',
        },
        {
          href: `/locs/4534985345734984`,
          id: '4534985345734984',
          loanType: 'LINE_OF_CREDIT',
        },
      ],
    },
  ],
};
