import React, { useEffect, useState } from 'react';
import { ButtonGroup as RButtonGroup, ToggleButton } from 'react-bootstrap';

const validSelection = (c: any, a: any, d: any) => c.filter((n: any) => a.includes(n) && !d.includes(n));

interface ButtonGroupProps {
  choices: Array<any>;
  activeChoices?: Array<any>;
  disabledChoices?: Array<any>;
  selectedChoice?: any;
  onChange?: (e: any) => void;
  className?: string;
  buttonGroupType?: 'toggle-buttons' | 'btn-group';
  variant?: string;
  vertical?: boolean;
}
export const ButtonGroup = ({
  choices,
  activeChoices = choices,
  disabledChoices = [],
  selectedChoice = choices[0],
  onChange = (e: any) => undefined,
  className,
  buttonGroupType,
  variant = 'accent',
  vertical = false
}: ButtonGroupProps) => {
  const [selected, setSelected] = useState(selectedChoice);

  useEffect(() => {
    setSelected(selectedChoice);
  }, [selectedChoice]);

  useEffect(() => {
    const validValues = validSelection(choices, activeChoices, disabledChoices);
    !validValues.includes(selected) && setSelected(validValues[0]);
  }, [activeChoices, disabledChoices, selectedChoice]);

  return (
    <RButtonGroup bsPrefix={buttonGroupType} vertical={vertical} >
      {choices.map(c => (
        <ToggleButton
          type="radio"
          key={c}
          id={`radio-${c}`}
          variant={`outline-${variant}`}
          name={c}
          value={c}
          disabled={!activeChoices.includes(c) || disabledChoices.includes(c)}
          checked={selected === c}
          onChange={e => {
            onChange(e.target.value)
            setSelected(e.target.value);
          }}
          className={className}
          size={buttonGroupType === 'toggle-buttons' ? 'sm' : 'lg'}
        >
          {c}
        </ToggleButton>
      ))}
    </RButtonGroup>
  );
};
