import useSWR from "swr";
import { CustomerData, LoanDetails, Offer } from "../../Helpers/types";
import useApplication from "../useApplication/useApplication";
import { orderBy } from 'lodash';

export interface UseDetailedOffersProps {
    isLoadingDetailedOffers: boolean;
    tlOffers: Offer[];
    selectedOffer?: Offer,
    previousLoan?: LoanDetails;
    customerData?: CustomerData;
    isErrorOffers: any;
    isPartnerChannel?: Boolean;
}

export function useDetailedOffers(): UseDetailedOffersProps {
    const apiUrl = process.env['NX_PUBLIC_OFFER_ORCHESTRATOR_URL']
    const { applicationData, isLoadingApplication, isErrorApplication, isPartnerChannel } = useApplication();
    const { data, error, isLoading } = useSWR(applicationData?.id ? `${apiUrl}/v2/detailedOffers?applicationId=${applicationData.id}` : null);


    const previousLoan = data?.loanDetails;
    const customerData = data?.customerData;

    const filterProductOffer = (product:string, offers: any[]) => offers?.filter(offer => offer?.product === product && offer?.presentationStatus === 'ACTIVE');
    const tlArray = filterProductOffer('TermLoan', data?.applicationOffer?.offers);
    const filteredTLArray = tlArray?.filter(offer => !offer.prePaymentDiscountRelatedOfferIds?.hasOwnProperty("100"))
    const orderedTLOffers = orderBy(filteredTLArray,
      [offer => offer.prePaymentDiscountPct === 100, 'loanAmount', 'term'],
      ['desc', 'desc', 'desc']);

    const selectedOffer = data?.applicationOffer?.offers.filter(o=> o.offerId === data?.applicationOffer.selectedOffer)[0] || null;

    return {
        isLoadingDetailedOffers: isLoading || isLoadingApplication,
        tlOffers: orderedTLOffers,
        selectedOffer: selectedOffer,
        previousLoan: previousLoan,
        customerData: customerData,
        isErrorOffers: error || isErrorApplication,
        isPartnerChannel: isPartnerChannel
    }
}

export default useDetailedOffers;
