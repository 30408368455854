import useSWR from 'swr';
import { Parties } from '../..';

export interface UseParties {
  isLoadingParties: boolean;
  partiesData: Parties;
  partyId: string | undefined;
  isErrorParties: any;
  isApplicant: boolean;
  hasLOC: string | undefined;
  hasTL: string | undefined;
  ifOptEnabled: boolean;
  personId: string | undefined;
  businessId: string | undefined;
  isOptSalesAgentEnabled: boolean;
  optimizely: any;
}

export function useParties(): UseParties {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/parties`)
  return {
    isApplicant: data?.parties?.some((e: any) => e.type === 'APPLICANT'),
    partiesData: data,
    partyId: data?.parties?.[0]?.id,
    personId: data?.parties?.[0]?.people?.[0]?.id,
    businessId: data?.parties?.[0]?.businesses?.[0]?.id,
    isLoadingParties: isLoading,
    isErrorParties: error,
    hasLOC: data?.parties?.flatMap((p) => p.loans?.filter((l) => l.loanType === 'LINE_OF_CREDIT').map((l) => l.id))[0] || undefined,
    hasTL: data?.parties?.flatMap((p) => p.loans?.filter((l) => l.loanType === 'FIXED_TERM').map((l) => l.id))[0] || undefined,
    ifOptEnabled: data?.instantFundingEnabled,
    isOptSalesAgentEnabled: data?.salesAgentInfoEnabled,
    optimizely: data?.optimizely,
  }
}

export default useParties;
