import useSWR from 'swr';
import { LoanActivity } from '../..';

export interface UseActivity {
  isLoading: boolean;
  transactions: LoanActivity[];
  isError: boolean;
}

export function useTransactions(): UseActivity {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/loans/transactions`, { revalidateOnMount: true })

  return {
    transactions: data,
    isLoading,
    isError: error
  }
}
 
export default useTransactions;
