import useSWR from 'swr';
import { Offer, useApplication } from '../..';
import { useEffect, useState } from 'react';

export enum OfferProduct {
  TERM_LOAN = "TermLoan",
  LINE_OF_CREDIT = "LineOfCredit",
  DUAL_PRODUCT = "DualProduct"
}

export interface UseOffersProps {
  isLoadingOffers: boolean;
  tlOffer?: Offer | null;
  locOffer?: Offer | null;
  isErrorOffers: any;
  selectedOffer?: Offer | null;
  product?: OfferProduct | null;
  isPartnerChannel?: Boolean;
}

export function useOffers(): UseOffersProps {
  const apiUrl = process.env['NX_PUBLIC_OFFER_ORCHESTRATOR_URL']
  const { applicationData, isErrorApplication, isPartnerChannel } = useApplication();
  const { data, error } = useSWR(applicationData?.id ? `${apiUrl}/v2/offers?applicationId=${applicationData.id}` : null);
  const [locOffer, setLocOffer] = useState<Offer | null>();
  const [tlOffer, setTLOffer] = useState<Offer | null>();
  const [product, setProduct] = useState<OfferProduct | null>();

  const maxOffer = (offers): Offer | undefined => (offers?.length) && offers?.reduce((prev,curr) => prev?.maxLineAmount > curr?.maxLineAmount ? prev : curr)
  const filterProductOffer = (product:string, offers: any[]) => offers?.filter(offer => offer?.product === product && offer?.presentationStatus === 'ACTIVE');
  const selectedOffer = data?.offers?.filter(offer => offer?.userActionStatus === 'SELECTED' && offer?.presentationStatus === 'ACTIVE');

  useEffect(() => {
    if(data?.offers) {
      const tlArray = filterProductOffer('TermLoan', data.offers);
      setTLOffer(tlArray?.length > 0 ? maxOffer(tlArray) : null);
      const locArray = filterProductOffer('LineOfCredit', data.offers);
      setLocOffer(locArray?.length > 0 ? maxOffer(locArray) : null);
    }
  }, [data]);

  useEffect(() => {
    (locOffer !== undefined && tlOffer !== undefined) && setProduct((locOffer && tlOffer) ? OfferProduct.DUAL_PRODUCT : tlOffer ? OfferProduct.TERM_LOAN : locOffer ? OfferProduct.LINE_OF_CREDIT : null)
  }, [locOffer, tlOffer]);

  return {
    tlOffer: tlOffer,
    locOffer: locOffer,
    isLoadingOffers: product === undefined,
    isErrorOffers: error || isErrorApplication,
    selectedOffer: selectedOffer?.length ? maxOffer(selectedOffer) : null,
    product: product,
    isPartnerChannel: isPartnerChannel
  }
}

export default useOffers;
