import { Card } from 'react-bootstrap';

export const ErrorCard = ({ name }: { name: string }) => (
  <Card className="px-5 py-8 rounded-3 text-center shadow mb-0 h-100">
    {name}
  </Card>
);

interface ErrorDivStyleProps {
  className?:string,
  errorMsg?: string
}

export const ErrorDiv = ({ className, errorMsg  }: ErrorDivStyleProps) => (
  <div className={className}>{errorMsg}</div>
  );

export default ErrorCard;
