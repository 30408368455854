import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, FloatingLabel, Stack } from 'react-bootstrap';

export interface OwnerFormData {
  firstName: string;
  lastName: string;
  address: {
    zipcode: string;
    addressLine1: string;
    city: string;
    state: string;
  };
  homePhone: string;
  dob: string;
  ownership: number;
  isPrimaryOwner: string;
  ssn: string;
}

interface OwnerFormProps {
  initialFormData?: OwnerFormData;
  onSubmit?: (data: OwnerFormData) => void;
}

export const PersonalForm = ({
  initialFormData,
  onSubmit = () => {},
}: OwnerFormProps) => {
  const { register, handleSubmit } = useForm<OwnerFormData>({
    defaultValues: initialFormData,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        <FloatingLabel controlId="firstName" label="First Name">
          <Form.Control type="text" required {...register('firstName')} />
        </FloatingLabel>

        <FloatingLabel controlId="lastName" label="Last Name">
          <Form.Control type="text" required {...register('lastName')} />
        </FloatingLabel>

        <FloatingLabel controlId="addressLine1" label="Address Line 1">
          <Form.Control
            type="text"
            required
            {...register('address.addressLine1')}
          />
        </FloatingLabel>

        <Stack direction="horizontal" gap={3}>
          <FloatingLabel controlId="city" label="City">
            <Form.Control type="text" required {...register('address.city')} />
          </FloatingLabel>

          <FloatingLabel controlId="state" label="State">
            <Form.Control type="text" required {...register('address.state')} />
          </FloatingLabel>

          <FloatingLabel controlId="zipcode" label="Zipcode">
            <Form.Control
              type="text"
              required
              {...register('address.zipcode')}
            />
          </FloatingLabel>
        </Stack>

        <FloatingLabel controlId="homePhone" label="Home Phone">
          <Form.Control type="tel" required {...register('homePhone')} />
        </FloatingLabel>

        <FloatingLabel controlId="dateOfBirth" label="Date of Birth">
          <Form.Control type="date" required {...register('dob')} />
        </FloatingLabel>

        <FloatingLabel
          controlId="ownership"
          label="Business Ownership Percentage"
        >
          <Form.Control type="number" required {...register('ownership')} />
        </FloatingLabel>

        <FloatingLabel controlId="ssn" label="Social Security Number">
          <Form.Control type="text" required {...register('ssn')} />
        </FloatingLabel>

        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Stack>
    </Form>
  );
};

export default PersonalForm;
