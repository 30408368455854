import { ErrorCard, LoadingCard } from '@odmonorepo/core-components';
import { Tabs, Tab, Collapse, Stack, Card } from 'react-bootstrap';
import LoanDetails from '../Components/LoanDetails';
import UpcomingPayments from '../Components/UpcomingPayments';
import { useIntl } from 'react-intl';
import {
  useLoan,
  getLOCPayment,
  useLOC,
  getTLPayment,
  useParties,
} from 'merchant-lib';
import { rawLoanToLoanDetails } from '../Transformers/rawLoanToLoanDetails';
import { useNavigate } from 'react-router';
import { IFEligibleSignUp } from '../Components/IFEligibleSignUp';
import { IFRegistered } from '../Components/IFRegistered';
import { BorrowingDetails } from '../Components/BorrowingDetails';

export function ProductCard() {
  const intl = useIntl();
  const { ifOptEnabled } = useParties();
  const { activeTL, loanLoading, loanError } = useLoan();
  const { activeLOC, locLoading, locError, cardRegistered } = useLOC();
  const { LOCPayment, LOCPaymentLoading } = getLOCPayment();
  const { loanPayment, loanPaymentLoading } = getTLPayment();
  const navigate = useNavigate();

  if (loanError || locError)
    return <ErrorCard name={intl.formatMessage({ id: 'loading.error' })} />;
  if (loanLoading || locLoading) return <LoadingCard />;
  const showProdCard =
    activeLOC || (activeTL && activeTL?.percentagePaidDown != 100);

  let modifiedLocPayment = LOCPayment! && {
    payment: {
      amount: LOCPayment?.nextPaymentAmount,
      scheduledDate: LOCPayment?.nextPaymentDate,
    },
    ...LOCPayment,
  };
  return (
    showProdCard && (
      <Card className="p-5">
        <Tabs className="d-flex justify-content-center custom-tabs">
          {activeLOC && (
            <Tab
              className="pt-5"
              title={intl.formatMessage({ id: 'title.loc' })}
              eventKey="Line of Credit"
            >
              <Stack gap={5}>
                <LoanDetails
                  {...rawLoanToLoanDetails(activeLOC)}
                  btnOnClick={() => {
                    navigate('/drawfunds');
                  }}
                />
                <BorrowingDetails apr={activeLOC.annualPercentageRate} cod={activeLOC.trueCOD} monthlyInterest={activeLOC.monthlyInterestRate} />
                {ifOptEnabled && cardRegistered ? (
                  <IFRegistered className="border-top pt-5 border-light-medium-gray" />
                ) : (
                  ifOptEnabled && <IFEligibleSignUp className="mb-2" />
                )}
                <Collapse in={!LOCPaymentLoading || LOCPayment !== undefined}>
                  <div>
                    <UpcomingPayments
                      className="border-top pt-5 border-light-medium-gray"
                      {...modifiedLocPayment}
                    />
                  </div>
                </Collapse>
              </Stack>
            </Tab>
          )}
          {activeTL && activeTL?.percentagePaidDown != 100 && (
            <Tab
              className="pt-5"
              title={intl.formatMessage({ id: 'title.termloan' })}
              eventKey="Term Loan"
            >
              <LoanDetails
                {...rawLoanToLoanDetails(activeTL)}
                showRangePercentage
              />
              <Collapse in={!loanPaymentLoading || loanPayment !== undefined}>
                <div>
                  <UpcomingPayments
                    className="border-top pt-5"
                    {...loanPayment!}
                  />
                </div>
              </Collapse>
            </Tab>
          )}
        </Tabs>
      </Card>
    )
  );
}

export default ProductCard;
