import useSWR from "swr";
import { CheckoutData, useApplication } from "merchant-lib";

interface CheckoutStateProps {
  isLoadingCheckoutState: boolean
  isErrorCheckoutState: any
  checkoutData?: CheckoutData
}

export function getCheckoutState(): CheckoutStateProps {
  const apiUrl = process.env['NX_PUBLIC_CHECKOUT_ORCHESTRATOR_URL']
  const { applicationData, isLoadingApplication, isErrorApplication } = useApplication();
  // This won't work until OX-814 is completed
  const { data, error, isLoading } = useSWR((applicationData?.id ? `${apiUrl}/v2/orchestration/state?applicationUuid=${applicationData.id}`: null));

  return {
    isLoadingCheckoutState: isLoading || isLoadingApplication,
    isErrorCheckoutState: error || isErrorApplication,
    checkoutData: data,
  }
}