import { BrowserRouter } from 'react-router-dom';
import { analytics, Auth0Provider } from 'merchant-lib';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { useEffect } from 'react';
import AppRouter from './router';
import { useSWRConfig } from 'swr';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from 'merchant-lib';
import { stringifyCache } from '@odmonorepo/odfunctions';

export function App() {
  const optimizely = createInstance({
    sdkKey: process.env['NX_PUBLIC_OPTIMIZELY_KEY'],
  });
  const { cache } = useSWRConfig();
  const logError = (error: Error, info: { componentStack: string }) =>
    
    analytics.track('DetailedError', {
      message: error.message,
      stack: info.componentStack,
      state: stringifyCache(cache),
    });
  useEffect(() => {
    analytics.page();
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorPage} onError={logError}>
      <OptimizelyProvider optimizely={optimizely} user={{ id: 'default_user' }}>
        <Auth0Provider
          domain={process.env.NX_PUBLIC_AUTH0_DOMAIN}
          clientID={process.env.NX_PUBLIC_AUTH0_CLIENT_ID}
          baseURL={window.location.origin}
          audience={process.env.NX_PUBLIC_AUTH0_AUDIENCE}
          connection={process.env.NX_PUBLIC_AUTH0_CONNECTION}
          responseType="code"
          scope="profile"
        >
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </Auth0Provider>
      </OptimizelyProvider>
    </ErrorBoundary>
  );
}

export default App;
