import { createServer } from 'miragejs';

interface mockDataProps {
  [x: string]: {
  method: 'get' | 'post' | 'put' | 'del';
  delay: number;
  data: any;
  } | any
}

export const makeServer = (mockData: mockDataProps[]) => {
  return(
    createServer({
      routes() {
        Object.entries(mockData).map(([url, mock]) => {      
          this[mock?.['method'] || 'get'](
            url,
            () => JSON.stringify(mock?.['data'] || mock), 
            { timing: Number(mock?.['delay'] || 500) }
          )
        })
      },
    })
  )
}
