import React, { MouseEventHandler, useState } from 'react';
import { useIntl } from 'react-intl';
import {Col, Row, Button, Form, Stack, Card, Collapse} from 'react-bootstrap';
import DocumentPageLineItem from './DocumentLineItem';
import {
  Caret,
  ExpandableList,
  FormattedMessageDiv,
  FormCheckboxes,
  FormSelect,
  WizardForm
} from '@odmonorepo/core-components';
import {useFormContext} from "react-hook-form";


export interface DocumentData {
  loanId: string;
  loanType: string;
  documentType: string;
  fileName: string;
  createdOn: Date;
  documentId: string;
  downloadDocument?: MouseEventHandler;
}

export interface FilterListProps {
  documents: DocumentData[];
  title?: string;
  filterVisible?: boolean,
  toggleFilterVisibility?: () => void
}

export const FilterMenu = ({documents}:{documents:DocumentData[]}) => {
  const { reset } = useFormContext();

  const loanMap = new Map(
    documents?.map(doc => [doc.loanId, {
      value: doc.loanId,
      label: `${doc.loanType} (...${doc.loanId})`
    }])
  );
  const loanIds: { label: string; value: string }[] = Array.from(loanMap.values()).sort((a, b) => a.value?.localeCompare(b.value));
  const years : number[] = [...new Set(documents?.map(doc => new Date(doc.createdOn).getFullYear()))].sort((a, b) => b - a);
  const documentTypes : string[] = [...new Set(documents?.map(doc => doc.documentType))].sort();

  return (
    <Stack gap={3} className="m-1">
      <div>
        <div className="fw-bold my-0 fs-6 mb-1">Loan ID</div>
        <FormSelect showLabel={false} showBlank={true} blankLabel="All" name="loanId" label="Loan ID" options={loanIds} useValueAsDisplay className="text-muted"/>
      </div>
      <div>
        <div className="fw-bold mb-0 fs-6 mb-1">Year</div>
        <FormSelect showLabel={false} showBlank={true} blankLabel="All" name="year" label="Year" options={years} useValueAsDisplay className="text-muted"/>
      </div>
      <div>
        <div className="fw-bold mb-0 fs-6 mb-1">Document Type</div>
        <FormCheckboxes name="documentType" label="" gap={1} inline={false} options={documentTypes}/>
        <Stack direction="horizontal" className="mt-2" gap={2}>
          <Button className="col" variant="outline-primary" onClick={() => reset()}>Clear</Button>
          <Button className="col" variant="primary" type='submit' >Apply</Button>
        </Stack>
      </div>
    </Stack>
  );
};


export function DocumentFilterList({
                                     documents,
                                     title = 'Documents',
                                     filterVisible = false,
                                     toggleFilterVisibility
                                   }: FilterListProps) {
  const intl = useIntl();
  const [filters, setFilters] = useState({
    loanId: '',
    year: '',
    documentTypes: [],
  });

  let setFilterVisible;
  if(!toggleFilterVisibility) {
    [filterVisible, setFilterVisible] = useState(false);
    toggleFilterVisibility = () => setFilterVisible(v => !v)
  }

  const handleApplyFilters = (appliedFilters) => {
    if (Array.isArray(appliedFilters['documentType']) && appliedFilters['documentType'].length === 0) {
      appliedFilters['documentType'] = false;
    }
    setFilters(appliedFilters);
    toggleFilterVisibility();
  };

  const filteredDocuments = documents?.filter(doc => {
    const loanIdMatch = filters['loanId'] ? doc.loanId === filters['loanId'] : true;
    const yearMatch = filters['year'] ? new Date(doc.createdOn).getFullYear().toString() === filters['year'] : true;
    const documentTypeMatch = filters['documentType'] ? filters['documentType'].includes(doc.documentType) : true;
    return loanIdMatch && yearMatch && documentTypeMatch;
  });

  return (
    <WizardForm onSubmit={handleApplyFilters}>
      <div>
        <Row className="gy-3 px-5 px-sm-0">
          <Col xs={12} sm={4} className="fs-6 fw-bold">{title}</Col>
          <Col xs={12} sm={8} className="d-flex justify-content-start justify-content-sm-end">
            <a id="dropdown-basic-button" role="button" title="Filter" className='fs-7' onClick={toggleFilterVisibility}>
              <Stack direction='horizontal' gap={1}>
                <FormattedMessageDiv id="btn.filter" className="d-inline-block" />
                <Caret className="ms-1 fs-8 d-flex" direction={filterVisible ? 'up' : 'down'} />
              </Stack>
            </a>
              <Collapse in={filterVisible} timeout={0}>
              <Card className="p-3 shadow mb-5" style={{width: "360px", position:"absolute", zIndex:100, top:"50px"}}>
                <FilterMenu documents={documents}/>
              </Card>
            </Collapse>
          </Col>
        </Row>
        <ExpandableList
          btnLabel={intl.formatMessage({ id: 'btn.loadMore' })}
          defaultShow={3}
          show={5}>
          {filteredDocuments?.map((item, id) => <DocumentPageLineItem key={id} {...item}/>)}
        </ExpandableList>
      </div>
    </WizardForm>
  );
}

export default DocumentFilterList;
