import useSWR from 'swr';
import useLoan from '../useLoan/useLoan';
import { NextPayment } from '../../Helpers/types';

export interface PaymentProps {
  loanPaymentLoading: boolean;
  loanPayment: NextPayment | null;
  loanPaymentError: any;
}

export function getTLPayment(): PaymentProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']

  const { activeTL, loanLoading, loanError } = useLoan();
  const loanId = activeTL?.id;
  const { data, error, isLoading } = useSWR(loanId ? `${apiUrl}/v1/loans/${loanId}/payment/next` : null)
  return {
    loanPayment: data,
    loanPaymentLoading: isLoading || loanLoading,
    loanPaymentError: error || loanError
  }
}

export default getTLPayment;
