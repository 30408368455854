import { InputSliderCombo } from '@odmonorepo/core-components';
import { Stack } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { maxLoanAmount, minLoanAmount } from '../../minMaxLoanAmounts';
import { CalculatorProps } from 'libs/merchant-lib/src/Helpers/types';


export const CalculatorInputSlider = ({
  tlOffers
}: CalculatorProps) => (

  <Stack gap={1}>
    <div className="fw-bold">
      <FormattedMessage id="calculator.termAmt" />
    </div>
    <Controller
      name="offer.loanAmount"
      render={({ field: { onChange, value } }) => (
        <InputSliderCombo
          min={minLoanAmount(tlOffers)}
          max={maxLoanAmount(tlOffers)}
          initialValue={value}
          onChange={onChange}
        />
      )}
    />
  </Stack>
);
