import useSWR from 'swr';
import { OTPData } from '../../Helpers/types';

export interface getOTPDataProps {
  OPTDataLoading: boolean;
  OPTData: OTPData | any;
  OPTDataError: any;
}

export function getOTPData(id: string): getOTPDataProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/otpPreliminaryData/loan/${id}`)
  return {
    OPTData: data,
    OPTDataLoading: isLoading,
    OPTDataError: error
  }
}

export default getOTPData;
