import React from 'react';
import { Offer } from 'merchant-lib';
import { FormattedMessageDiv, FormattedNumberDiv } from '@odmonorepo/core-components';
import { Badge, Card, Col, Row, Stack } from 'react-bootstrap';
import { codToCents } from '../codToCents';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Tooltip } from '@odmonorepo/core-components';

interface TermLoanOfferProps {
  offer: Offer
  applicationType: string
}

const TermLoanOfferCard = ({ offer, applicationType }: TermLoanOfferProps ) => {
  const intl = useIntl();
  const ppdValue = offer.prePaymentDiscountPct === 100 ? 100 : 25;

  const amountType =
    applicationType === 'NEW' || offer.pricedDetails.remainingPrincipal === 0
      ? 'disbursementAmt'
      : 'netAmt';

  return (
    <Card data-testid="term-loan-container" className="text-center p-md-6 p-4">
      <Row className="p-3 mb-6 pb-0">
        <Col>
          <FormattedNumberDiv
            className="h1"
            value={offer.pricedDetails.lineAmount}
            style='currency'
            currency='USD'
            minimumFractionDigits={0}
          />
          <Col className="mt-2">
            <FormattedMessageDiv className='fw-bold' id={`offerReview.totalLoan`} />
            {applicationType === 'NEW' ? null
              : <FormattedNumberDiv
                value={offer.pricedDetails.disbursementAmount}
                currency='USD'
                style='currency'
                minimumFractionDigits={0}
                prefix={intl.formatMessage({id: 'offerReview.totalLoan.prefix'})}
                suffix={intl.formatMessage({id: 'offerReview.totalLoan.suffix'})}
              />
            }
          </Col>
        </Col>
        <div className="d-none d-md-block vr border border-light-gray p-0 border-opacity-50 mx-5" />
        <hr className="d-block d-md-none border border-gray my-5 border-opacity-75" />
        <Col>
          <FormattedNumberDiv className="h1" value={offer.pricedDetails.payment} style='currency' currency='USD' />
          <Col className="mt-2">
            <FormattedMessageDiv id="offerReview.paymentFrequency" className="fw-bold" />
            <FormattedMessage id="offerReview.paymentFrequency.1" />
            <FormattedNumber
              value={offer.pricedDetails.averageMonthlyPayment}
              currency='USD'
              style='currency'
              minimumFractionDigits={0} />
            <FormattedMessage id="offerReview.paymentFrequency.2" values={{term: offer.pricedDetails.term}} />
          </Col>
        </Col>
      </Row>
      <Card className="bg-light-gray p-5 shadow-none" style={{borderRadius: 0}}>
        <Row className="flex-md-row text-start">
          <Stack gap={3} className="col-md-3">
            <FormattedMessageDiv className='fw-bold' id='offerPreview.details' />
            <Stack direction="horizontal">
              <FormattedMessage id={`offerPreview.loanAmount`} />
              <FormattedNumberDiv value={offer.pricedDetails.lineAmount} className="ms-auto" style='currency' currency='USD' />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage id="offerPreview.originationFee" />
              <Tooltip
                title={intl.formatMessage({
                  id: 'originationFee.tooltip'
                })}
              />
              {offer.pricedDetails.loanOriginationFee === 0 ? (
                <Badge className='ms-auto fw-500' bg='success'>{intl.formatMessage({ id: 'offerPreview.waived' })}</Badge>
              ) : ( <FormattedNumberDiv value={offer.pricedDetails.loanOriginationFee} className="ms-auto" style='currency' currency='USD' /> )}
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage id={`reviewPage.${amountType}.label`} />
              <Tooltip
                title={intl.formatMessage({
                  id: `tooltip.disbursement.${amountType}`
                })}
              />
              <FormattedNumberDiv value={offer.pricedDetails.disbursementAmount} className="ms-auto" style='currency' currency='USD' />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage id="offerPreview.cod" />
              <FormattedNumberDiv
                value={codToCents(offer.pricedDetails.trueCentsOnDollar)}
                className="ms-auto"
                suffix='&cent;'
                roundingMode="ceil"
                maximumFractionDigits={1}
                minimumFractionDigits={1}
              />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage id={`offerPreview.apr`} />
              <FormattedNumberDiv value={offer.pricedDetails.trueAPR} suffix="%" className="ms-auto" />
            </Stack>
          </Stack>
          <div className="d-none d-md-block vr border border-light-gray p-0 border-opacity-50 mx-5" />
          <Stack className="d-block d-md-none py-2">
            <hr className="border border-gray border-opacity-75" />
          </Stack>
          <Stack gap={3} className="col-md-3">
            <FormattedMessageDiv className='fw-bold' id="offerPreview.costAndFees" />
            <Stack direction="horizontal">
              <FormattedMessage id={`offerPreview.totalCost`} />
              <Tooltip title={intl.formatMessage({ id: 'totalCostOfCapital.tooltip' })} />
              <FormattedNumberDiv value={offer.pricedDetails.totalLoanCost} className="ms-auto" style='currency' currency='USD' />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage id={`totalPaymentAmount.label`} />
              <Tooltip title={intl.formatMessage({ id: `prepayment.tooltip.ppd.${ppdValue}` })} />
              <FormattedNumberDiv value={offer.pricedDetails.totalAmountPaidBack} className="ms-auto" style='currency' currency='USD' />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage id={'offerReview.returnedPaymentFee'} />
              <FormattedNumberDiv value={offer.returnedPaymentFee} className="ms-auto" style='currency' currency='USD' />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessage id={'offerReview.lateFee'} />
              <FormattedNumberDiv value={offer.lateFee} className="ms-auto" style='currency' currency='USD' />
            </Stack>
          </Stack>
        </Row>
        <Row className="mt-5 text-start gap-3">
          <FormattedMessageDiv className='fw-bold' id={`prepayment.reduction.ppd.${ppdValue}.label`} />
          <FormattedMessageDiv id={`prepayment.tooltip.ppd.${ppdValue}`} />
        </Row>
      </Card>
    </Card>
  );
};

export default TermLoanOfferCard;
