import useSWR from 'swr';
import { RenewalEligibility } from '../../Helpers/types';

export interface RenewalProps {
  isRenewalLoading: boolean;
  renewal: RenewalEligibility;
  isRenewalError: any;
}

export function getRenewal(): RenewalProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/renewal`)
  return {
    renewal: data,
    isRenewalLoading: isLoading,
    isRenewalError: error
  }
}

export default getRenewal;
