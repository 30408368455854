import { combineClassNames } from "@odmonorepo/odfunctions";

type AvatarProps = JSX.IntrinsicElements['div'] & {
  name: string;
  size: "sm" | "lg";
}

export function Avatar({name = '', size = 'sm', ...props}: AvatarProps) {
  return (
    <div
      {...props}
      className={combineClassNames("rounded-circle shadow-sm border d-flex justify-content-center align-items-center fw-semibold fs-4", props.className)}
      style={{
        width: size === 'sm' ? "40px" : "80px",
        height: size === 'sm' ? "40px" : "80px",
        ...props.style
      }}
    >
      {name.charAt(0).toLocaleUpperCase()}
    </div>
  );
}

export default Avatar;
