import React from 'react';
import { Button} from '@odmonorepo/core-components';
import { Card, Stack } from 'react-bootstrap';
import BlueBlob from '../../../../core-components/src/lib/BlueBlob/BlueBlob';
import CustomErrorTitle from './CustomErrorTitle';
import CustomErrorMessage from './CustomErrorMessage';

interface CustomErrorProps {
  customTitle?: React.ReactNode;
  customMessage?: React.ReactNode;
  link?: string;
  CTA?: string;
}

export const CustomError = ({customTitle = <CustomErrorTitle />, customMessage = <CustomErrorMessage />, link = 'tel:(888)556-3483', CTA = 'Call Customer Service' }: CustomErrorProps) => {

  return (
    <BlueBlob style={{ marginTop: '60px'}}>
      <div className="h3 mb-5 mx-3">
        {customTitle}
      </div>
      <Stack gap={5} className="mx-3">
        <Card style={{ maxWidth: '848px'}} className="mx-auto pt-4 pb-4 px-4" >
          <div className="px-4 py-4 text-start" >
            {customMessage}
          </div>
          <Button type="button" className="mx-auto mb-3 p-4d" href={link}>{CTA}</Button>
        </Card>
      </Stack>
    </BlueBlob>
  );
};

export default CustomError;