/* eslint-disable-next-line */

import { FormattedNumberDiv } from '@odmonorepo/core-components';
import {
  combineClassNames,
  dollarFormat,
  formatDate,
} from '@odmonorepo/odfunctions';
import { Badge, Col, Row } from 'react-bootstrap';

export interface ActivityLineItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  date: string;
  type: string;
  status: string;
  product: string;
  amount: number;
  principal: number;
  interest: number;
}

export const ActivityLineItem = ({
  date,
  type,
  status,
  product,
  amount,
  principal,
  interest,
  ...props
}: ActivityLineItemProps) => {
  return (
    <Row
      {...props}
      className={combineClassNames(
        'fs-7',
        status === 'Pending' ? 'text-muted' : '',
        props.className
      )}
    >
      <Col xs={9} className="p-0 d-none d-md-block">
        <Row>
          <Col xs={5} className="d-none d-md-block">
            <span className="fw-bold">{formatDate(date)}</span>
            <br />
            {type} - {product}
          </Col>
          <Col xs={4} className="text-left d-md-block d-none">
            {dollarFormat(principal)}
            <br />
            Principal
          </Col>
          <Col xs={3} className="text-left d-md-block d-none">
            {dollarFormat(interest)}
            <br />
            Interest
          </Col>
        </Row>
      </Col>
      <Col xs={6} className="p-0 d-block d-md-none">
        <b className="fs-8 lh-sm">{formatDate(date)}</b>
        <div className="fs-8 lh-sm">
          {type} - {product}
        </div>
        <div className="fs-8 lh-sm">Principal: {dollarFormat(principal)}</div>
        <div className="fs-8 lh-sm">Interest: {dollarFormat(interest)}</div>
      </Col>

      <Col xs={6} md={3} className="p-0 text-end">
        <Badge
          bg={{ C: 'success', P: 'pending', E: 'danger', X: 'danger' }[status]}
        >
          <FormattedNumberDiv
            className="fs-7"
            value={amount}
            currency="USD"
            style="currency"
          />
        </Badge>
        <br />
        <span className="d-md-block fs-8">
          {{ C: 'Completed', P: 'Pending', E: 'Error', X: 'Cancelled' }[status]}
        </span>
      </Col>
    </Row>
  );
};

export default ActivityLineItem;
