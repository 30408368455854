import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, FloatingLabel, Stack } from 'react-bootstrap';

export interface BusinessFormData {
  legalName: string;
  name: string;
  address: {
    zipcode: string;
    addressLine1: string;
    city: string;
    state: string;
  };
  businessPhone: string;
  businessStartYear: number;
  legalEntityType: string;
  shortTermFinancing: string;
  businessTaxID: string;
}

interface BusinessFormProps {
  initialFormData?: BusinessFormData;
  onSubmit?: (data: BusinessFormData) => void;
}

export const BusinessForm = ({
  initialFormData,
  onSubmit = () => {},
}: BusinessFormProps) => {
  const { register, handleSubmit } = useForm<BusinessFormData>({
    defaultValues: initialFormData,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        <FloatingLabel
          controlId="legalBusinessName"
          label="Legal Business Name"
        >
          <Form.Control type="text" required {...register('legalName')} />
        </FloatingLabel>

        <FloatingLabel
          controlId="businessNameDifferent"
          label="Do you do business under a different name?"
        >
          <Form.Select required {...register('name')}>
            <option value="" className="d-none" />
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Select>
        </FloatingLabel>

        <FloatingLabel controlId="addressLine1" label="Address Line 1">
          <Form.Control
            type="text"
            required
            {...register('address.addressLine1')}
          />
        </FloatingLabel>

        <Stack direction="horizontal" gap={3}>
          <FloatingLabel controlId="city" label="City">
            <Form.Control type="text" required {...register('address.city')} />
          </FloatingLabel>

          <FloatingLabel controlId="state" label="State">
            <Form.Control type="text" required {...register('address.state')} />
          </FloatingLabel>

          <FloatingLabel controlId="zipcode" label="Zipcode">
            <Form.Control
              type="text"
              required
              {...register('address.zipcode')}
            />
          </FloatingLabel>
        </Stack>

        <FloatingLabel controlId="businessPhone" label="Business Phone">
          <Form.Control type="tel" required {...register('businessPhone')} />
        </FloatingLabel>

        <FloatingLabel
          controlId="businessStartYear"
          label="What year did you start your business?"
        >
          <Form.Control
            type="number"
            required
            {...register('businessStartYear')}
          />
        </FloatingLabel>

        <FloatingLabel controlId="legalEntityType" label="Legal Entity Type">
          <Form.Select required {...register('legalEntityType')}>
            <option value="" className="d-none" />
            <option value="Sole Proprietor">Sole Proprietor</option>
            <option value="Limited Liability Company (LLC)">
              Limited Liability Company (LLC)
            </option>
            <option value="Corporation">Corporation</option>
            <option value="General Partnership">General Partnership</option>
            <option value="Limited Partnership (LP)">
              Limited Partnership (LP)
            </option>
            <option value="Limited Liability Partnership (LLP)">
              Limited Liability Partnership (LLP)
            </option>
            <option value="Non-Profit">Non-Profit</option>
          </Form.Select>
        </FloatingLabel>

        <FloatingLabel
          controlId="shortTermFinancing"
          label="Do you currently have other short-term financing?"
        >
          <Form.Select required {...register('shortTermFinancing')}>
            <option value="" className="d-none" />
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Select>
        </FloatingLabel>

        <FloatingLabel controlId="businessTaxID" label="Business Tax ID">
          <Form.Control type="text" required {...register('businessTaxID')} />
        </FloatingLabel>

        <Button variant="primary" type="submit" className="mt-3">
          Continue
        </Button>
      </Stack>
    </Form>
  );
};

export default BusinessForm;
