import {
  FormattedMessageDiv,
  CheckCircle,
  Tooltip,
  LoadingCard,
} from '@odmonorepo/core-components';
import { useLOC } from 'merchant-lib';
import { Badge, Stack } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface IFRegisteredProps extends React.HTMLAttributes<HTMLDivElement> {}

export function IFRegistered({ ...props }: IFRegisteredProps) {
  const { activeLOC, locLoading } = useLOC();
  const cardEnding = activeLOC?.instantFunding?.cardInfo?.account;
  const cardNetwork = activeLOC?.instantFunding?.cardInfo?.network;
  const formattedCardNetwork =
    cardNetwork &&
    cardNetwork[0].toUpperCase() + cardNetwork.substring(1).toLowerCase();

  if (locLoading) return <LoadingCard />;

  return (
    <div {...props}>
      {activeLOC && (
        <Stack gap={1}>
          <Stack direction="horizontal" gap={2}>
            <FormattedMessageDiv
              className="fw-bold"
              id="instantFunding.title"
            />
            <Badge bg="success" pill className="d-flex">
              <CheckCircle color="#2b7665" width="16px" />
              <FormattedMessageDiv
                id={'instantFunding.registered.label'}
                className="p-1 fw-bold"
              />
            </Badge>
          </Stack>
          <Stack direction="horizontal">
            <FormattedMessageDiv
              id="instantfunding.draw.cardInfo"
              values={{ network: formattedCardNetwork, lastFour: cardEnding }}
            />
            <Tooltip
              color="dark"
              variant="standard"
              placement="auto-end"
              className="mb-1"
            >
              <FormattedMessage
                id="instantFunding.registered.toolTip.msg"
                values={{
                  tel: (txt: any) => (
                    <a href={`tel:${txt}`} className="link-white fw-bold">
                      {txt}
                    </a>
                  ),
                }}
              />
            </Tooltip>
          </Stack>
        </Stack>
      )}
    </div>
  );
}

export default IFRegistered;
