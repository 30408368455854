import { Card } from '@odmonorepo/core-components';

export function CredentialCard() {
  return (
    <Card>
      <div>
        <div className="fs-6 fw-bold mb-4">Privacy and Sign In</div>
        <a
          className="fw-semibold"
          href={process.env['NX_PUBLIC_LOGIN_RESET_PASSWORD_URL']}
        >
          Reset my password
        </a>
      </div>
    </Card>
  );
}

export default CredentialCard;
