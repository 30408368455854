import {
  Button,
  Card,
  FormattedMessageDiv,
  InstantFundingLogo,
  GreenCheckWithText,
} from '@odmonorepo/core-components';
import { Stack, Row, FormCheck, Col, Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postSession } from 'merchant-lib';

export function IFLandingCard({
  onContinue,
  setAuthorizedUrl,
  registrationError,
}: {
  onContinue: () => void;
  setAuthorizedUrl: any;
  registrationError: any;
}) {
  const [validCheckbox1, setValidCheckbox1] = useState(false);
  const [validCheckbox2, setValidCheckbox2] = useState(false);
  const navigate = useNavigate();
  const { triggerSessionDetails, isSessionLoading } = postSession();
  const handleSubmit = async () => {
    const res = await triggerSessionDetails();
    if (res?.data?.authorized_url) {
      setAuthorizedUrl(res.data.authorized_url);
      onContinue();
    }
  };

  return (
    <Stack gap={5}>
      <Card>
        {registrationError && (
          <Alert variant="danger">
            <FormattedMessageDiv
              id="instantFunding.error"
              className="fs-6 fw-bold"
            />
            <FormattedMessage
              id="instantFunding.error.message"
              values={{
                tel: (txt: any) => {
                  return (
                    <a href={`tel:${txt}`} className="fw-bold">
                      {txt}
                    </a>
                  );
                },
              }}
            />
          </Alert>
        )}
        <Stack gap={3}>
          <FormattedMessageDiv
            id="instantFunding.tsAndCs.subtitle"
            className="h4 text-center"
          />
          <Stack>
            <FormattedMessageDiv
              id="instantFunding.tsAndCs.getFunds"
              className="fs-6"
            />
          </Stack>
          <Row>
            <Col xs={12} md={6}>
              <Stack gap={3}>
                <GreenCheckWithText
                  id="instantFunding.tsAndCs.fundsAccess"
                  className="fs-6"
                  gap={3}
                />
                <GreenCheckWithText
                  id="instantFunding.tsAndCs.immediate"
                  className="fs-6"
                  gap={3}
                />
                <GreenCheckWithText
                  id="instantFunding.tsAndCs.cost"
                  className="fs-6"
                  gap={3}
                />
              </Stack>
            </Col>
            <InstantFundingLogo className="d-none d-md-block me-auto col"></InstantFundingLogo>
          </Row>
        </Stack>
        <hr />
        <FormattedMessageDiv
          id="instantFunding.tsAndCs.heading"
          className="fw-bold fs-8"
        />
        <Stack gap={3} className="fs-8">
          <FormattedMessageDiv id="instantFunding.tsAndCs.para1" />
          <FormattedMessageDiv id="instantFunding.tsAndCs.para2" />
          <FormattedMessageDiv id="instantFunding.tsAndCs.para3" />
          <FormCheck
            className="fw-bold"
            style={{ minHeight: '0' }}
            id="disclosure1"
            label={
              <FormattedMessageDiv id="instantFunding.tsAndCs.disclosure1" />
            }
            onChange={() => setValidCheckbox1(!validCheckbox1)}
            checked={validCheckbox1}
          />
          <FormCheck
            className="fw-bold"
            id="disclosure2"
            label={
              <FormattedMessageDiv id="instantFunding.tsAndCs.disclosure2" />
            }
            onChange={() => setValidCheckbox2(!validCheckbox2)}
            checked={validCheckbox2}
          />
        </Stack>
      </Card>
      <Row className="justify-content-center text-center gx-5" xs={1} md={2}>
        <Button
          style={{ width: '260px' }}
          className="px-0 col mb-6 mx-2"
          variant="outline-primary"
          onClick={() => navigate('/')}
        >
          <FormattedMessage id="instantFunding.tsAndCs.cancel" />
        </Button>
        <Stack gap={2} style={{ maxWidth: '300px' }}>
          <Button
            disabled={!validCheckbox1 || !validCheckbox2}
            className="px-0 col mx-2 border-0"
            loading={isSessionLoading}
            onClick={handleSubmit}
          >
            <FormattedMessage id="instantFunding.tsAndCs.continue" />
          </Button>
          <FormattedMessageDiv
            className="fs-8"
            id="instantFunding.tsAndCs.checkBoxesToContinue"
          />
        </Stack>
      </Row>
    </Stack>
  );
}
export default IFLandingCard;
