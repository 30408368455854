import useSWR from 'swr';
import { Documents } from '../..';

export interface useDocumentsProps {
  isLoading: boolean;
  documents: Documents[];
  isError: any;
}

export function useDocuments(): useDocumentsProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/documents`)
 
  return {
    documents: data,
    isLoading,
    isError: error
  }
}

export default useDocuments;
