import useSWR from 'swr';
import useLOC from '../useLOC/useLOC';
import { NextPayment } from '../../Helpers/types';

export interface LOCPaymentProps {
  LOCPaymentLoading: boolean;
  LOCPayment: NextPayment | null;
  LOCPaymentError: any;
}

export function getLOCPayment(): LOCPaymentProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']

  const { activeLOC, locLoading, locError } = useLOC();
  const LocId = activeLOC?.id;
  const { data, error, isLoading } = useSWR(LocId ? `${apiUrl}/v1/locs/${LocId}/payment/next` : null)
  return {
    LOCPayment: data,
    LOCPaymentLoading: isLoading || locLoading,
    LOCPaymentError: error || locError
  }
}

export default getLOCPayment;
