/* eslint-disable-next-line */

import { Col, Row, Stack } from 'react-bootstrap';
import { openDocumentPDF } from 'merchant-lib';
import { formatDate } from '@odmonorepo/odfunctions';
import { Button } from '@odmonorepo/core-components';

export interface DocumentLineItemProps {
  loanId: string;
  loanType: string;
  fileName: string;
  createdOn: Date;
  documentType: string;
  documentId: string;
}

export const DocumentLineItem = ({
  loanId,
  loanType,
  fileName,
  createdOn,
  documentId,
}: DocumentLineItemProps) => {
  const { openPDF, isLoading } = openDocumentPDF(documentId);
  const newLabel =
    (new Date().getTime() - new Date(createdOn).getTime()) /
      (1000 * 60 * 60 * 24.0) <
    30; //calculate if documents are created within 30 days
 
  return (
    <Row className="justify-content-between">
      <Col xs={7} md={4}>
        {(loanType || loanId) && (
          <div>
            <div className="d-md-inline-block d-none me-1">
              {loanType}
            </div>
            <span>&#40;...{loanId}&#41;</span>{' '}
          </div>
        )}{' '}
      </Col>
      <Col xs={5} sm={4} className="col-sm-4 order-md-3 text-end">
        {formatDate(createdOn)}
      </Col>
      <Col xs={12} sm={4} className="text-md-start fw-bold order-md-2">
        <Button
          className="p-0 fs-7"
          loading={isLoading}
          variant="link"
          onClick={() => openPDF()}
        >
          <Stack direction="horizontal" gap={4}>
            {fileName}
          </Stack>
        </Button>
      </Col>
    </Row>
  );
};

export default DocumentLineItem;
