import React from 'react';
import { FormattedMessage } from 'react-intl';

const CustomErrorMessage: React.FC = () => {
  return (
    <FormattedMessage
      id="customErrorMessage.body"
      values={{
      tel: (txt: any) => {
        return (
          <a href={`tel:${txt}`} className="fw-bold">
            {txt}
          </a>
        );},
      }}
    />
  );
};

export default CustomErrorMessage;