import { FormCheckProps, FormCheck } from 'react-bootstrap';
import { omit } from 'lodash';
import { v4 } from 'uuid';

interface ToggleElementProps extends FormCheckProps {
  inputClassName?: string;
  type?: "radio" | "checkbox";
}

const ToggleElement = ({type='radio', hidden=true, id=v4(), className, inputClassName = 'btn-check', ...props}: ToggleElementProps) => <>
  <FormCheck.Input id={id} type={type} hidden={hidden} className={inputClassName} tabIndex={-1} {...omit(props, ['children', 'className', 'disabled'])}/>
  <FormCheck.Label htmlFor={id} className={className} tabIndex={props?.disabled ? -1 : 0} role={props?.disabled ? "" : "button"} onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && document.getElementById(id)?.click()}>{props.children}</FormCheck.Label>
</>

export default ToggleElement;
