import useSWR from 'swr';
import { LDR, useApplication } from '../..';

export interface useLDRProps {
  ldrLoading: boolean;
  loanDocRequest: LDR[] | null;
  ldrError: any;
  hasBankRequest: boolean;
  hasClosedBankRequest: boolean;
  hasOpenBankRequest: boolean;
  LDRType?: string;
}

export function getLDR(): useLDRProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { opportunityId, isLoadingApplication, isErrorApplication  } = useApplication();
  const { data, error, isLoading } = useSWR(opportunityId ? `${apiUrl}/v1/ldr/${opportunityId}` : null, {revalidateOnMount: true})
  return {
    hasOpenBankRequest: data?.some(r => r.issueType === "No bank statements" && r.status === "REQUESTED"),
    hasClosedBankRequest: data?.some(r => r.issueType === "No bank statements" && r.status !== "REQUESTED"),
    hasBankRequest: data?.some(r => r.issueType === "No bank statements"),
    LDRType: data?.filter(ldr => ldr.status === "REQUESTED")[0]?.issueType || undefined,
    loanDocRequest: data,
    ldrError: error || isErrorApplication,
    ldrLoading: isLoading || isLoadingApplication
  }
}

export default getLDR;
