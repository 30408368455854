import useSWR, {useSWRConfig} from 'swr';
import useParties from '../useParties/useParties';
import useSWRMutation from "swr/mutation";

export interface SessionProps {
  isSessionLoading: boolean;
  sessionDetails: any;
  isSessionError: any;
  triggerSessionDetails: any;
}

export function postSession(): SessionProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { fetcher } = useSWRConfig();
  const { hasLOC, isErrorParties, isLoadingParties } = useParties();

  async function sendRequest(url: any) {
    return fetcher && fetcher(url, {method: 'POST', body: JSON.stringify({})});
  }

  const { data, error, trigger, isMutating } = useSWRMutation(`${apiUrl}/v1/locs/instantfunding/${hasLOC}`, sendRequest);

  return {
    sessionDetails: data,
    isSessionLoading: isMutating || isLoadingParties,
    isSessionError: error || isErrorParties,
    triggerSessionDetails: trigger
  }
}

export default postSession;
