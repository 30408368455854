import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import RModal from 'react-bootstrap/Modal';

interface ModalProps {
  showModal: boolean;
  handleClose: () => void;
  title?: string;
  children?: React.ReactNode;
  buttonText?: React.ReactNode;
  onButtonClick?: () => void;
}
const Modal = ({ showModal = false, handleClose, title, buttonText, children, onButtonClick, ...props }: ModalProps) => {

  return (
    <div>
      <RModal show={showModal} onHide={handleClose}>
        <RModal.Header closeButton>
          <RModal.Title>{title}</RModal.Title>
        </RModal.Header>
        <RModal.Body>{children}</RModal.Body>
        <RModal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onButtonClick}>
            {buttonText}
          </Button>
        </RModal.Footer>
      </RModal>
    </div>
  );
}

export default Modal;
export { Modal };