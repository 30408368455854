import { combineClassNames } from '@odmonorepo/odfunctions';
import ToggleElement from 'libs/core-components/src/lib/ToggleElement/ToggleElement';
import { CalculatorProps, Offer } from 'libs/merchant-lib/src/Helpers/types';
import { useEffect } from 'react';
import { Badge, ButtonGroup, Stack } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export const CalculatorTermSelection = ({ tlOffers }: CalculatorProps) => {
  const { watch, setValue } = useFormContext();
  const loanAmount = watch('offer.loanAmount');
  const termDisabled = (o: Offer) =>
    loanAmount < o?.minLineAmount || loanAmount > o?.maxLineAmount;
  const validTerms = (offers: Offer[]) =>
    offers.filter((o) => !termDisabled(o));

  return (
    <Stack gap={2}>
      <div className="fw-bold">
        <FormattedMessage id="calculator.termChoices" />
      </div>
      <Controller
        name={'offer'}
        render={({ field: { onChange, value, name } }) => {
          useEffect(() => {
            termDisabled(
              tlOffers.filter((o) => o.offerId === watch('offer.offerId'))[0],
            ) && setValue('offer', {offerId: validTerms(tlOffers)[0].offerId, ppdDiscount: validTerms(tlOffers)[0].prePaymentDiscountPct === 100 ? "100" : "25"});
          }, [loanAmount]);
          return (
            <ButtonGroup
              className={combineClassNames(tlOffers.length < 4 ? 'd-flex' : 'd-md-flex d-block', 'w-100')}
            >
              {tlOffers
                .sort((a, b) => a.term - b.term)
                .map((o) => {
                  return (
                    <ToggleElement
                      value={o.offerId}
                      style={{ width: '' }}
                      onChange={(e) => onChange({
                        offerId: e.target.value,
                        loanAmount: watch('offer.loanAmount'),
                        ppdDiscount: o.prePaymentDiscountPct === 100 ? "100" : "25"
                      })}
                      name={name}
                      className={combineClassNames(
                        'btn btn-outline-accent w-100',
                        value?.offerId === o.offerId && !termDisabled(o)
                          ? 'fw-bold'
                          : '',
                        termDisabled(o) ? 'disabled' : '',
                      )}
                      checked={value?.offerId === o.offerId && !termDisabled(o)}
                      disabled={termDisabled(o)}
                    >
                      {o.term + (o.prePaymentDiscountPct === 100 ? '*' : '')}
                    </ToggleElement>
                  );
                })}
            </ButtonGroup>
          );
        }}
      />
      <PPDBadge tlOffers={tlOffers} />
    </Stack>
  );
};

const PPDBadge = ({ tlOffers }) => {
  const { watch } = useFormContext();
  const hasPPD = tlOffers.some((o) => o.prePaymentDiscountPct === 100);
  const PPD100 = tlOffers.some(
    (o) =>
      o.offerId === watch('offer.offerId') && o.prePaymentDiscountPct === 100,
  );
  return hasPPD ? (
    <div>
      <Badge
        bg={PPD100 ? 'success' : 'default'}
        text={PPD100 ? undefined : 'dark'}
        className="text-wrap"
      >
        <FormattedMessage id="ppd.100.available" />
      </Badge>
    </div>
  ) : null;
};
