import { IntlProvider } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import BusinessProfile from './Pages/BusinessProfile/BusinessProfile';
import { merchantPortalText } from './en-US.js';
import Documents from './Pages/Documents/Documents';
import Dashboard from './Pages/Dashboard/Dashboard';
import DocumentsCenter from './Pages/DocumentsCenter/DocumentsCenter';
import DrawFunds from './Pages/DrawFunds/DrawFunds';
import Payment from './Pages/Payment/Payment';
import PersonalProfile from './Pages/PersonalProfile/PersonalProfile';
import Renewal from './Pages/Renewal/Renewal';
import InstantFunding from './Pages/InstantFunding/InstantFunding';
import { useParties } from 'merchant-lib';
import {AccountHomeLayout, CheckoutLayout, OfferConfigLayout} from "./Layout/AppLayout";
import OfferConfig from './Pages/OfferConfig/OfferConfig';
import OfferReview from './Pages/OfferReview/OfferReview';

export const MerchantRoutes = () => {
  const { ifOptEnabled, optimizely } = useParties();

  return (
    <IntlProvider locale="en-us" messages={merchantPortalText}>
      <Routes>
        <Route path="/" element={<AccountHomeLayout />}>
          <Route path="/" index element={<Dashboard/>}/>
          <Route path="profile" element={<PersonalProfile/>}/>
          <Route path="business" element={<BusinessProfile/>}/>
          <Route path="documents" element={<Documents/>}/>
          <Route path="drawfunds" element={<DrawFunds/>}/>
          <Route path="payment" element={<Payment/>}/>
          <Route path="documents-center" element={<DocumentsCenter/>}/>
          <Route path="renewal" element={<Renewal/>}/>
          {ifOptEnabled && <Route path="instantfunding" element={<InstantFunding/>}/>}
        </Route>
        {optimizely?.monorepoOfferPresentment && (
          <>
            <Route path="/" element={<OfferConfigLayout />}>
              <Route path="offer" element={<OfferConfig />} />
            </Route>
            <Route path="/" element={<CheckoutLayout stepIndex={0.2} />}>
              <Route path="checkout" element={<OfferReview />} />
            </Route>
          </>
        )}
      </Routes>
    </IntlProvider>
  );
};

export default MerchantRoutes;
