import { Stack, Col, ListGroup, ListGroupItem, StackProps } from 'react-bootstrap';

export interface ListProps { obj: object;}

export function List({ obj }: ListProps & StackProps) {
  return (
    <Stack>
      <ListGroup className="fs-7" variant="flush">
        <ListGroupItem variant="light" className="bg-white" />
        {obj &&
          Object.entries(obj).map(([k, v], idx) => (
            <ListGroupItem
              variant="light"
              key={idx}
              className="py-4 d-md-flex bg-white"
            >
              <Col xs={12} md={4}>
                {k}
              </Col>
              <Col xs={12} md={8}>
                {v}
              </Col>
            </ListGroupItem>
          ))}
        <ListGroupItem variant="light" className="bg-white" />
      </ListGroup>
    </Stack>
  );
}

export default List;
