import { Caret, FormattedMessageDiv, FormattedNumberDiv, Tooltip } from '@odmonorepo/core-components';
import { useState } from 'react';
import { Collapse, Stack } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

export interface BorrowingDetailsCardProps {
  apr: number;
  cod: number;
  monthlyInterest: number;
}

export const BorrowingDetails = ({
  apr,
  cod,
  monthlyInterest
}: BorrowingDetailsCardProps) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  return (
    <div>
      <Stack
        gap={4}
        className="d-flex justify-content-between"
        direction="horizontal"
        onClick={() => setOpen(o => !o)}
        role="button"
      >
        <div className="d-flex text-primary mb-2">
          <FormattedMessageDiv
            className="fw-bold fs-6"
            id="borrowing.details.header"
          />
          <Caret className="mx-3" direction={open ? 'up' : 'down'} />
        </div>
      </Stack>
      <Collapse in={open}>
        <div className='lh-sm'>
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="borrowing.details.monthly.interest" />
          </div>
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            suffix={`%`}
            maximumFractionDigits={2}
            roundingMode='trunc'
            value={monthlyInterest}
          />
        </Stack>
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="borrowing.details.CoD" />
            <Tooltip
              color="dark"
              title={`${intl.formatMessage({ id: 'borrowing.details.tooltip' })}`}
            />
          </div>
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            maximumFractionDigits={1}
            roundingMode='trunc'
            suffix="&cent;"
            value={(cod - 1) * 100}
          />
        </Stack>
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="borrowing.details.APR" />
          </div>
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            suffix={`%`}
            maximumFractionDigits={2}
            roundingMode='trunc'
            value={apr}
          />
        </Stack>
        </div>
      </Collapse>
    </div>
  );
};

export default BorrowingDetails;