import { FormattedMessageDiv } from '@odmonorepo/core-components';
import { Row, Tab } from 'react-bootstrap';
import BlueBlob from '../../../../core-components/src/lib/BlueBlob/BlueBlob';
import { useState } from 'react';
import IFDebitCardDetails from '../../Features/InstantFunding/IFDebitCardDetails';
import IFLandingCard from '../../Features/InstantFunding/IFLandingCard';
import IFrameCard from '../../Features/InstantFunding/IFrameCard';


export const InstantFunding = () => {
  const [activeTab, setActiveTab] = useState('landing');
  const [registrationError, setRegistrationError] = useState(false);
  const [authorizedUrl, setAuthorizedUrl] = useState('');

  return (
    <>
      <BlueBlob>
        <Row>
          <h1 className="h2 mb-6 text-center">
            <FormattedMessageDiv id={'instantFunding.title'} />
          </h1>
        </Row>
      </BlueBlob>

      <Tab.Container
        mountOnEnter={true}
        unmountOnExit={true}
        activeKey={activeTab}
      >
        <Tab.Content>
          <Tab.Pane eventKey={'landing'}>
            <IFLandingCard
              onContinue={() => setActiveTab('iFrame')}
              setAuthorizedUrl={setAuthorizedUrl}
              registrationError={registrationError}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={'iFrame'}>
            <IFrameCard
              setActiveTab={setActiveTab}
              setRegistrationError={setRegistrationError}
              authorizedUrl={authorizedUrl}
            ></IFrameCard>
          </Tab.Pane>
          <Tab.Pane eventKey={'success'}>
            <IFDebitCardDetails />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default InstantFunding;
