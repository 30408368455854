import { Outlet } from 'react-router-dom';
import { Stack } from 'react-bootstrap';
import { Footer } from "@odmonorepo/core-components";
import SideNav from "./Components/SideNav/SideNav";
import Header from "./Components/Header/Header";
import { SteppedRange } from "../../../core-components/src/lib/SteppedRange/SteppedRange";
import { CobrandedHeader } from './Components/Header/CobrandedHeader';

interface AppLayoutProps {
  sidebar?: JSX.Element
  header?: JSX.Element
}

const AppLayout = ({sidebar, header}: AppLayoutProps) => {
  return (
    <div
      className="d-flex flex-column flex-lg-row"
      style={{ minHeight: '100vh' }}
    >
      {sidebar}
      <div className="w-100">
        <div className="w-100 d-flex justify-content-center p-5">
          <Stack style={{ maxWidth: '1050px' }}>
            {header}
            <Outlet />
          </Stack>
        </div>
        <div className="w-100 mt-5">
          <Footer />
        </div>
      </div>
    </div>
  );
}


export const AccountHomeLayout = () => <AppLayout sidebar={<SideNav/>}/>
export const AppIntakeLayout = () => <AppLayout header={<CobrandedHeader/>}/>
export const OfferConfigLayout = () => <AppLayout header={<Header/>}/>

interface CheckoutLayoutProps {
  stepIndex: number
}

const steps = [
  'Accept your offer',
  'Confirm your information',
  'Sign your agreement',
  'Get your funds',
] 

export const CheckoutLayout = ({stepIndex}: CheckoutLayoutProps) =>
  <AppLayout
    header={<>
      <Header/>
      <SteppedRange className="my-5" steps={steps} stepIndex={stepIndex}/>
    </>}
  />
