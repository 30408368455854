const adobeAnalyticsPlugin = () => ({ 
  name: 'adobe-analytics',
  initialize: () => {
    const script = document.createElement('script');
    script.src = process.env["NX_PUBLIC_ADOBE_ANALYTICS_URL"]!;
    document.body.appendChild(script);
    }
})

export default adobeAnalyticsPlugin;
