import useSWR from 'swr';
import { SalesAgentInfo } from '../../Helpers/types';

export interface SalesAgentInfoProps {
  isSalesAgentInfoLoading: boolean;
  salesAgentInfo: SalesAgentInfo;
  isSalesAgentInfoError: any;
}

export function getSalesAgentInfo(): SalesAgentInfoProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { data, error, isLoading} = useSWR(`${apiUrl}/v1/sales-agent-info/current`);
  return {
    salesAgentInfo: data,
    isSalesAgentInfoLoading: isLoading,
    isSalesAgentInfoError: error
  }
}

export default getSalesAgentInfo;
