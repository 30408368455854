import { useEffect } from 'react';
import {  useApplication, useOffers, useParties } from '../../API';
import { useNavigate } from 'react-router';
import { AppError} from '../../Helpers/types';

export const offerHelper = (): {
  offerHelperError: boolean;
  offerHelperLoading: boolean;
  showNotification: boolean;
  navigateToCheckout: () => void;
} => {
  const navigate = useNavigate();

  const { applicationData, isErrorApplication, isLoadingApplication } = useApplication();
  const { tlOffer, locOffer, selectedOffer, product, isErrorOffers, isLoadingOffers } = useOffers();
  const { isLoadingParties, isErrorParties, partiesData, isApplicant, optimizely } = useParties();
  const urlParams = new URLSearchParams({ applicationId: applicationData?.id, loanId: partiesData?.parties[0]?.loans[0]?.id}).toString()
  const navigateToCheckout = () => window.location.href = process.env['NX_PUBLIC_CHECKOUT_URL'] + '?' + urlParams

  useEffect(() => {

    if (isApplicant) {
      if (tlOffer === null && locOffer === null) throw AppError.NoOffer;

      if (!isLoadingOffers) {
        if (selectedOffer !== null || product === "TermLoan") optimizely?.monorepoOfferPresentment ? navigate("/offer") : navigateToCheckout();
        else throw new Error("New applicant must go through sales assist");
      }
    }
  }, [isLoadingApplication, isLoadingOffers, applicationData]);

  return {
    offerHelperLoading: isLoadingApplication || isLoadingOffers || isLoadingParties,
    offerHelperError: isErrorApplication || isErrorOffers || isErrorParties,
    showNotification: !sessionStorage.getItem('dismiss') && applicationData?.status === 'APPROVED' && product ? true : false,
    navigateToCheckout: navigateToCheckout
  };
}
