import {
  Button,
  Card,
  FormattedMessageDiv,
  ProcessingApplication,
} from '@odmonorepo/core-components';
import { Stack } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export function ReceivedCard() {
  const navigate = useNavigate();

  return (
    <Card>
      <Stack gap={5}>
        <div className="d-flex flex-column text-center">
          <ProcessingApplication />
          <FormattedMessageDiv
            className="h2"
            id="application.softDecline.title"
          />
        </div>
        <div className="d-flex justify-content-center">
          <div>
            <FormattedMessage id="application.softDecline.msg" tagName="p" />
            <FormattedMessage
              id="loan.advisor.questions"
              values={{
                tel: (txt: any) => {
                  return (
                    <a href={`tel:${txt}`} className="fw-bold">
                      {txt}
                    </a>
                  );
                },
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button onClick={() => navigate('/')}>
            <FormattedMessage id="btn.returnToDashboard" />
          </Button>
        </div>
      </Stack>
    </Card>
  );
}

export default ReceivedCard;
