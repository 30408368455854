import { createLDR, useParties } from 'merchant-lib';
import { useWizard } from 'react-use-wizard';
import { useEffect } from 'react';
import { BlueBlob, ErrorPage, FormattedMessageDiv, LoadingAnimation } from '@odmonorepo/core-components';
import { Stack } from 'react-bootstrap';
import PlaidCard from '../../Plaid/PlaidCard';
import PlaidDisclaimer from '../../Plaid/PlaidDisclaimer';
import StatementUploader from './StatementUploader';

export const BankInfoCard = () => {
  const { optimizely, isLoadingParties} = useParties();
  const { hasOpenBankRequest, ldrLoading, ldrError } = createLDR();
  const { nextStep } = useWizard();

  useEffect(() => {
    hasOpenBankRequest === false && nextStep();
  }, [hasOpenBankRequest]);

  if (ldrLoading || isLoadingParties || hasOpenBankRequest === false)
    return (
      <Stack>
        <LoadingAnimation className="mx-auto mt-7" />
      </Stack>
    );

  if (ldrError) return <ErrorPage />;

  return (
    <Stack gap={5}>
      <BlueBlob>
        <FormattedMessageDiv className="h3" id="renewalApp.step.final" />
      </BlueBlob>
      <PlaidCard next />
      {optimizely?.eceRenewalMilestone3 && <StatementUploader />}
      <PlaidDisclaimer />
    </Stack>
  );
};

export default BankInfoCard;
