import { Caret } from '@odmonorepo/core-components';
import { Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import LoanDocsCard from '../../Features/Documents/Cards/LoanDocsCard';
import StatementsCard from '../../Features/Documents/Cards/StatementsCard';
import { useState } from "react";

export const Documents = () => {
  const navigate = useNavigate();
  const [visibleCard, setVisibleCard] = useState(null);

  const toggleFilterVisibility = (cardName) => {
    setVisibleCard((prev) => (prev === cardName ? null : cardName));
  };

  return (
    <div>
      <h2 className="d-flex fw-normal text-dark">
        <Caret
          role="button"
          width={13}
          direction="left"
          onClick={() => navigate(-1)}
        />
      </h2>
      <Stack gap={3}>
        <StatementsCard
          filterVisible={visibleCard === 'StatementsCard'}
          toggleFilterVisibility={() => toggleFilterVisibility('StatementsCard')}  />
        <LoanDocsCard
          filterVisible={visibleCard === 'LoanDocsCard'}
          toggleFilterVisibility={() => toggleFilterVisibility('LoanDocsCard')}  />
      </Stack>
    </div>
  );
};

export default Documents;
