import { Stack } from 'react-bootstrap';
import OTPCard from '../../Features/OneTimePayment/Cards/OTPCard';

export function Payment() {
  return (
    <Stack>
      <OTPCard />
    </Stack>
  );
}

export default Payment;
