export interface Contact {
  id: number;
  type: string;
  value: string;
}

export interface Address {
  id: number;
  lines: string[];
  city: string;
  state: string;
  postalCode: string;
  country: string;
  type: string;
}

export interface ProfileProps {
  fullName: string;
  id: string;
  addresses: Address[];
  contacts: Contact[];
}

export interface Identifier {
  type: string;
  value: string;
}

export interface Draw {
  amount: number;
  apr: number;
  numberOfPayments: number;
  outstandingPrincipal: number;
  periodicPayment: number;
  totalInterest: number;
}

export interface LoC {
  creditLimit: number;
  balanceOutstanding: number;
  availableCredit: number;
  id: string;
  status: string;
  drawOnHold: boolean;
  periodicPayment?: number;
  annualPercentageRate?: number;
  outstandingPrincipal?: number;
  paymentFrequency?: string;
  term?: string;
  type?: 'LINE_OF_CREDIT';
  reamortizationStatus?: string | null;
  instantFunding?: {
    eligible: boolean;
    cardInfo: object;
  };
}

export interface TermLoan {
  approvedAmount: number;
  balanceOutstanding: number;
  balancePaid: number;
  estimatedPaymentsRemaining: number;
  estimatedPayoffAmount?: number;
  estimatedPayoffDate?: string;
  id: string;
  type: 'FIXED_TERM';
  percentagePaidDown: number;
  term: string;
  status: string;
}

export interface LoanActivity {
  amount: number;
  beginningBalance: number;
  clearedDate: string;
  currency: string;
  initiatedDate: string;
  interest: number;
  loanId: string;
  principal: number;
  remainingBalance: number;
  status: string;
  transactionSetId: string;
  type: string;
  loanType: string;
}

export interface Session {
  status: string;
  client_message: string;
  data?: {
    session_identifier: string;
    authorized_url: string;
    authorized_url_expiration_utc: string;
    participant_unique_id1: string;
    participant_unique_id2?: string;
  },
  time: string;
}

export interface Documents {
  loanId: string;
  loanType: string;
  documentId: number;
  documentType: 'MONTHLY_STATEMENT' | 'SIGNED_CONTRACT';
  fileName: string;
  createdOn: string;
}

export interface User {
  email: string;
  userId: string;
}

type AppStatus =
  |'APPROVED'
  |'CLOSED_LOST'
  |'CLOSED_WON'
  |'NOT_APPROVED'
  |'REROUTED_LEGACY'
  |'STARTED'
  |'SUBMITTED'
  |'SYSTEM_GENERATED'

  type createdBy = 'SoFi' | string;

export interface Application {
  id: string;
  type: 'NEW' | 'RENEWAL';
  href: string;
  userId: string;
  status: AppStatus;
  sourcePartyId: string;
  issuesExist: boolean;
  issues: unknown;
  data: unknown;
  metadata: {
    updatedAt: string;
    sfOpportunityId: string;
    channelCode: string;
    createdBy: createdBy;
  };
}

export interface Offer {
  offerId: string;
  term: number;
  maxLineAmount: number;
  minLineAmount: number;
  defaultPaymentFrequency: string;
  originationFeePct: number;
  prePaymentDiscountPct: number | null;
  centsOnDollar: number;
  product: 'TermLoan' | 'LineOfCredit';
  pricedDetails: PricedDetails;
  presentableDisbursementAmt: number;
  requiredInitialDraw: number;
  returnedPaymentFee: number;
  lateFee: number;
  prePaymentDiscountRelatedOfferIds: Record<string, string>;
  userActionStatus: 'HIDDEN' | 'NOT_SELECTED' | 'REJECTED' | 'SELECTED';
  [x: string]: unknown;
}

export interface PricedDetails {
  lineAmount: number;
  trueCentsOnDollar: number;
  trueAPR: number;
  paymentFrequency: string;
  payment: number;
  disbursementAmount: number;
  totalAmountPaidBack: number;
  loanOriginationFee: number;
  term: number;
  totalLoanCost: number;
  averageMonthlyPayment: number;
  modelId: string;
  monthlyFee: number;
  [x: string]: unknown;
}

export interface NextPayment {
  loanId: string;
  loanStatus: 'NORMAL' | 'ARRANGEMENT' | 'PAST_DUE';
  onHold: boolean;
  frequency: 'DAILY' | 'WEEKLY' | 'MONTHLY';
  currency: 'USD';
  nextPaymentAmount: number;
  nextPaymentDate: string;
  payment?: {
    amount: number;
    scheduledDate: string;
  };
  estimatedPaymentsRemaining: number;
}

export interface Profile {
  firstName: string;
  lastName: string;
  fullName: string;
  preferredName: string;
  href: string;
  id: string;
  addresses: Address[];
  contacts: Contact[];
}

export interface BusinessProfile {
  id: string;
  legalName: string;
  doingBusinessAs: string;
  legalEntityType:
    | 'COMPANY'
    | 'SOLE_TRADER'
    | 'PARTNERSHIP'
    | 'TRUST'
    | 'SOLE_PROPRIETOR'
    | 'LLC'
    | 'CORPORATION'
    | 'GENERAL_PARTNERSHIP'
    | 'LP'
    | 'LLP'
    | 'NON_PROFIT'
    | 'INDEPENDENT_CONTRACTOR'
    | 'S_CORP'
    | 'C_CORP'
    | 'TRIBAL_BUSINESS'
    | 'ELIGIBLE_SELF_EMPLOYED_INDIVIDUAL'
    | 'VETERANS_ORG';
  href: string;
  addresses: Address[];
  contacts: Contact[];
  identifiers: Identifier[];
}

export interface LDR {
  id: number;
  opportunityId: string;
  salesforceId: string;
  createdAt: string;
  createdBy: string;
  classification: string;
  issueType: string;
  merchantRequest: string;
  status: string;
  requestType: string;
  details: string;
  explanation: string;
  rejectionReason: string | null;
  reviewedDateTime: string | null;
  userSubmittedDateTime: string | null;
  merchantProvidedExplanation: string | null;
  documentSalesforceId: string | null;
  attachments: any[];
  source: string;
}

export interface RenewalEligibility {
  loceligible: boolean;
  termLoanEligible: boolean;
}

export interface ODORenewal {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDismiss?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  cta?: string;
  title?: string;
  message: string;
}

export interface Parties {
  parties: [
    {
      businesses: [
        {
          href: string;
          id: string;
          legalName: string;
        }
      ];
      id: string;
      loans: [
        {
          href: string;
          id: string;
          loanType: string;
        }
      ];
      people: [
        {
          href: string;
          id: string;
          name: string;
        }
      ];
      type: string;
    }
  ];
  user: {
    href: string;
    id: string;
    name: string;
  };
  instantFundingEnabled: boolean;
  salesAgentInfoEnabled: boolean;
}

export interface OTPData {
  bankAccount: string;
  bankName: string;
  lowerDraw: number;
  nextBusinessDay: string;
  nextPaymentAmount: number;
  nextPaymentDate: string;
  pastDue: number;
  remainingBalance: number;
  upperDraw: number;
}

export interface rawOffersProps {
  customerData: CustomerData,
  loanDetails?: LoanDetails,
  applicationOffer: {
    experienceType: string;
    selectedOffer: string | null;
    offers: Offer[];
    [x: string]: unknown;
  };
  [x: string]: unknown;
}

export interface offerPreviewData {
  offerId: string;
  prevInterest: number | undefined;
  remainingPrincipal: number | undefined;
  lineAmount: number;
  disbursementAmount: number;
  totalAmountPaidBack: number;
  loanOriginationFee: number;
  totalLoanCost: number;
  term: number;
  paymentFrequency: string;
  trueAPR: number;
  trueCentsOnDollar: number;
  payment: number;
  averageMonthlyPayment: number;
  monthlyFee: number;
  requiredInitialDraw: number;
  returnedPaymentFee: number;
  lateFee: number;
  prePaymentDiscountPct: number | null;
}

export interface termLoanOfferProps {
  offerPreviewData: offerPreviewData;
  applicationType: string | null;
  businessState: string;
}

export interface SalesAgentInfo {
  name: string,
  email: string,
  phone: string,
  fax: string
}

export interface LoanDetails {
  remainingPrincipal: number;
  remainingInterest: number;
  loanAmount: number;
  disbursementAmount: number;
  term: number;
  paymentFrequency: string;
  [x: string]: unknown;
}

export interface CustomerData {
  businessCountry: string;
  businessState: string;
  legalEntity: string;
  [x: string]: unknown;
}

export interface CalculatorProps {
  tlOffers: Offer[]
  [x: string]: unknown;
}

export interface CheckoutData {
  checkoutStatus: CheckoutStatus
}

export enum CheckoutStatus {
  INITIALIZED = "INITIALIZED",
  UPDATED = "UPDATED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  VOID = "VOID"
}

export const AppError = {
  NoOffer: new Error("No offer available")
} as const;
