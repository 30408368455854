import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

export interface UsePlaidProps {
  getClientInfo: Function;
  trackPlaid: Function;
  isLoading: boolean;
}

interface PlaidRequest {
  callback: string;
  error: string;
  eventName: string;
  metadata: string;
  publicToken: string;
}

export function usePlaid(opportunityId: string): UsePlaidProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL'];
  const { fetcher } = useSWRConfig();
  const [loadingClient, setLoadingClient] = useState(false);

  async function sendPlaidRequest(url, { arg }: { arg: PlaidRequest}) {
    return fetcher && fetcher(url, {method: 'POST', body: JSON.stringify(arg), returnType: 'RAW'});
  };
  const sendRequest = (url) => {
    setLoadingClient(true);
    return fetcher && fetcher(url);
  };

  const { trigger: trackPlaid } = useSWRMutation(`${apiUrl}/v1/plaid/track/${opportunityId}`, sendPlaidRequest);
  const { data, error, trigger: getClientInfo} = useSWRMutation(`${apiUrl}/v1/plaid/client-info`, sendRequest)

  useEffect(() => {
    (data || error) && setLoadingClient(false);
  }, [data, error, sendRequest]);

  return {
    getClientInfo: getClientInfo,
    isLoading: loadingClient,
    trackPlaid: trackPlaid 
  };
}

export default usePlaid;
