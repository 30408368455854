import {
  Card,
  ErrorPage,
  FormattedMessageDiv,
  LoadingAnimation,
} from '@odmonorepo/core-components';
import { postRenewal, useParties } from 'merchant-lib';
import { useEffect } from 'react';
import { Col, Stack } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useWizard } from 'react-use-wizard';
import { LoanStep } from '../LoanAppForm';

export const AppReviewCard = () => {
  const { sendRenewal, renewalError } = postRenewal();
  const { partyId } = useParties();
  const { getValues } = useFormContext();
  const { goToStep } = useWizard();

  useEffect(() => {
    partyId &&
      sendRenewal({ ...getValues(), partyId: partyId }).then((res) => {
        switch (res?.decisionStatus) {
          case 'APPROVED':
            return goToStep(LoanStep.BANK_INFO);
          case 'DECLINED':
            return goToStep(LoanStep.DECLINED);
        }
      });
  }, [partyId]);

  if (renewalError) return <ErrorPage />;

  const intl = useIntl();

  return (
    <Card className="text-center">
      <Stack gap={4}>
        <FormattedMessageDiv className="h4" id="renewalApp.review.title" />
        <LoadingAnimation />
        <Stack>
          <FormattedMessageDiv
            className="fw-bold mt-2"
            id="renewalApp.review.questions"
            values={{ tel: (txt: any) => <a href={`tel:${txt}`}>{txt}</a> }}
          />
          <FormattedMessageDiv id="renewalApp.review.para1" />
        </Stack>
        <Stack gap={4} className="fw-bold fs-5 flex-column flex-sm-row">
          <Col
            onClick={() =>
              window.open(
                `tel:${intl.formatMessage({ id: 'renewalApp.review.phone' })}`,
                '_self'
              )
            }
            role="button"
            className="card shadow-none bg-body p-5 "
          >
            <FormattedMessageDiv id="renewalApp.review.phone" />
          </Col>
          <Col
            onClick={() =>
              window.open(
                `mailto:${intl.formatMessage({
                  id: 'renewalApp.review.email',
                })}`,
                '_self'
              )
            }
            role="button"
            className="card shadow-none bg-body p-5 text-primary"
          >
            <FormattedMessageDiv id="renewalApp.review.emailSupport" />
          </Col>
        </Stack>
      </Stack>
    </Card>
  );
};

export default AppReviewCard;
