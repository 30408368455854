import { LoanDetailsProps } from '../Components/LoanDetails';

export const rawLoanToLoanDetails = (rawLoanData) => {
  const loanType = rawLoanData?.type;
  return {
    loanId: rawLoanData?.id,
    title: loanType === 'FIXED_TERM' ? 'Term Loan' : 'Line of Credit',
    loanAmount:
      loanType === 'FIXED_TERM'
        ? rawLoanData?.approvedAmount
        : rawLoanData?.creditLimit,
    leftPosNumber:
      loanType === 'FIXED_TERM'
        ? rawLoanData?.balancePaid
        : rawLoanData?.availableCredit,
    rightPosNumber: rawLoanData?.balanceOutstanding,
    percentage:
      loanType === 'FIXED_TERM'
        ? rawLoanData?.percentagePaidDown
        : (rawLoanData?.availableCredit / rawLoanData?.creditLimit) * 100,
  } as LoanDetailsProps;
};
