import {SpinnerProps, Stack} from "react-bootstrap";
import { FormattedMessageDiv, GreenCheck } from "@odmonorepo/core-components";
import {ButtonProps as BButtonProps} from "react-bootstrap/Button";
import React from "react";
import {ResponsiveUtilityValue} from "react-bootstrap/createUtilityClasses";
import {GapValue} from "react-bootstrap/types";
import {MessageDescriptor} from "react-intl";

type GreenCheckWithTextProps = {
    gap: ResponsiveUtilityValue<GapValue>;
    className?: string;
}
export function GreenCheckWithText({ id, gap, className, ...props }: MessageDescriptor & GreenCheckWithTextProps) {
    return (
        <div>
            <Stack direction='horizontal' gap={gap}>
                <GreenCheck/>
                <FormattedMessageDiv id={id} className={className}/>
            </Stack>
        </div>
    )
}
export default GreenCheckWithText;
