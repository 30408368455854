import {
  CheckoutStatus,
  useApplication,
  getCheckoutState,
  useDetailedOffers,
  useParties,
} from 'merchant-lib';
import {
  Button,
  ErrorPage,
  FormattedMessageDiv,
  LoadingCard,
} from '@odmonorepo/core-components';
import TermLoanOfferCard from '../../Features/OfferConfiguration/Cards/TermLoanOfferCard';
import LocOfferCard from '../../Features/OfferConfiguration/Cards/LocOfferCard';
import { FormattedMessage } from 'react-intl';
import { Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { offerHelper } from '../../Features/OfferConfiguration/helpers';

const PRODUCT_TYPE_TERM_LOAN = 'TermLoan';

const OfferReview = () => {
  const { applicationData, isLoadingApplication, isErrorApplication } =
    useApplication();
  const { partiesData, isLoadingParties, isErrorParties, optimizely } = useParties();
  const { selectedOffer, isLoadingDetailedOffers, isErrorOffers } = useDetailedOffers();
  const { navigateToCheckout } = offerHelper();
  const navigate = useNavigate();

  useEffect(() => {
    if(selectedOffer === null) {
      navigate(-1);
    }
  }, [selectedOffer]);

  if (isLoadingDetailedOffers || isLoadingParties || isLoadingApplication || !selectedOffer) return <LoadingCard />;
  if (isErrorOffers || isErrorApplication || isErrorParties)
    return (
      <Stack>
        <ErrorPage />
      </Stack>
    );

  return (
    <Stack gap={5}>
      <h2 className="text-secondary text-center">
        <FormattedMessageDiv id="offerReview.title" />
      </h2>
      {selectedOffer.product === PRODUCT_TYPE_TERM_LOAN ? (
        <TermLoanOfferCard
          offer={selectedOffer}
          applicationType={applicationData.type}
        />
      ) : (
        <LocOfferCard offer={selectedOffer} />
      )}
      <FormattedMessageDiv
        id="offerReview.subtext"
        className="px-3 fs-7 text-muted text-center"
        values={{
          agreementLink: (
            <a href="https://online.ondeck.com/ondeck/terms">
              OnDeck's Application Agreement.
            </a>
          ),
        }}
      />
      <div className="d-flex justify-content-center">
        <Button onClick={() => navigateToCheckout()}>
          <FormattedMessage id="offerReview.acceptOffer" />
        </Button>
      </div>
    </Stack>
  );
};

export default OfferReview;
