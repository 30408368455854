import { Stack } from 'react-bootstrap';
import {
  FormattedMessageDiv,
  Button,
  LoadingAnimation,
  ErrorPage,
} from '@odmonorepo/core-components';
import SelectOfferCard from '../../Cards/SelectOfferCard';
import { useWizard } from 'react-use-wizard';
import { OfferConfigStep } from '../../../../Pages/OfferConfig/OfferConfig';
import { Controller, useFormContext } from 'react-hook-form';
import { useDetailedOffers } from 'libs/merchant-lib/src/API';
import ToggleElement from 'libs/core-components/src/lib/ToggleElement/ToggleElement';

export const SimpleConfig = () => {
  const { goToStep } = useWizard();
  const { watch } = useFormContext();
  const { tlOffers, isLoadingDetailedOffers, isErrorOffers } = useDetailedOffers();

  if (isLoadingDetailedOffers)
    return (
      <Stack>
        <LoadingAnimation className="mx-auto" />
      </Stack>
    );
  if (isErrorOffers)
    return (
      <Stack>
        <ErrorPage />
      </Stack>
    );

  return (
      <Stack direction='vertical' className='flex-none px-4 py-4 py-md-5 pe-md-2 ps-md-6'>
        <FormattedMessageDiv
          className="fw-bold pb-4"
          id="offerConfig.simple.selectOffer.heading"
        />
        <Controller
          name="offer"
          defaultValue={tlOffers[0].offerId}
          render={({ field: { onChange, name } }) => (
            <Stack direction='vertical' gap={3}>
            { tlOffers.map((o) => (
              <ToggleElement value={o.offerId} onChange={(e) => onChange({
                offerId: e.target.value,
                loanAmount: o.pricedDetails.lineAmount,
                ppdDiscount: o.prePaymentDiscountPct === 100 ? "100" : "25"
              })} name={name}>
                <SelectOfferCard offer={o} selected={watch('offer.offerId') === o.offerId && watch('offer.loanAmount') === o.pricedDetails.lineAmount}/>
              </ToggleElement>
            ))
          }
          </Stack>
          )}
        />
          <Button
            variant="link"
            onClick={() => {
              goToStep(OfferConfigStep.FULL_CONFIG);
            }}
          >
            <FormattedMessageDiv
              className="fs-7"
              id="offerConfig.simple.selectOffer.customize"
            />
          </Button>
      </Stack>
  );
};

export default SimpleConfig;
