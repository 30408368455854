import {
  Card,
  FormattedMessageDiv,
  Button,
  Caret,
  FormattedNumberDiv,
  Tooltip,
  ErrorCard,
  LoadingCard,
  InputSliderCombo,
} from '@odmonorepo/core-components';
import { Stack, Col, Fade } from 'react-bootstrap';
import {
  Draw,
  useDrawPreview,
  useLOC,
} from 'merchant-lib';
import { useState, ReactNode } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

export interface ReviewPricingProps {
  errorMsg?: ReactNode;
  drawAmount: number;
  onChange: (val: number) => void;
  resetPricing: (val) => void;
  updateAlert: (msg) => void;
}

export const ReviewPricingCard = ({
  errorMsg,
  drawAmount,
  onChange,
  resetPricing,
  updateAlert,
}: ReviewPricingProps) => {
  const [spinner, setSpinner] = useState(false);
  const [revealPricing, setRevealPricing] = useState(false);
  const [drawDetails, updateDrawDetails] = useState<Draw>();

  const { activeLOC, locLoading, locError } = useLOC();
  const { triggerPreview, isErrorPreview } = useDrawPreview(activeLOC?.id);
  const intl = useIntl();

  const onCalculate = async () => {
    setSpinner(true);
    setRevealPricing(true);
    let drawPreviewReq = {
      apr: activeLOC?.annualPercentageRate,
      drawAmount: drawAmount,
      outstandingPrincipal: activeLOC?.outstandingPrincipal,
    };
    try {
      const res = await triggerPreview(drawPreviewReq);
      if (res?.amount && !isErrorPreview) {
        updateDrawDetails(res);
        resetPricing(false);
      } 
    } catch (error: any) {
      error?.messages.then(res => res && (
        updateAlert(
          res?.rootException
            .replace('LSI call returned error(s): ', '')
            .replace('(code 206)', '')
        )
      ))
      setRevealPricing(false);
    }
    setSpinner(false);
  };

  if (locError)
    return <ErrorCard name={intl.formatMessage({ id: 'loading.error' })} />;
  if (locLoading) return <LoadingCard />;

  return (
    <div>
      {activeLOC && (
        <Card>
          <header className="fw-bold h6 text-start">
            <FormattedMessage id="drawFunds.selectDrawAmount" />
          </header>
          <Stack className="flex-column flex-md-row mt-4">
            <Col xs={12} md={7} className="border rounded-start border-1 p-5">
              <FormattedMessageDiv
                className="fw-bold text-start"
                id="drawFunds.chooseAmount"
              />
              <Stack gap={3} className="mt-4">
                <InputSliderCombo
                  min={1000}
                  rangeStep={100}
                  inputStep={1}
                  max={Math.trunc(activeLOC?.availableCredit)}
                  upperErrorMessage={intl.formatMessage({
                    id: 'drawFunds.instantFunding.drawMoreThanMax',
                  })}
                  initialValue={drawAmount}
                  onChange={(value) => {
                    onChange(value);
                    updateDrawDetails(undefined);
                    resetPricing(true);
                  }}
                  row
                />
              </Stack>
              <div className="small mt-2 text-danger">{errorMsg}</div>
              <div className="border my-5" />
              <Button
                className="w-100 d-flex justify-content-center"
                spinnerProps={{ size: 'sm', animation: 'border' }}
                onClick={onCalculate}
                loading={spinner}
                disabled={spinner || !drawAmount || activeLOC?.drawOnHold}
              >
                {revealPricing && !drawDetails && !spinner
                  ? 'Recalculate'
                  : 'Calculate'}
                {!spinner && <Caret className="ms-4" direction="right" />}
              </Button>
            </Col>
            <Fade in={revealPricing}>
              <Col
                xs={12}
                md={5}
                className="border-secondary border border-2 rounded-end p-5"
                style={{ height: revealPricing ? 'auto' : 0 }}
              >
                <FormattedMessageDiv
                  className="fw-bold"
                  id="drawFunds.reviewPricing"
                />
                <Stack className="mt-3 text-center bg-light-gray fs-7 p-4">
                  <div>
                    <span className="fw-bold">
                      <FormattedMessage id="drawFunds.drawAmount" />
                    </span>
                    <span className="fs-4 d-block">
                      <FormattedNumber
                        value={drawDetails ? drawDetails.amount : 0}
                        style="currency"
                        currency="USD"
                      />
                    </span>
                  </div>
                  <div>
                    <span className="fw-bold">
                      <FormattedMessage
                        id={
                          activeLOC?.paymentFrequency === 'MONTHLY'
                            ? 'drawFunds.monthlyAutoPayment'
                            : 'drawFunds.weeklyAutoPayment'
                        }
                      />
                    </span>
                    <span className="fs-4 d-block">
                      <FormattedNumber
                        value={drawDetails ? drawDetails.periodicPayment : 0}
                        style="currency"
                        currency="USD"
                      />
                    </span>
                  </div>
                </Stack>
                <Stack gap={2} className="mt-5 fs-7">
                  <div className="d-flex justify-content-between">
                    <FormattedMessage id="drawFunds.repaymentPeriod" />
                    <span className="fw-bold">
                      <FormattedNumber
                        value={drawDetails ? drawDetails.numberOfPayments : 0}
                      />
                      {activeLOC?.paymentFrequency === 'MONTHLY'
                        ? ' months'
                        : ' weeks'}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-md-flex">
                      <FormattedMessageDiv id="drawFunds.outstandingBalance" />
                      <Tooltip className="ms-2">
                        <FormattedMessageDiv id="drawFunds.outstandingBalance.tooltip.msg" />
                      </Tooltip>
                    </div>
                    <FormattedNumberDiv
                      className="fw-bold"
                      value={drawDetails ? drawDetails.outstandingPrincipal : 0}
                      style="currency"
                      currency="USD"
                    />
                  </div>
                </Stack>
              </Col>
            </Fade>
          </Stack>
        </Card>
      )}
    </div>
  );
};
export default ReviewPricingCard;
