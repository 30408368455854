import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation'

export interface UseDrawPreviewProps {
  previewData: any;
  isErrorPreview: any;
  triggerPreview: any;
}
export interface drawReq {
  apr: number,
  drawAmount: number,
  fundingMethod?: string;
  outstandingPrincipal: number
}
 
export function useDrawPreview(locId: any): UseDrawPreviewProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL'];
  const { fetcher } = useSWRConfig();

  async function sendRequest(url, { arg }: { arg: drawReq}) {
    return fetcher && fetcher(url, {method: 'POST', body: JSON.stringify(arg)});
  };

  const { data, error, trigger } = useSWRMutation(`${apiUrl}/v1/locs/${locId}/draw/preview`, sendRequest);

  return {
    previewData: data,
    triggerPreview: trigger,
    isErrorPreview: error
  };
}

export default useDrawPreview;
