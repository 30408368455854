import { Card, FormattedMessageDiv, Button } from '@odmonorepo/core-components';
import { Stack, Collapse } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { loanStep } from '../helpers';
import { LoanStep } from '../LoanAppForm';

export const RenewalNotification = () => {
  const { step, stepError, stepLoading, showRenewal } = loanStep();
  if (stepError || stepLoading || !showRenewal || step === LoanStep.DECLINED) return null;

  return (
    <Collapse in={step !== undefined}>
      <Card bg="primary" text="light" className="text-center" dismissible dismissItem="renewalNotificationDismissed">
        <Stack gap={3}><FundingStatus status={step} /></Stack>
      </Card>
    </Collapse>
  );
};

const Submitted = () => (
  <>
    <FormattedMessageDiv className="h3" id="notification.title.submitted" />
    <FormattedMessageDiv id="notification.message.submitted" />
  </>
);

const Declined = () => (
  <>
    <FormattedMessageDiv className="h3" id="notification.title.declined" />
    <FormattedMessageDiv id="notification.message.declined" />
  </>
);

const Started = () => {
  const navigate = useNavigate();
  return (
    <>
      <FormattedMessageDiv className="h3" id="notification.title.started" />
      <FormattedMessageDiv id="notification.message.started" />
      <div>
        <Button variant="outline-light" onClick={() => navigate('/renewal')}>
          <FormattedMessageDiv id="notification.started" />
        </Button>
      </div>
    </>
  );
};

const Renewal = () => {
  const navigate = useNavigate();
  return (
    <>
      <FormattedMessageDiv className="h3" id="notification.title.renewal" />
      <FormattedMessageDiv id="notification.message.renewal" />
      <div>
        <Button variant="outline-light" onClick={() => navigate('/renewal')}>
          <FormattedMessageDiv id="notification.renewal" />
        </Button>
      </div>
    </>
  );
};

const FundingStatus = ({ status }: { status?: LoanStep }) => {
  switch (status) {
    case LoanStep.DECLINED: return <Declined />;
    case LoanStep.RECEIVED: return <Submitted />;
    case LoanStep.BANK_INFO: return <Started />;
    default: return <Renewal />;
  }
};

export default RenewalNotification;
