import useSWR from 'swr';
import { LDR, useApplication } from '../..';

export interface createLDRProps {
  ldrLoading: boolean;
  loanDocRequest: LDR[] | null;
  ldrError: any;
  hasBankRequest: boolean;
  hasOpenBankRequest: boolean;
}

export function createLDR(): createLDRProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { opportunityId  } = useApplication();
  const { data, error, isLoading } = useSWR(opportunityId ? `${apiUrl}/v1/ldr/create/${opportunityId}` : null, {revalidateOnMount: true})
  return {
    hasOpenBankRequest: data?.some(r => r.issueType === "No bank statements" && r.status === "REQUESTED"),
    hasBankRequest: data?.some(r => r.issueType === "No bank statements"),
    loanDocRequest: data,
    ldrError: error,
    ldrLoading: isLoading
  }
}

export default createLDR;
