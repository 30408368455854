import {
  Avatar,
  Card,
  Caret,
  ErrorCard,
  LoadingCard,
} from '@odmonorepo/core-components';
import { ReactComponent as ShopIcon } from './shop-icon.svg';
import { useParties, usePersons } from 'merchant-lib';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Col, Row, Spinner } from 'react-bootstrap';

export function InfoCard() {
  const { partiesData, isErrorParties, isLoadingParties } = useParties();
  const { email, isLoading } = usePersons();

  const intl = useIntl();
  const navigate = useNavigate();
  const fullName = partiesData?.user?.name;
  const businessName = partiesData?.parties[0]?.businesses[0]?.legalName;

  if (isErrorParties)
    return (
      <ErrorCard name={intl.formatMessage({ id: 'loading.error.profile' })} />
    );
  if (isLoadingParties) return <LoadingCard />;

  return (
    <Card>
      <Row xs={1} sm={2} className="mb-3">
        <Col className="h2 text-dark">{fullName}</Col>
        <Col className="pt-0 pt-sm-4">
          {isLoading ? <Spinner size="sm" /> : email}
        </Col>
      </Row>
      <Card bodyClassName="p-4" className="bg-light-gray shadow-none">
        <div
          role="button"
          onClick={() => navigate('/business')}
          className="d-flex"
        >
          <ShopIcon />
          <div className="ms-3 fs-7 d-flex align-items-center">
            {businessName}
          </div>
          <Caret className="ms-auto d-flex align-items-center" />
        </div>
      </Card>
    </Card>
  );
}

export default InfoCard;
