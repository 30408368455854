import { Card } from '@odmonorepo/core-components';
import { Alert, Stack } from 'react-bootstrap';

export const PlaidSuccessCard = () => {
  return (
    <Card>
      <Alert variant="success">You are now connected to your bank.</Alert>
      <Stack gap={3} className="text-center mt-5">
        <h3> Thank you!</h3>
        <h6>
          Your dedicated loan specialist will contact you within one business
          day after your documents have been reviewed.
        </h6>
      </Stack>
    </Card>
  );
};

export default PlaidSuccessCard;
