import { ErrorCard, LoadingCard } from '@odmonorepo/core-components';
import DocumentFilterList, {DocumentData} from '../Components/DocumentFilterList';
import { useDocuments } from 'merchant-lib';
import { useIntl } from 'react-intl';
import { filterRawDocuments } from '../Transformers/filterRawDocuments';
import { Card } from 'react-bootstrap';

interface FilterListProps {
  filterVisible?: boolean,
  toggleFilterVisibility?: () => void
}

export const LoanDocsCard = ({filterVisible, toggleFilterVisibility}: FilterListProps) => {
  const intl = useIntl();
  const { documents, isLoading, isError } = useDocuments();
  const validDocumentTypes = [ 'SIGNED_CONTRACT', 'SIGNED_DISCLOSURE', 'UNSIGNED_DISCLOSURE', 'UNSIGNED_CONTRACT', 'DRAW_DISCLOSURE', 'BANK_STATEMENT', 'TAX', 'UNCLASSIFIED'];

  if (isError)
    return (
      <ErrorCard name={intl.formatMessage({ id: 'loading.error.documents' })} />
    );
  if (isLoading) return <LoadingCard />;
  return (
    <Card className="p-5 px-0 px-sm-5">
      <DocumentFilterList
        title="Loan Documents"
        documents={filterRawDocuments(documents, validDocumentTypes)}
        filterVisible={filterVisible}
        toggleFilterVisibility={toggleFilterVisibility}
      />
    </Card>
  );
};

export default LoanDocsCard;
