import { LoadingAnimation } from '@odmonorepo/core-components';
import { MerchantRoutes, LoginPage, analytics, useAuth0 } from 'merchant-lib';
import {
  fetcher,
  useParties,
  useUser,
} from 'merchant-lib';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SWRConfig } from 'swr';

// *********************************************************************************
// *********************************************************************************
// ***                                                                           ***
// ***     STOP! DON'T REFACTOR WHEN MFA IS 100% ON. USE MFA-ONLY-ROUTER.TSX     ***
// ***                                                                           ***
// *********************************************************************************
// *********************************************************************************

const AppRouter = () => {
  const { status, data } = useUser();
  const { partiesData } = useParties();
  const [authData, setAuthData] = useState({});
  const [authenticated, setAuthenticated] = useState(false);

  const { getTokenSilently, loading, isAuthenticated } = useAuth0();

  useEffect(() => {
    status &&
      (async () => {
        if (status === 401) {
          isAuthenticated &&
            (await getTokenSilently().then((res: string) =>
              setAuthData({ token: res })
            ));
          setAuthenticated(isAuthenticated);
        } else if (status === 200) {
          setAuthData({ credentials: 'include' });
          setAuthenticated(true);
        }
      })();
  }, [status, isAuthenticated]);

  useEffect(() => {
    data?.userId &&
      analytics.identify(data?.userId, {
        name: partiesData?.user?.name,
        email: data?.email,
      });
    if (status === 200 && data?.monorepoLogin === false)
      window.location.href = process.env.NX_PUBLIC_BAZAAR_URL;
  }, [data, partiesData]);

  if (status === 403) {
    window.location.href = process.env.NX_PUBLIC_ODO_URL + '/login?reset_session=true';
    return null;
  }

  if (status === 401 && loading)
    return <LoadingAnimation className="text-center w-100" />;

  if (authenticated)
    return (
      <SWRConfig
        value={{
          fetcher: async (url, args) => fetcher(url, { ...authData, ...args }),
        }}
      >
        <MerchantRoutes />
      </SWRConfig>
    );
  return (
    !loading && (
      <SWRConfig value={{ fetcher: fetcher }}>
        <Routes>
          <Route path="*" element={status === 401 ? <LoginPage /> : null} />
        </Routes>
      </SWRConfig>
    )
  );
};

export default AppRouter;
