import React, { useState, useEffect } from 'react';
import { Stack, Card, Row, Col, RowProps } from 'react-bootstrap';
import { combineClassNames } from '@odmonorepo/odfunctions';

type ToggleCardGroupProps = RowProps & {
  showRadioBtn?: boolean;
  onChange?: Function;
  flexDirection: 'row' | 'column';
  cardClassName?: string;
  disabledIndex?: number[];
  selectedIdx?: number;
  hideIdx?: number;
  value: any;
};

export const ToggleCardGroup = ({
  showRadioBtn = false,
  onChange = () => {},
  flexDirection = 'row',
  cardClassName,
  disabledIndex,
  selectedIdx,
  hideIdx,
  value,
  ...props
}: ToggleCardGroupProps) => {
  const [selected, setSelected] = useState<number | undefined>(selectedIdx);
  useEffect(() => {
    setSelected(selectedIdx);
  }, [selectedIdx]);
  return (
    <Row
      className={combineClassNames(
        `d-flex flex-${flexDirection}`,
        props.className,
      )}
    >
      {React.Children.map(props.children, (child, i) => {
        const disabled = disabledIndex?.includes(i);
        return (
          hideIdx !== i && (
            <Col>
              <Card
                bg={
                  selected === i && !disabledIndex?.includes(i)
                    ? 'light-gray'
                    : disabledIndex?.includes(i)
                      ? 'disabled'
                      : 'white'
                }
                className={
                  'shadow-none ' +
                  combineClassNames(
                    selected === i ? 'border border-primary' : 'border',
                    cardClassName,
                  )
                }
                role="button"
                onClick={() => {
                  if (!disabled) {
                    onChange(value[i]);
                    setSelected(i);
                  }
                }}
              >
                <Stack direction="horizontal" className="p-3 fw-600 lh-base">
                  {showRadioBtn && (
                    <Col className="col-1 align-self-start pt-5 ms-1">
                      <input
                        className="me-5"
                        readOnly
                        type="radio"
                        checked={selected === i}
                      />
                    </Col>
                  )}
                  {child}
                </Stack>
              </Card>
            </Col>
          )
        );
      })}
    </Row>
  );
};

export default ToggleCardGroup;
