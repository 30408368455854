import { ProfileProps } from 'merchant-lib';
import { ListCardProps } from '../../../../../core-components/src/lib/List/ListCard';
import { findObjWithType, formatAddress } from '@odmonorepo/odfunctions';

export const rawProfileToList = (rawProfile: ProfileProps) => {
  const address = findObjWithType(rawProfile.addresses, 'PHYSICAL_ADDRESS');
  const formattedAddress = address && formatAddress(address);
  const [homePhone, alternatePhone] = rawProfile.contacts?.filter(
    (contact) => contact.type === 'HOME_PHONE'
  );
  const email = findObjWithType(rawProfile.contacts, 'EMAIL_ADDRESS');
  return {
    title: 'Profile',
    listObj: {
      'Home Phone': homePhone ? homePhone.value : '-',
      'Alternate Phone': alternatePhone ? alternatePhone.value : '-',
      Email: email ? email.value : '-',
      Address: formattedAddress ? formattedAddress : '-',
    },
  } as ListCardProps;
};
