import { Card, FormattedNumberDiv } from '@odmonorepo/core-components';
import { OTPdataProps } from 'merchant-lib';
import { Alert, Row, Stack } from 'react-bootstrap';
import { FormattedDate } from 'react-intl';

export const OTPSuccessCard = (OTPdata: OTPdataProps) => {
  return (
    <Card>
      <Stack gap={3}>
        <Alert variant="success">
          <div className="fs-4 fw-bold">Payment Sent. Thank you!</div>
          <div className="mx-auto mb-3">
            Your bank may take up to 24 hours to process this transaction.
          </div>
        </Alert>
        <Stack>
          <h4>Payment Amount</h4>
          <FormattedNumberDiv
            currency="USD"
            style="currency"
            value={Number(OTPdata.paymentAmount)}
          />
        </Stack>
        <hr />
        <Row>
          <Stack className="col-12 col-sm-6">
            <h4>Payment Date</h4>
            <FormattedDate timeZone="UTC" value={OTPdata.paymentDate} />
          </Stack>
          <Stack className="col-6">
            <h4>Payment ID</h4>
            {OTPdata.paymentId}
          </Stack>
        </Row>
      </Stack>
    </Card>
  );
};

export default OTPSuccessCard;
