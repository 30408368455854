import useSWR from 'swr';
import { Address, Profile, useParties } from '../..';
import { findObjWithType, formatAddress } from '@odmonorepo/odfunctions';

export interface UsePersons {
  isLoading: boolean;
  person: Profile;
  personAddress: Address;
  personPhoneNumber: string;
  fullName: string;
  isError: any;
  email: string;
}

const getEmail = (data: Profile) => 
  data?.contacts?.filter(contact => contact.type === 'EMAIL_ADDRESS')[0].value

export function usePersons(): UsePersons {
  const { personId, isLoadingParties } = useParties();

  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { data, error, isLoading } = useSWR(personId ? `${apiUrl}/v1/persons/${personId}` : null)
 
  return {
    person: data,
    personAddress: findObjWithType(data?.addresses,'PHYSICAL_ADDRESS'),
    personPhoneNumber: findObjWithType(data?.contacts, 'PHONE_NUMBER')?.value,
    email: getEmail(data),
    fullName: data?.fullName,
    isLoading: isLoading || isLoadingParties,
    isError: error
  }
}

export default usePersons;
