export function getUniqueYears<T>(items: T[], dateParamName: string): number[] {
    const years = new Set<number>();
    items.forEach((item) => {
      const date = item[dateParamName];
      if (date) {
        const year = new Date(date).getFullYear();
        years.add(year);
      }
    });
    return Array.from(years).sort().reverse();
  }