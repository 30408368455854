import { Card, Placeholder } from 'react-bootstrap';
import LoadingAnimation from '../../images/LoadingAnimation/LoadingAnimation';
import React from 'react';

export const LoadingCardGlow = ({ height }: { height?: number }) => (
  <Placeholder as={Card} animation="glow">
    <Placeholder
      className="rounded-3"
      style={{ minHeight: `${height ? height : 150 + Math.random() * 200}px` }}
    />
  </Placeholder>
);

export const LoadingCard = ({ height }: { height?: number }) => (
  <Card className="rounded-3 pt-7 pb-8 text-center">
    <LoadingAnimation/>
  </Card>
);

interface LoadingDivProps extends React.HTMLAttributes<HTMLDivElement> {
}

export const LoadingDiv = ({...props}: LoadingDivProps) => {
  return (<div {...props}><LoadingAnimation/></div>);
}

export default LoadingCard;
