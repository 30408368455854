import { Avatar, OnDeckLogo } from '@odmonorepo/core-components';
import { Navbar } from 'react-bootstrap';
import { useParties } from "merchant-lib";

export interface HeaderProps {
  fullName: string | never
  businessName: string | never
}

export function Header() {
  const { partiesData } = useParties();

  const merchantName = partiesData?.parties[0]?.people[0]?.name;
  const businessName = partiesData?.parties[0]?.businesses[0]?.legalName;

  return (
    <header className="mb-5">
      <Navbar expand="lg">
        <Navbar.Brand href="/" className="">
          <OnDeckLogo width="150px" />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="d-none d-md-block">
           <p className="fw-bold lh-sm mb-0">{merchantName}</p>
            <p className="fs-8 lh-sm mb-0">{businessName}</p>
          </Navbar.Text>
        </Navbar.Collapse>
        <a className="text-decoration-none lh-1 ms-6" href="libs/merchant-lib/src/Layout/Components/Header/Header#">
          <Avatar name={merchantName} size="sm" className="bg-primary border-primary text-white" />
        </a>
      </Navbar>
    </header>
  );
}

export default Header;
