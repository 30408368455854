import { Stack } from 'react-bootstrap';
import {
  Card,
  ErrorCard,
  FormattedMessageDiv,
  FormattedNumberDiv,
  LoadingCard,
} from '@odmonorepo/core-components';
import { useLOC } from 'merchant-lib';
import { useIntl } from 'react-intl';

export const FundingInfoCard = () => {
  const { activeLOC, locLoading, locError } = useLOC();
  const intl = useIntl();

  if (locError)
    return <ErrorCard name={intl.formatMessage({ id: 'loading.error' })} />;
  if (locLoading) return <LoadingCard />;
  return (
    <Card bodyClassName="p-4">
      {activeLOC && (
        <Stack className="d-flex flex-column flex-sm-row justify-content-between">
          <FormattedNumberDiv
            value={activeLOC.availableCredit}
            currency="USD"
            style="currency"
            className="fw-bold d-flex"
            suffix={
              <FormattedMessageDiv
                className="ms-1"
                id="drawFunds.availableCredit"
              />
            }
          />
          <FormattedMessageDiv
            id="drawFunds.loanId"
            className="small text-start"
            suffix={activeLOC.id}
          />
        </Stack>
      )}
    </Card>
  );
};

export default FundingInfoCard;
