import {useEffect} from "react";
import useSWRMutation from "swr/mutation";
import {useSWRConfig} from "swr";
import useOffers from "../useOffers/useOffers";

export interface UseLoanValidationProps {
  data: any;
  isErrorLoanValidation: any;
  isLoadingLoanValidation: boolean;
}

export const DUPLICATE_TAX_ID_ERROR = "Tax ID is associated to another merchant"
export const TAX_ID_MISMATCH_ERROR = "Tax ID does not match with the given merchant"

export function useLoanValidation(): UseLoanValidationProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']
  const { fetcher } = useSWRConfig();
  const { locOffer, tlOffer, isLoadingOffers } = useOffers();

  async function sendRequest(url, { arg }) {
    return fetcher && fetcher(url, {
      method: 'POST', body: JSON.stringify({...arg})});
  }
  const { data, error, trigger, isMutating } = useSWRMutation(`${apiUrl}/v1/loans/validate`, sendRequest);

  useEffect(() => {
    if (!isLoadingOffers && (tlOffer || locOffer)) {
      trigger();
    }
  }, [isLoadingOffers, tlOffer, locOffer]);

  useEffect(() => {
    if (!isMutating && error) {
      throw new Error('ERROR: Loan validation failed');
    }
  }, [isMutating, error]);

  return {
    data: data,
    isErrorLoanValidation: error,
    isLoadingLoanValidation: isMutating
  };
}

export default useLoanValidation;
