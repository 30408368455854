import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { GreenCheck } from "@odmonorepo/core-components";

export interface TripleGreenCheckProps {
  text1: any;
  text2: any;
  text3: any;
}

export const TripleGreenCheck = ({ text1, text2, text3 }: TripleGreenCheckProps) => {
  return (
    <Row xs={1} md={3} className="px-2 px-md-5 fw-semibold">
      <Col className="d-flex flex-column align-items-center my-3 px-4 text-center text-md-left">
        <GreenCheck />
        {text1}
      </Col>
      <Col className="d-flex flex-column align-items-center my-3 px-4 text-center text-md-left">
        <GreenCheck />
        {text2}
      </Col>
      <Col className="d-flex flex-column align-items-center my-3 px-4 text-center text-md-left">
        <GreenCheck className=''/>
        {text3}
      </Col>
    </Row>
  );
};

export default TripleGreenCheck;