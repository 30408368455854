import { ErrorCard, LoadingCard } from '@odmonorepo/core-components';
import DocumentFilterList from '../Components/DocumentFilterList';
import { useDocuments } from 'merchant-lib';
import { useIntl } from 'react-intl';
import { filterRawDocuments } from '../Transformers/filterRawDocuments';
import { Card } from 'react-bootstrap';

interface FilterListProps {
  filterVisible?: boolean,
  toggleFilterVisibility?: () => void
}

export const StatementsCard = ({filterVisible, toggleFilterVisibility}:FilterListProps) => {
  const intl = useIntl();
  const { documents, isLoading, isError } = useDocuments();
  const validDocumentTypes = ['MONTHLY_STATEMENT'];

  if (isError)
    return (
      <ErrorCard name={intl.formatMessage({ id: 'loading.error.documents' })} />
    );
  if (isLoading) return <LoadingCard />;
  return (
    <Card className="p-5 px-0 px-sm-5">
      <DocumentFilterList
        title="Statements"
        documents={filterRawDocuments(documents, validDocumentTypes)}
        filterVisible={filterVisible}
        toggleFilterVisibility={toggleFilterVisibility}
      />
    </Card>
  );
};

export default StatementsCard;
