import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import { SWRConfig } from 'swr';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <SWRConfig
    value={{
      provider: () => new Map(),
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    }}
  >
    <App />
  </SWRConfig>
);
