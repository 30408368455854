import { useIntl } from 'react-intl';
import PlaidCard from '../../Features/Plaid/PlaidCard';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import { WizardForm } from '@odmonorepo/core-components';

export const DocumentsCenter = () => {
  const intl = useIntl();

  return (
    <OptimizelyFeature feature="apex-odo-authentication">
      {(isEnabled) =>
        isEnabled && (
          <div>
            <h3 className="text-center mb-5">
              {intl.formatMessage({ id: 'documentCenter.title' })}
            </h3>
            <WizardForm>
              <PlaidCard />
            </WizardForm>
          </div>
        )
      }
    </OptimizelyFeature>
  );
};

export default DocumentsCenter;
