import React from 'react';
import { FormattedMessage } from 'react-intl';


const CustomErrorTitle: React.FC = () => {
  return (
    <FormattedMessage id="customErrorMessage.title" defaultMessage="We found an issue with your account" />
  );
};

export default CustomErrorTitle;