import { useEffect, useState, ReactNode } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import ReviewPricingCard from '../../Features/DrawFunds/ReviewPricingCard';
import FundingMethodCard from '../../Features/DrawFunds/FundingMethodCard';
import {
  Button,
  ErrorCard,
  FormattedMessageDiv,
} from '@odmonorepo/core-components';
import { useDrawCreate, useLOC } from 'merchant-lib';
import FundingInfoCard from '../../Features/DrawFunds/FundingInfoCard';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import BlueBlob from '../../../../core-components/src/lib/BlueBlob/BlueBlob';

export const DrawFunds = () => {
  const [fundingMethod, setFundingMethod] = useState<string>('ACH');
  const [resetPricing, setResetPricing] = useState(true);
  const [drawAmount, setDrawAmount] = useState<number>(1000);
  const [errorMsg, setErrorMsg] = useState<ReactNode>('');
  const [drawSpinner, setDrawSpinner] = useState(false);
  const [alert, setAlert] = useState<any>('');

  const navigate = useNavigate();
  const intl = useIntl();
  const { activeLOC, locError } = useLOC();
  const { triggerDraw, drawResult } = useDrawCreate(activeLOC?.id);
  useEffect(() => {
    if (activeLOC?.drawOnHold)
      setAlert(
        <FormattedMessage
          id={'drawFunds.loc.drawOnHold'}
          values={{
            tel: function renderTel(txt: any) {
              return (
                <a href={`tel:${txt}`} className="fw-bold">
                  {txt}
                </a>
              );
            },
          }}
        />
      );
    if (drawAmount > 10000) setFundingMethod('ACH');
    setErrorMsg('');
  }, [fundingMethod, drawAmount, activeLOC]);

  const createDraw = async () => {
    setDrawSpinner(true);
    let createDrawReq = {
      apr: activeLOC?.annualPercentageRate,
      drawAmount: drawAmount,
      fundingMethod: fundingMethod,
      outstandingPrincipal: activeLOC?.outstandingPrincipal,
    };
    const res = await triggerDraw(createDrawReq);
    if (res?.error) setAlert(res?.rootException);
    setDrawSpinner(false);
  };

  if (locError)
    return <ErrorCard name={intl.formatMessage({ id: 'loading.error' })} />;

  return (
    <BlueBlob>
      <FormattedMessageDiv id="drawFunds.title" className="h3 mb-7" />
      <Stack gap={5}>
        <SuccessAlert show={drawResult && !drawResult?.error} />
        {alert && <ErrorAlert message={alert} />}
        <FundingInfoCard />
        <ReviewPricingCard
          errorMsg={errorMsg}
          drawAmount={drawAmount}
          onChange={(val: number) => setDrawAmount(val)}
          resetPricing={(val: boolean) => setResetPricing(val)}
          updateAlert={(msg) => setAlert(msg)}
        />
        <FundingMethodCard
          drawAmount={drawAmount}
          updateFundingMethod={(method: string) => setFundingMethod(method)}
          fundingMethod={fundingMethod}
        />
        <Stack
          gap={3}
          className="flex-col my-5 flex-md-row justify-content-between align-items-center"
        >
          <Button
            variant="outline-primary"
            onClick={() => navigate('/')}
            style={{ width: '320px' }}
          >
            <FormattedMessageDiv id="drawFunds.button.cancel" />
          </Button>
          <Button
            style={{ width: '320px' }}
            className="primary"
            disabled={resetPricing || errorMsg !== '' || drawSpinner}
            loading={drawSpinner}
            onClick={createDraw}
          >
            <FormattedMessageDiv id="drawFunds.button.confirmDraw" />
          </Button>
        </Stack>
        <Stack className="small text-start" gap={4}>
          <FormattedMessageDiv id="drawFunds.instantFunding.withdrawals.info" />
          <FormattedMessageDiv id="drawFunds.ach.withdrawals.info" />
          <FormattedMessageDiv id="drawFunds.withdrawals.tc" />
        </Stack>
      </Stack>
    </BlueBlob>
  );
};

const ErrorAlert = ({ message = '' }: { message?: any }) => (
  <Alert variant="danger text-start">{message}</Alert>
);

const SuccessAlert = ({ show }: { show?: boolean }) =>
  show && (
    <Alert variant="success text-start">
      <FormattedMessage id={'drawFunds.loc.success'} />
    </Alert>
  );

export default DrawFunds;
