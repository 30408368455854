import {
  Button,
  Card,
  ErrorCard,
  FormattedMessageDiv,
  LoadingCard,
} from '@odmonorepo/core-components';
import { Tabs, Tab, Stack, Alert, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useLoan,
  usePersons,
  postOTPReq,
  postOTP,
  useLOC,
} from 'merchant-lib';
import BlueBlob from '../../../../../core-components/src/lib/BlueBlob/BlueBlob';
import OTPTab from '../Components/OTPTab';
import { useEffect, useState } from 'react';
import OTPSuccessCard from './OTPSuccess';
import { useNavigate } from 'react-router-dom';

export function OTPCard() {
  const intl = useIntl();
  const { email, isLoading: loadingEmail } = usePersons();
  const { triggerOTP, OTPdata, posting, postOTPerror } = postOTP();
  const { activeTL, loanLoading, loanError } = useLoan();
  const { activeLOC, locLoading, locError } = useLOC();
  const [valid, setValid] = useState(false);
  const navigate = useNavigate();
  const [OTPReqData, setOTPReqData] = useState<postOTPReq>();

  if (loanError || locError)
    return <ErrorCard name={intl.formatMessage({ id: 'loading.error' })} />;

  if (loadingEmail || loanLoading || locLoading) return <LoadingCard />;

  return (
    <Stack gap={5}>
      <BlueBlob>
        <FormattedMessageDiv id="OTP.both.heading" className="h3" />
      </BlueBlob>
      {!OTPdata || OTPdata?.error ? (
        <>
          <Card bodyClassName="pt-3">
            <Tabs className="d-flex justify-content-center custom-tabs">
              {activeLOC && (
                <Tab
                  className="pt-5"
                  title={intl.formatMessage({ id: 'title.loc' })}
                  eventKey="Line of Credit"
                >
                  <ErrorAlert
                    show={postOTPerror || OTPdata?.error != undefined}
                  />
                  <OTPTab
                    type="LOC"
                    id={activeLOC.id}
                    onChange={(data, valid) => (
                      setOTPReqData({
                        ...data,
                        email: email,
                        paymentDate: Date.now(),
                      }),
                      setValid(valid)
                    )}
                  />
                </Tab>
              )}
              {activeTL && (
                <Tab
                  className="pt-5"
                  title={intl.formatMessage({ id: 'title.termloan' })}
                  eventKey="Term Loan"
                >
                  <ErrorAlert
                    show={postOTPerror || OTPdata?.error != undefined}
                  />
                  <OTPTab
                    type="TL"
                    id={activeTL.id}
                    onChange={(data, valid) => (
                      setOTPReqData({
                        ...data,
                        email: email,
                        paymentDate: Date.now(),
                      }),
                      setValid(valid)
                    )}
                  />
                </Tab>
              )}
            </Tabs>
          </Card>
          <Row
            className="justify-content-center text-center gx-5"
            xs={1}
            md={2}
          >
            <Button
              style={{ width: '220px' }}
              className="px-0 col mb-5 mx-2"
              variant="outline-primary"
              onClick={() => navigate('/')}
            >
              <FormattedMessage id="OTP.both.cancel" />
            </Button>
            <Button
              disabled={!valid}
              style={{ width: '220px' }}
              className="px-0 mb-5 col mx-2"
              loading={posting}
              onClick={() => triggerOTP(OTPReqData)}
            >
              <FormattedMessage id="OTP.both.submit" />
            </Button>
          </Row>
        </>
      ) : (
        <>
          <OTPSuccessCard {...OTPdata} />
          <Button
            className="mx-auto mt-3"
            onClick={() => navigate('/')}
            style={{ width: '220px' }}
          >
            <FormattedMessage id="OTP.both.return" />
          </Button>
        </>
      )}
    </Stack>
  );
}

const ErrorAlert = ({ show = true }: { show?: boolean }) =>
  show && (
    <Alert variant="danger">
      <FormattedMessageDiv
        className="fs-5 fw-bold"
        id="OTP.both.error.header"
      />
      <FormattedMessageDiv className="" id="OTP.both.error.text" />
    </Alert>
  );

export default OTPCard;
