import { Stack } from 'react-bootstrap';
import ActivityCard from '../../Features/Activity/ActivityCard';
import ProductCard from '../../Features/Product/Cards/ProductCard';
import DocumentsCard from '../../Features/Documents/Cards/DocumentsCard';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import OfferNotification from '../../Features/OfferConfiguration/Components/OfferNotification/OfferNotification';
import RenewalNotification from '../../Features/LoanApp/Cards/RenewalNotification';

export function Dashboard() {
  return (
    <Stack gap={5}>
      <OfferNotification/>
      <OptimizelyFeature feature="apex-odo-authentication">
        {(isEnabled) => isEnabled && <RenewalNotification />}
      </OptimizelyFeature>
      <ProductCard />
      <ActivityCard />
      <DocumentsCard />
    </Stack>
  );
}

export default Dashboard;
