import {
  Button,
  Card,
  ErrorCard,
  LoadingCard,
} from '@odmonorepo/core-components';
import { useState } from 'react';
import { Stack } from 'react-bootstrap';
import List from '../../../../../core-components/src/lib/List/List';
import { usePersons } from 'merchant-lib';
import { useIntl } from 'react-intl';
import { rawProfileToList } from '../Transformers/rawProfileToList';
import PersonalForm from '../Components/OwnerForm';

export const PersonalCard = ({ showEdit = false }: { showEdit?: boolean }) => {
  const [editing, setEditing] = useState(false);
  const { person, isError, isLoading } = usePersons();
  const intl = useIntl();

  if (isError)
    return (
      <ErrorCard name={intl.formatMessage({ id: 'loading.error.profile' })} />
    );
  if (isLoading) return <LoadingCard />;

  const profileList = rawProfileToList(person).listObj;

  const handleSubmit = () => {};

  return (
    <Card>
      <Stack direction="horizontal">
        <h4>Personal Details</h4>
        {showEdit && (
          <Button
            variant="link"
            className="ms-auto p-0"
            onClick={() => setEditing((e) => !e)}
          >
            {editing ? 'Cancel' : 'Edit'}
          </Button>
        )}
      </Stack>
      {editing ? (
        <PersonalForm onSubmit={() => handleSubmit()} />
      ) : (
        <List obj={profileList} />
      )}
    </Card>
  );
};

export default PersonalCard;
