import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";


export interface PricingResponse {
  lineAmount: number;
  trueCentsOnDollar: number;
  trueAPR: number;
  paymentFrequency: string;
  numberOfPayments: number;
  payment: number;
  disbursementAmount: number;
  totalAmountPaidBack: number;
  lender: string;
  modelId: string;
  loanOriginationFee: number;
  averageMonthlyPayment: number;
  term: number;
  totalLoanCost: number;
  monthlyFee: number;
}

export interface GetPricingProps {
  response: any;
  getPricingSending: boolean;
  getPricingError: string;
  getPricing: any;
}

export function getPricingDetails(): GetPricingProps {
  const apiUrl = process.env['NX_PUBLIC_OFFER_ORCHESTRATOR_URL']
  const {fetcher} = useSWRConfig();
  
  async function sendRequest(url, {arg}) {
    return fetcher && fetcher(url, {method: 'POST', body: JSON.stringify({...arg})});
  };

  const {data, error, trigger, isMutating} = useSWRMutation(`${apiUrl}/v1/pricing`, sendRequest);

  return {
    response: data,
    getPricingSending: isMutating,
    getPricingError: error,
    getPricing: trigger
  }
}

export default getPricingDetails;