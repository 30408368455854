import { Stack } from 'react-bootstrap';
import { BusinessContactSchema, BusinessContact } from './Cards/BusinessContact';
import { BusinessDetailsSchema, BusinessDetails } from './Cards/BusinessDetails';
import { BlueBlob, ErrorPage, FormattedMessageDiv, LoadingAnimation, WizardForm, WizardNav, combineSchemas} from '@odmonorepo/core-components';
import { OwnerContactSchema, OwnerContact } from './Cards/OwnerContact';
import { OwnerDetailsSchema, OwnerDetails } from './Cards/OwnerDetails';
import AppReviewCard from './Cards/AppReview';
import DeclinedCard from './Cards/DeclinedCard';
import ReceivedCard from './Cards/ReceivedCard';
import BankInfoCard from './Cards/BankInfoCard';
import { loanDefaultValues, loanStep } from './helpers';

const schema = [
  BusinessContactSchema,
  BusinessDetailsSchema,
  OwnerContactSchema,
  OwnerDetailsSchema,
];

export enum LoanStep {
  BUSINESS_STEP,
  OWNER_STEP,
  APP_REVIEW,
  BANK_INFO,
  RECEIVED,
  DECLINED,
}

export const LoanAppForm = () => {
  const { defaultValues, loading } = loanDefaultValues();
  const { step, stepError, stepLoading } = loanStep();

  if (stepError) <ErrorPage/>;
  if (stepLoading || loading)
    return (
      <Stack>
        <LoadingAnimation className="mx-auto mt-7" />
      </Stack>
    );
    return (
      <WizardForm startIndex={step} schema={combineSchemas(schema)} defaultValues={defaultValues}>
        <BusinessStep/>
        <OwnerStep/>
        <AppReviewCard/>
        <BankInfoCard/>
        <ReceivedCard/>
        <DeclinedCard/>
      </WizardForm>
    );
  };

const BusinessStep = () => (
  <Stack gap={5}>
    <BlueBlob>
      <FormattedMessageDiv className="h3" id="renewalApp.step.1"/>
    </BlueBlob>
    <BusinessContact/>
    <BusinessDetails/>
    <WizardNav schema={combineSchemas([BusinessContactSchema, BusinessDetailsSchema])}/>
  </Stack>
);

const OwnerStep = () => (
  <Stack gap={5}>
    <BlueBlob>
      <FormattedMessageDiv className="h3" id="renewalApp.step.2"/>
    </BlueBlob>
    <OwnerContact/>
    <OwnerDetails/>
    <WizardNav schema={combineSchemas([OwnerContactSchema, OwnerDetailsSchema])}/>
  </Stack>
);

export default LoanAppForm;
