import { useState, useEffect } from 'react';

type ImageProps = JSX.IntrinsicElements['div'] & {
  direction?: 'up' | 'down' | 'left' | 'right';
  width?: number;
}

export const Caret = ({direction, width=9, ...props}: ImageProps) => {
  const [deg, setDeg] = useState(0);

  useEffect(() => {
    switch (direction) {
      case 'up': setDeg(270); break;
      case 'down': setDeg(90); break;
      case 'left': setDeg(180); break;
      case 'right': setDeg(0); break;
      default: setDeg(0);
    }
  }, [direction]);

  return <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width*2} viewBox="0 0 8 14" style={{transform: `rotate(${deg}deg)`}}>
      <path id="Fill_187" data-name="Fill 187" d="M7.632,7.872,1.846,13.684a1.115,1.115,0,0,1-1.511.042A.969.969,0,0,1,.291,12.3L5.569,7,.291,1.7A.968.968,0,0,1,.335.275,1.114,1.114,0,0,1,1.846.316L7.63,6.122a1.222,1.222,0,0,1,0,1.75Z" fill="currentColor"/>
    </svg>
  </div>
}
export default Caret;
