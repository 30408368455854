import { Card, Stack, Container, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { getTransactionCSV, useTransactions } from 'merchant-lib';
import { rawActivityToLineItem } from './rawActivityToLineItem';
import {
  Button,
  DownloadIcon,
  ErrorCard,
  ExpandableList,
  FormattedMessageDiv,
  LoadingCard,
} from '@odmonorepo/core-components';
import ActivityLineItem from './ActivityLineItem';

export function ActivityCard() {
  const intl = useIntl();
  const { transactions, isLoading, isError } = useTransactions();
  const { openCSV, isLoading: opening } = getTransactionCSV();
  if (isError)
    return (
      <ErrorCard name={intl.formatMessage({ id: 'loading.error.activity' })} />
    );
  if (isLoading) return <LoadingCard />;

  return (
    transactions?.length > 0 && (
      <Card className="p-5 px-0 px-sm-5">
        <Stack>
          <Container className="ps-5 ps-sm-0 mx-sm-0" fluid>
            <Row>
              <Col xs={12} md={6}>
                <h4 className="fs-6 p-0 m-0">
                  {intl.formatMessage({ id: 'title.activity' })}
                </h4>
              </Col>
              <Col xs={12} md={6} className="text-start text-md-end">
                <Button
                  variant="link"
                  onClick={openCSV}
                  className="fs-8 ms-auto p-0 text-start text-md-end"
                  loading={opening}
                  style={{ textDecoration: 'none' }}
                >
                  <Stack direction='horizontal' gap={2}>
                    <FormattedMessageDiv
                      id="btn.downloadCSV"
                      className="d-inline-block"
                    />
                    <DownloadIcon/>
                  </Stack>
                </Button>
              </Col>
            </Row>
          </Container>
          <div className="mx-5 mx-sm-0">
            <ExpandableList
              btnLabel={intl.formatMessage({ id: 'btn.loadMore' })}
              defaultShow={3}
              show={5}
            >
              {transactions?.map((transaction, idx) => (
                <ActivityLineItem
                  key={idx}
                  {...rawActivityToLineItem(transaction)}
                  className="px-0"
                />
              ))}
            </ExpandableList>
          </div>
        </Stack>
      </Card>
    )
  );
}

export default ActivityCard;
