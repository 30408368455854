import { default as Analytics, AnalyticsInstance } from 'analytics';
import fullStoryPlugin from '@analytics/fullstory';
import mixpanelPlugin from '@analytics/mixpanel';
import datadogRumPlugin from 'libs/odfunctions/src/lib/datadogPlugin';
import adobeAnalyticsPlugin from 'libs/odfunctions/src/lib/adobeAnalytics';

const IS_DEV = process.env.NODE_ENV === 'development';
const serviceName = 'ondeck_merchant_ux';

export const analytics: AnalyticsInstance = Analytics({
  app: serviceName,
  debug: IS_DEV,
  plugins: [
    fullStoryPlugin({
      org: process.env.NX_PUBLIC_FULLSTORY_TOKEN,
    }),
    mixpanelPlugin({
      token: process.env.NX_PUBLIC_MIXPANEL_TOKEN,
    }),
    datadogRumPlugin({
      applicationId: process.env.NX_PUBLIC_DATADOG_APP_ID,
      clientToken: process.env.NX_PUBLIC_DATADOG_CLIENT_TOKEN,
      serviceName: serviceName,
    }),
    adobeAnalyticsPlugin(),
  ],
});

export default analytics;
