import React, { ReactNode, useState } from 'react';
import { Card } from '@odmonorepo/core-components';
import { combineClassNames } from '@odmonorepo/odfunctions';
import { Tab, Tabs } from 'react-bootstrap';


type TabsInternalProps = JSX.IntrinsicElements['div'] & {
	tabs: string[];
	children: ReactNode[];
}



export const TabsInternal = ({ tabs, children, ...props }: TabsInternalProps) => {
	const [active, setActive] = useState(0);
	return (
		<Card bodyClassName='pt-4' {...props}>
			<div className='d-flex justify-content-center'>
				{tabs.map((tab, idx) => (
					<div
						key={idx}
						style={{ width: '140px' }}
						className={combineClassNames(active === idx ? 'boder border-bottom border-4 border-accent fw-bold' : '', 'text-center pb-2')}
						onClick={() => {
							setActive(idx);
						}}
					>
						{tab}
					</div>
				))}
			</div>
			<div className='' style={{ height: '0', borderTop: '2px solid #ddd' }} />
			<Tabs>

			{children.map((child, idx) => (
				<Tab key={idx} className={active === idx ? 'd-block' : 'd-none'} title={"title"}>
					{child}
				</Tab>
			))}
							</Tabs>

		</Card>
	);
};

export default TabsInternal;
