import { Caret, ErrorCard, LoadingCard } from '@odmonorepo/core-components';
import { getBusiness } from 'merchant-lib';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { rawBusinessToList } from '../../Features/Profile/Transformers/rawBusinessToList';
import BusinessCard from '../../Features/Profile/Cards/BusinessCard';

export const BusinessProfile = () => {
  const { businessDetails, isBusinessError, isBusinessLoading } = getBusiness();
  const businessList =
    businessDetails && rawBusinessToList(businessDetails)?.listObj;
  const navigate = useNavigate();
  const intl = useIntl();

  if (isBusinessError)
    return <ErrorCard name={intl.formatMessage({ id: 'title.business' })} />;
  if (isBusinessLoading) return <LoadingCard />;

  return (
    <div>
      <h2 className="d-flex fw-normal text-dark">
        <Caret
          role="button"
          width={13}
          direction="left"
          onClick={() => navigate(-1)}
        />
        <div style={{ marginTop: '2px' }} className="ms-3">
          {businessDetails.legalName}
        </div>
      </h2>
      <h5 className="ms-6">
        {businessList && businessList['Business Address']}
      </h5>
      <BusinessCard />
    </div>
  );
};

export default BusinessProfile;
