import { LoanActivity } from 'merchant-lib';
import { ActivityLineItemProps } from './ActivityLineItem';


export const rawActivityToLineItem = (rawActivity: LoanActivity) => {
  return {
    date:
      rawActivity.status === 'P'
        ? rawActivity.initiatedDate
        : rawActivity.clearedDate,
    type: rawActivity.type,
    status: rawActivity.status,
    product:
      rawActivity.loanType === 'FIXED_TERM' ? 'Term Loan' : 'Line of Credit',
    amount: rawActivity.amount,
    principal: rawActivity.principal,
    interest: rawActivity.interest,
  } as ActivityLineItemProps;
};
