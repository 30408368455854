import React, { useState, useEffect, useContext, useCallback } from 'react';
import auth0 from 'auth0-js';
import createAuth0Spa, { Auth0Client, User } from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

interface Auth0ContextProps {
  loading: boolean;
  isAuthenticated: boolean;
  user: User | undefined;
  login: (email: string, password: string) => void;
  logout: (path: string) => void;
  changePassword: (email: string) => void;
  changePasswordError: any;
  resetPassword: (
    email: string,
    currentPassword: string,
    newPassword: string
  ) => void;
  resetPasswordError: any;
  resetPasswordSuccess: boolean;
  getTokenSilently: (...p: any) => any;
  callbackResponse: any;
}

export const Auth0Context = React.createContext<Auth0ContextProps>(
  {} as Auth0ContextProps
);
export const useAuth0 = () => useContext<Auth0ContextProps>(Auth0Context);
export const Auth0Provider = ({
  children,
  domain,
  audience,
  clientID,
  connection,
  baseURL,
  responseType,
  scope,
}) => {
  const [auth0SPA, setAuth0SPA] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();
  const [callbackResponse, setCallbackResponse] = useState({});
  const [changePasswordError, setChangePasswordError] = useState(null);
  const [resetPasswordError, setResetPasswordError] = useState<any>(null);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const displayError = useCallback(
    (err) => {
      sessionStorage.removeItem('ignoreResponseCode');
      setCallbackResponse(
        Object.assign({}, callbackResponse, {
          code: err.code,
          description: err.description,
        })
      );
    },
    [callbackResponse]
  );

  useEffect(() => {
    (async () => {
      setTimeout(() => {
        if (auth0SPA === undefined) {
          throw new Error('Auth0 server response error');
        }
      }, 8000);
      const auth0SPA = await createAuth0Spa({
        domain,
        client_id: clientID,
        audience,
      });
      setAuth0SPA(auth0SPA);

      if (sessionStorage.getItem('ignoreResponseCode')) {
        sessionStorage.removeItem('ignoreResponseCode');
        return auth0SPA.loginWithRedirect({
          redirect_uri: baseURL,
        });
      } else if (window.location.search.includes('code=')) {
        await auth0SPA.handleRedirectCallback();
        DEFAULT_REDIRECT_CALLBACK();
      }

      const isAuthenticated = await auth0SPA.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await auth0SPA.getUser();
        setUser(user);
      } else setUser({ auth: 'no_user' });
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (email, password) => {
    setCallbackResponse({});
    try {
      const auth0JS = await new auth0.WebAuth({
        domain,
        clientID,
        audience,
      });
      await auth0JS.login(
        {
          realm: connection,
          redirectUri: baseURL,
          responseType,
          scope,
          username: email,
          password,
        },
        async (err) => {
          if (err.code === 'access_denied') {
            const sentryResponse = await fetch(
              `${process.env.NX_PUBLIC_SENTRY_URL}/v1/users/authenticate`,
              {
                method: 'POST',
                body: JSON.stringify({
                  email,
                  password,
                }),
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              }
            );
            if (sentryResponse.ok) {
              await auth0JS.login({
                realm: connection,
                redirectUri: baseURL,
                responseType,
                scope,
                username: email,
                password,
              });
              return;
            } else {
              sentryResponse.json().then((res) => {
                if (res.code === 'MZXOF')
                  window.location.href = `/reset-password?email=${email}`;
              });
            }
          }
          displayError(err);
        }
      );
      sessionStorage.setItem('ignoreResponseCode', 'true');
    } catch (err) {
      sessionStorage.removeItem('ignoreResponseCode');
    }
  };

  const logout = (path = '/') => {
    auth0SPA?.logout({
      client_id: clientID,
      returnTo: `${baseURL}${path}`,
    });
  };

  const changePassword = async (email) => {
    setChangePasswordError(null);
    const auth0JS = await new auth0.WebAuth({
      domain,
      clientID: clientID,
      audience: audience,
    });

    await auth0JS.changePassword(
      {
        connection: connection,
        email: email,
      },
      (err) => {
        setChangePasswordError(err);
      }
    );
  };

  const resetPassword = async (email, currentPassword, newPassword) => {
    setResetPasswordError(null);
    try {
      const sentryResponse = await fetch(
        `${process.env.REACT_APP_SENTRY_API_URL}/users/reset_password`,
        {
          method: 'POST',
          body: JSON.stringify({
            currentPassword,
            email,
            newPassword,
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      if (sentryResponse.ok) setResetPasswordSuccess(true);
      else {
        sentryResponse.json().then((res) => {
          setResetPasswordError(res);
        });
      }
    } catch (e) {
      setResetPasswordError(e);
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        loading,
        isAuthenticated,
        login,
        logout,
        changePassword,
        changePasswordError,
        resetPassword,
        resetPasswordError,
        resetPasswordSuccess,
        getTokenSilently: (...p) => auth0SPA?.getTokenSilently(...p),
        user,
        callbackResponse,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
