import { FormattedMessageDiv } from '@odmonorepo/core-components';

export const PlainDisclaimer = () => 
  <FormattedMessageDiv
    className="fs-8"
    id="plaid.disclamier"
    values={{
      sup: (txt: any) => <sup>{txt}</sup>,
      link: (txt: any) => <a href={`https://my.plaid.com`}>{txt}</a>,
      privacy: (txt: any) => <a href={`https://plaid.com/legal/`}>{txt}</a>,
    }}
  />

export default PlainDisclaimer;
