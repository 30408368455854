import Sofi from './Sofi.png';

type ImageProps = JSX.IntrinsicElements['div'] & {
  partner: 'Sofi' | string;
  width?: string;
  alt?: string;
}

const logos = {
  Sofi: Sofi,
};


export const PartnerLogo = ({ width = "100px", alt = "OnDeck", partner, ...props }: ImageProps) => {
  const logoSrc = logos[partner] || undefined; 
  return <div {...props}>
    {logoSrc ? <img src={logoSrc} width={width} alt={partner} /> : <div className="fs-3 lh-1 fw-bold">{partner}</div>}
  </div>
}

export default PartnerLogo;
