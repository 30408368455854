import Check from './Check.svg';

type ImageProps = JSX.IntrinsicElements['div'] & {
  width?: string;
  alt?: string;
}

export const GreenCheck = ({width = "23.955px", alt = "Green Check", ...props}: ImageProps) => <div {...props}><img src={Check} width={width} alt={alt} /></div>
export default GreenCheck;

