import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation'
import { useEffect, useState } from 'react';

export interface OTPdataProps {
  "loanId": string;
  "paymentAmount":  number;
  "paymentDate": string;
  "paymentId": number;
  "success": boolean;
}
export interface postOTPProps {
  OTPdata: any;
  triggerOTP: any;
  postOTPerror: any;
  posting: boolean
}
export interface postOTPReq {
  email: string;
  paymentDate: string | Date;
  loanId: string;
  paymentAmount: number;
}
 
export function postOTP(): postOTPProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL'];
  const { fetcher } = useSWRConfig();
  const [loading, setLoading] = useState(false);

  async function sendRequest(url, { arg }: { arg: postOTPReq}) {
    setLoading(true)
    return fetcher && fetcher(url, {method: 'POST', body: JSON.stringify(arg)});
  };

  const { data, error, trigger } = useSWRMutation(`${apiUrl}/v1/otp`, sendRequest);
  
  useEffect(() => {
    (data || error) && setLoading(false)
  }, [data, error]);

  return {
    OTPdata: data,
    triggerOTP: trigger,
    postOTPerror: Boolean(error),
    posting: loading
  };
}

export default postOTP;
