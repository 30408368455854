import ErrorPageDefault from './ErrorPageDefault';
import { IntlProvider } from 'react-intl';
import { merchantPortalText } from '../../en-US.js'; 
import { CustomError } from 'merchant-lib';
import { AppError } from 'merchant-lib';

interface ErrorPageProps {
  error?: Error;
}

const ErrorContent = ({ error }: ErrorPageProps) => {
  if (error === AppError.NoOffer) return <CustomError/>
  return <ErrorPageDefault />;
};

export const ErrorPage = ({ error }: ErrorPageProps) => {
  return (
    <IntlProvider locale="en-US" messages={merchantPortalText}>
      <ErrorContent error={error}/>
    </IntlProvider>
  );
};
export default ErrorPage;