import { Card } from '@odmonorepo/core-components';
import List from './List';

export interface ListCardProps {
  title: string;
  listObj: object;
}

export function ListCard({ title, listObj }: ListCardProps) {
  return (
    <Card>
      <div className="fs-6 fw-bold">{title}</div>
      <List obj={listObj} />
    </Card>
  );
}

export default ListCard;
