import { Button, Card, FormattedMessageDiv } from '@odmonorepo/core-components';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

export function DeclinedCard() {
  const navigate = useNavigate();

  return (
    <Card className="p-4">
      <div className="d-flex flex-column align-items-center mb-5">
        <FormattedMessageDiv
          className="h2 text-center"
          id="application.declined.title"
        />
      </div>
      <div>
        <FormattedMessage id="application.declined.msg" tagName="p" />
        <FormattedMessage
          id="loan.advisor.questions"
          values={{
            tel: (txt: any) => {
              return (
                <a href={`tel:${txt}`} className="fw-bold">
                  {txt}
                </a>
              );
            },
          }}
        />
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Button onClick={() => navigate('/')}>
          <FormattedMessage id="btn.returnToDashboard" />
        </Button>
      </div>
    </Card>
  );
}

export default DeclinedCard;
