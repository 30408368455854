import useSWR from 'swr';
import { useUser } from '../..';

export interface MapiOwnerProps {
  firstName: string;
  lastName: string;
  homeNumber: string;
  legalIdentifier: string;
  maskedLegalIdentifier: string;
  isFullLegalId: boolean;
  dateOfBirth: string;
  percentageOwned: number;
  address1: string;
  city: string;
  stateProv: string;
  postalCode: string;
  countryIso3: string;
  id: number;
  legalIdentifierMasked: boolean;
  missingRequiredFullLegalIdentifier: boolean;
  beneficialOwner: boolean;
  managingPerson: boolean;
}

export interface useMapiOwnerProps {
  isLoading: boolean;
  owner: MapiOwnerProps[];
  fullName: string;
  isError: any;
}

export function useMapiOwner(): useMapiOwnerProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']

  const { status, isLoading: userLoading} = useUser();
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/merchant-api/owner/user/3531614`)
 
  return {
    owner: data,
    fullName: data?.fullName,
    isLoading: isLoading || userLoading,
    isError: error
  }
}

export default useMapiOwner;
