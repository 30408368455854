import { IntlProvider } from 'react-intl';
import { merchantPortalText } from '../../en-US.js';
import Login from './auth0Login';

export const LoginPage = () => (
  <IntlProvider locale="en-us" messages={merchantPortalText}>
    <Login />
  </IntlProvider>
);

export default LoginPage;
