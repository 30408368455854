import {
  ErrorPage,
  FormattedNumberDiv,
  LoadingAnimation,
  WizardForm,
} from '@odmonorepo/core-components';
import { FullConfig } from '../../Features/OfferConfiguration/Components/FullConfig/FullConfig';
import SimpleConfig from '../../Features/OfferConfiguration/Components/SimpleConfig/SimpleConfig';
import PPDOptions from '../../Features/OfferConfiguration/Components/PPD/PPDOptions';
import { Card, Stack } from 'react-bootstrap';
import { ConfigStepCard } from '../../Features/OfferConfiguration/Cards/ConfigStepCard';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import {
  CheckoutStatus,
  getCheckoutState,
  useDetailedOffers,
} from 'merchant-lib';
import { maxLoanAmount } from '../../Features/OfferConfiguration/minMaxLoanAmounts';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export enum OfferConfigStep {
  SIMPLE_CONFIG,
  FULL_CONFIG,
  PPD_OPTION,
}

const schema = yup.object().shape({
  showOfferPreview: yup.boolean(),
  offer: yup.object().shape({
    offerId: yup.string(),
    loanAmount: yup.number(),
    ppdDiscount: yup.string(),
  }),
});

export const OfferConfig = () => {
  const { tlOffers, selectedOffer, isLoadingDetailedOffers, isErrorOffers } =
    useDetailedOffers();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      selectedOffer != null
    ) {
      navigate('/checkout', { replace: true });
    }
  }, [ selectedOffer]);


  if (isLoadingDetailedOffers)
    return (
      <Stack>
        <LoadingAnimation className="mx-auto mt-7" />
      </Stack>
    );
  if (isErrorOffers)
    return (
      <Stack>
        <ErrorPage />
      </Stack>
    );

  const defaultValues = {
    showOfferPreview: true,
    offer: {
      offerId: selectedOffer?.offerId || tlOffers[0].offerId,
      loanAmount: selectedOffer?.pricedDetails.lineAmount || tlOffers[0].pricedDetails.lineAmount,
      ppdDiscount: '100',
    },
  };

  return (
    <Stack gap={5}>
      <h4 className="text-secondary">
        <FormattedNumberDiv
          value={maxLoanAmount(tlOffers)}
          style={'currency'}
          currency="USD"
          minimumFractionDigits={0}
          prefix={<FormattedMessage id="offerConfig.title.prefix" />}
          suffix="."
        />
      </h4>
      <Card>
        <WizardForm schema={schema} defaultValues={defaultValues}>
          <ConfigStepCard>
            <SimpleConfig />
          </ConfigStepCard>
          <ConfigStepCard>
            <FullConfig />
          </ConfigStepCard>
          <ConfigStepCard>
            <PPDOptions />
          </ConfigStepCard>
        </WizardForm>
      </Card>
    </Stack>
  );
};

export default OfferConfig;
