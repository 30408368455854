import { useEffect, useState } from "react";

export interface UseUserProps {
  status: number | undefined;
  isLoading: boolean;
  logout: () => void;
  data: any
}

export function useUser(): UseUserProps {
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL'];

  const logout = () => {
    fetch(`${apiUrl}/v1/users/logout`, {
      method: 'POST',
      credentials: 'include'
    }).then(
      () => window.location.href = process.env['NX_PUBLIC_ODO_URL'] + '/login?reset_session=true' 
    );
  }

  useEffect(() => {
    setLoading(true);
    fetch(`${apiUrl}/v1/users/profile`, {credentials: 'include'}).then(res => {
      setStatus(res?.status);
      res?.json().then(data => setData(data));
      setLoading(false)
    });
  }, []);
  
  return {
    status: status,
    isLoading: loading,
    logout: logout,
    data: data
  };
}

export default useUser;
