import { useEffect } from 'react';
import { Card } from '@odmonorepo/core-components';
import { Stack } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import './ingo.instant.payments.sdk-2.1.0.min.js';
import { useNavigate } from 'react-router-dom';

declare var IngoInstantPayments;

export const IFrameCard = ({
  setActiveTab,
  setRegistrationError,
  authorizedUrl,
}) => {
  const navigate = useNavigate();

  const handleFailure = () => {
    setRegistrationError(true);
    setActiveTab('landing');
  };

  const handleSuccess = () => {
    setActiveTab('success');
  };

  const handleCancel = () => {
    navigate('/');
  };

  const addEventListeners = (webPlugin) => {
    const events = IngoInstantPayments.EVENTS;

    webPlugin.addEventListener(
      events.MAX_VERIFICATION_ATTEMPTS_EXCEEDED,
      handleFailure
    );
    webPlugin.addEventListener(events.PLUGIN_ERROR, handleFailure);
    webPlugin.addEventListener(events.SESSION_ERROR, handleFailure);
    webPlugin.addEventListener(
      events.TERMINAL_FAILURE_ACKNOWLEDGED,
      handleFailure
    );
    webPlugin.addEventListener(
      events.UNSUPPORTED_BROWSER_DETECTED,
      handleFailure
    );
    webPlugin.addEventListener(events.TOKEN_SUCCESS, handleSuccess);
    webPlugin.addEventListener(events.FUNDING_CANCELED, handleCancel);
  };

  useEffect(() => {
    if (!authorizedUrl) {
      handleFailure();
      return;
    }

    const webPlugin = IngoInstantPayments.create(
      document.getElementById('container'),
      {
        cssName: 'ingo-plugin',
        autoHeight: true,
        scrolling: true,
      }
    );

    addEventListeners(webPlugin);
    webPlugin.mount(
      authorizedUrl,
      IngoInstantPayments.FUNDING_DESTINATIONS.DEBIT
    );

    return () => {};
  }, [authorizedUrl]);

  return (
    authorizedUrl && (
      <Card>
        <style>{`.ingo-plugin { width: 100% }`}</style>
        <div>
          <FormattedMessage
            id={'loc.iFrame.message'}
            values={{ b: (txt: any) => <b>{txt}</b> }}
          />
          <ul className="mt-3">
            <li>
              <FormattedMessage id={'loc.iFrame.list1'} />
            </li>
            <li>
              <FormattedMessage id={'loc.iFrame.list2'} />
            </li>
          </ul>
        </div>
        <div className="border-top pt-5 border-light-medium-gray" />
        <div className="mx-auto">
          <Stack id="container"></Stack>
        </div>
      </Card>
    )
  );
};

export default IFrameCard;
