import { Button, Card, FormFileUpload, WizardForm, useWizardFormContext } from "@odmonorepo/core-components";
import { uploadDocuments } from "merchant-lib";
import { useEffect } from "react";
import { Stack } from "react-bootstrap";
import { useWizard } from "react-use-wizard";
import * as yup from 'yup';

const fileTest = yup
  .mixed<FileList>()
  .test('required', 'File is required', file => file && file?.length > 0)
  .test('size', 'File must be smaller than 5MB', file => file && file[0]?.size < 5 * 1024 * 1024)
  .test('type', 'File must a PDF', file => file && file[0]?.type === 'application/pdf')
  
const schema = yup.object().shape({
  file1: fileTest,
  file2: fileTest,
  file3: fileTest,
});

const previousMonth = (prev = 1): string => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - prev);
  const month = currentDate.toLocaleString('default', { month: 'long' });
  const year = currentDate.getFullYear();
  return `${month} ${year} Statement`;
};

export const StatementUploader = () => {
  const {upload, documentsSending, response} = uploadDocuments();
  const { nextStep } = useWizard();

  const handleSubmit = async (values: typeof schema.__outputType) => {
    const formData = new FormData();
    Object.values(values).forEach(fileList => formData.append('files', fileList.item(0)!));
    upload(formData);
  }

  useEffect(() => {
    response && nextStep();
  }, [response]);

  return <Card className="text-center">
    <Stack gap={1}>
      <h4>Option 2: Upload Files</h4>
      <p>If you can’t connect with Plaid, you can upload PDF files of your last three monthly bank statements. </p>
      <WizardForm schema={schema} reValidateMode='onChange' onSubmit={v =>handleSubmit(v as typeof schema.__outputType)}>
        <Stack className="text-start" gap={1}>
          <FormFileUpload name="file1" label={previousMonth(1)} accept="application/pdf" useYupErrors/>
          <FormFileUpload name="file2" label={previousMonth(2)} accept="application/pdf" useYupErrors/>
          <FormFileUpload name="file3" label={previousMonth(3)} accept="application/pdf" useYupErrors/>
          <SubmitButton loading={documentsSending}/>
        </Stack>
      </WizardForm>
      <Stack direction="horizontal" gap={1} className="justify-content-center">
        <div>Need help?</div>
        <a href="https://www.ondeck.com/how-to-download-your-bank-statements" target="_blank">How to download bank statements.</a>
      </Stack>
    </Stack>
  </Card>;
};

const SubmitButton = ({loading = false}) => {
  const { formName } = useWizardFormContext();
  return <div className="mx-auto mt-3 mb-5 ">
    <Button type="submit" className="px-8" loading={loading} form={formName}>
      Submit
    </Button>
  </div>
}

export default StatementUploader;
