import { ReactNode, useState } from 'react';
import { Stack, Button, ListGroup, ListGroupItem } from 'react-bootstrap';

/* eslint-disable-next-line */
export interface ExpandableListProps {
  show?: number;
  defaultShow?: number;
  children: ReactNode[];
  btnLabel?: string;
}

export function ExpandableList({
  show,
  defaultShow,
  children,
  btnLabel,
}: ExpandableListProps) {
  const [showing, setShowing] = useState(defaultShow ?? show);
  return (
    <Stack>
      <ListGroup className="fs-7" variant="flush">
        <ListGroupItem variant="light" className="bg-transparent" />
        {children?.slice(0, showing).map((c, idx) => (
          <ListGroupItem
            variant="light"
            key={idx}
            className="py-4 bg-transparent"
          >
            {c}
          </ListGroupItem>
        ))}
        <ListGroupItem variant="light" className="bg-transparent" />
      </ListGroup>
      {showing && showing < children?.length && (
        <Button
          variant="outline-primary"
          className="mx-auto"
          onClick={() => setShowing((s) => s && show && s + show)}
        >
          {btnLabel}
        </Button>
      )}
    </Stack>
  );
}

export default ExpandableList;
