import useSWR from 'swr';
import useParties from '../useParties/useParties';

export interface UseLocProps {
  activeLOC: any;
  locError: any;
  locLoading: boolean;
  ifEnabled: boolean;
  cardRegistered: boolean;
}

export function useLOC(): UseLocProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']

  const { hasLOC, isLoadingParties, isErrorParties, ifOptEnabled } = useParties();
  const { data, error, isLoading} = useSWR(hasLOC ? apiUrl + "/v1/locs/" + hasLOC : null);

  const activeLOC = data?.status === 'ACTIVE' ? data : null;
  const ifsDetails = activeLOC?.instantFunding;

  const ifEnabled =
    process.env['NX_PUBLIC_INSTANT_FUNDING_DRAW_DISABLED'] === 'false' &&
    ((!ifOptEnabled &&
      ifsDetails?.eligible && ifsDetails?.cardInfo &&
      Object.entries(ifsDetails?.cardInfo).length > 0) ||
      (ifOptEnabled && ifsDetails?.eligible)) &&
    !activeLOC?.reamortizationStatus;

  return <UseLocProps> {
    ifEnabled: ifEnabled,
    cardRegistered: ifsDetails?.cardInfo?.registered,
    activeLOC: activeLOC,
    locError: error || isErrorParties,
    locLoading: isLoading || isLoadingParties,
  };
}

export default useLOC;
