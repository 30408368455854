import React from 'react';
import { Badge, Col, Row, Stack } from 'react-bootstrap';
import {
  Button,
  FormattedMessageDiv,
  FormattedNumberDiv,
} from '@odmonorepo/core-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

enum ELoanStatus {
  ARRANGEMENT = 'Arrangement',
  NORMAL = 'Normal',
  PAST_DUE = 'Past Due',
}

const loanStatusColor = new Map<string, string>([
  [ELoanStatus.ARRANGEMENT, 'warning'],
  [ELoanStatus.PAST_DUE, 'danger'],
]);

export interface UpcomingPaymentsProps {
  frequency: string;
  loanStatus: string;
  onHold: boolean;
  payment?: {
    amount: number;
    scheduledDate: string;
  };
}

export const UpcomingPayments = ({
  frequency,
  loanStatus,
  onHold,
  payment,
  ...props
}: UpcomingPaymentsProps & React.HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();
  let color = 'accent';
  const date = payment?.scheduledDate && new Date(payment.scheduledDate);

  if (loanStatus && loanStatus !== 'NORMAL') {
    color = loanStatusColor.get(ELoanStatus[loanStatus]) || color;
  }
  return (
    <div className={props?.className}>
      <div className="d-flex justify-content-between">
        <FormattedMessageDiv
          className="fs-6 fw-bold"
          id="upcomingPayments.heading"
        />
        {loanStatus !== 'NORMAL' && (
          <Badge className="rounded-pill small px-3 lh-base" bg={color}>
            {ELoanStatus[loanStatus]}
          </Badge>
        )}
      </div>
      <Row className="gy-5 mt-0">
        <Col xs={12} sm={6} className="d-flex">
          <div
            style={{ width: '4px' }}
            className={`bg-${color} align-self-stretch rounded`}
          />
          <div className="ms-3">
            <FormattedNumberDiv
              value={payment?.amount ? payment.amount : 0}
              style="currency"
              currency="USD"
            />
            {onHold ? (
              <FormattedMessageDiv id="upcomingPayments.info.onHold" />
            ) : (
              date && (
                <FormattedDate
                  value={date}
                  timeZone="UTC"
                  year="numeric"
                  month="long"
                  day="2-digit"
                  weekday="short"
                />
              )
            )}
            <p className="mt-3 mb-0 fs-7">
              {onHold ? (
                <FormattedMessage id="upcomingPayments.autoPay.notEnrolled" />
              ) : (
                <FormattedMessage
                  id="upcomingPayments.autoPay.enrolled"
                  values={{
                    frequency: <b>{frequency?.toLowerCase()}</b>,
                  }}
                />
              )}
            </p>
          </div>
        </Col>
        <Col xs={12} sm={6} className="text-center text-sm-end">
          <Button
            className=""
            onClick={() => navigate('/payment')}
            variant="outline-primary"
          >
            <FormattedMessage id="upcomingPayments.makePayment" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UpcomingPayments;
