import {
  OnDeckLogo,
  Caret,
  FormattedMessageDiv,
  HamburgerBar,
  ErrorCard,
  CloseIcon,
} from '@odmonorepo/core-components';
import { SalesAgentInfoCard, useAuth0, useParties, useUser } from 'merchant-lib';
import { useState } from 'react';
import {
  Button,
  Card,
  Collapse,
  ListGroup,
  Nav,
  Offcanvas,
  Row,
  Stack
} from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export function SideNav() {
  const [show, setShow] = useState(false);
  const { status, logout } = useUser();
  const navigate = useNavigate();
  const { logout: auth0Logout } = useAuth0();
  const { hasLOC, partiesData, isErrorParties, isLoadingParties,isOptSalesAgentEnabled } = useParties();
  const intl = useIntl();
  const handleLogout = () => {
    status === 401 ? auth0Logout('/sign-out') : logout();
  };

  if (isErrorParties)
    return (
      <div style={{ width: '272px' }}>
        <ErrorCard name={intl.formatMessage({ id: 'title.sideNav' })} />
        <Button
          className="text-start fw-bold fixed-bottom"
          variant="link"
          onClick={() => handleLogout()}
        >
          Sign Out
        </Button>
      </div>
    );

  return (
    <>
      <Card className="d-lg-none d-flex flex-row p-2 rounded-0 shadow-none sticky-top">
        <HamburgerBar
          role="button"
          className="d-flex"
          onClick={() => setShow(true)}
        />
        <OnDeckLogo className="ms-5 mb-2" onClick={() => navigate('/')} />
      </Card>
      <Offcanvas show={show} onHide={() => setShow(false)} responsive="lg" className='w-auto'>
        <Card
          className="rounded-0 vh-100 shadow-none pt-5 pb-3 bg-light overflow-y-auto overflow-x-hidden sticky-top"
          style={{ width: '272px' }}
        >
          <Stack>
            {show && (
              <CloseIcon
                role="button"
                onClick={() => setShow(false)}
                className="d-flex justify-content-end me-4"
              />
            )}
            <OnDeckLogo
              className="ms-5 mb-8"
              role="button"
              onClick={() => navigate('/')}
            />
            <Collapse in={!isLoadingParties}>
              <div>
                <div className="ms-5 fw-bold lh-sm">
                  {partiesData?.user?.name}
                </div>
                <div className="fs-7 ms-5 lh-sm">
                  {partiesData?.parties[0]?.businesses[0]?.legalName}
                </div>
                <a
                  className="fs-7 ms-5 mt-3 td text-decoration-none lh-sm"
                  role="button"
                  onClick={() => navigate('/profile')}
                >
                  Edit Profile
                </a>
              </div>
            </Collapse>
            <ListGroup className="rounded-0 my-4" variant="flush">
              <Nav className="flex-column">
                <NavItem href="/">Dashboard</NavItem>
                {hasLOC && <NavItem href="/drawfunds">Draw Funds</NavItem>}
                <NavItem href="/payment">Make a Payment</NavItem>
                <NavItem href="/documents">Documents</NavItem>
                {isOptSalesAgentEnabled === false && <NavItem href=''><ContactUs/></NavItem>}
              </Nav>
            </ListGroup>
          </Stack>
          <Row className='text-start' >
            {isOptSalesAgentEnabled && <SalesAgentInfoCard className='border-bottom border-light-medium-gray'/>}
            <Button className="fw-bold mt-2"  style={{width:'150px'}} variant='link' onClick={() => handleLogout()}>Sign Out</Button>
          </Row>
        </Card>
      </Offcanvas>
    </>
  );
}

const NavItem = ({ href, children, ...props }) => {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <ListGroup.Item variant="light" role="button">
      <Nav.Link
        href={href}
        className={`text-body px-2 ${isActive ? 'fw-bold' : ''}`}
      >
        {children}
      </Nav.Link>
    </ListGroup.Item>
  );
};

export const ContactUs = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  return (
    <div className="overflow-hidden">
      <Stack
        direction="horizontal"
        onClick={() => setOpen((o) => !o)}
        role="button"
      >
        Contact Us
        <Caret className="ms-auto me-2" direction={open ? 'up' : 'down'} />
      </Stack>
      <Stack
        className="fs-7"
        gap={1}
        style={{ height: open ? '90px' : '0px', transition: 'all 500ms' }}
      >
        <a
          className="text-decoration-none mt-3"
          href={`tel: ${intl.formatMessage({
            id: 'contactInfo.phone.helpline',
          })}`}
        >
          <FormattedMessageDiv id="contactInfo.phone.helpline" />
        </a>
        <a
          className="text-decoration-none"
          href={`mailto: ${intl.formatMessage({
            id: 'common.customerServiceEmail',
          })}`}
        >
          <FormattedMessageDiv id="common.customerServiceEmail" />
        </a>
        <FormattedMessageDiv id="contactInfo.businessHours" />
      </Stack>
    </div>
  );
};

export default SideNav;
