import {
  useApplication,
  getLDR,
  getBusiness,
  usePersons,
  getRenewal,
  useLoan,
} from 'merchant-lib';
import { useState, useEffect } from 'react';
import { LoanStep } from './LoanAppForm';

export const loanStep = (): {
  step: LoanStep | undefined;
  stepLoading: boolean;
  stepError: boolean;
  showRenewal: boolean;
} => {
  const { applicationData, isErrorApplication, isLoadingApplication } = useApplication();
  const { hasOpenBankRequest, ldrError, ldrLoading } = getLDR();
  const { activeTL, loanError } = useLoan();
  const { renewal } = getRenewal();
  const [step, setStep] = useState<number>();
 
  useEffect(() => {
    if (!isLoadingApplication && !ldrLoading) {
      switch (applicationData?.status) {
        case 'SUBMITTED':
          hasOpenBankRequest
            ? setStep(LoanStep.BANK_INFO)
            : setStep(LoanStep.RECEIVED);
          break;
        case 'REROUTED_LEGACY': 
          setStep(LoanStep.RECEIVED);
          break;
        case 'CLOSED_LOST':
        case 'CLOSED_WON':
        case 'NOT_APPROVED':
          setStep(LoanStep.DECLINED);
          break;
        case 'APPROVED':
          setStep(LoanStep.RECEIVED);
          break;
        default:
          setStep(LoanStep.BUSINESS_STEP);
          break;
      }
    }
  }, [applicationData, hasOpenBankRequest, ldrLoading, isLoadingApplication]);

  return {
    step,
    stepLoading: step === undefined,
    stepError: isErrorApplication || ldrError || loanError,
    showRenewal: applicationData?.status !== 'APPROVED' && activeTL?.percentagePaidDown >= 25 && renewal?.termLoanEligible
  };
};

export const loanDefaultValues = () => {
  const {
    businessDetails,
    businessAddress,
    businessPhoneNumber,
    isBusinessLoading,
  } = getBusiness();
  const { person, personAddress, personPhoneNumber, isLoading } = usePersons();

  return {
    loading: isBusinessLoading || isLoading,
    defaultValues: {
      business: {
        id: businessDetails?.id,
        legalName: businessDetails?.legalName,
        address: {
          lineOne: businessAddress?.lines[0],
          ...businessAddress,
        },
        phone: businessPhoneNumber,
        dba: String(businessDetails?.doingBusinessAs !== undefined),
        dbaName: businessDetails?.doingBusinessAs,
        legalEntityType: businessDetails?.legalEntityType,
      },
      person: {
        id: person?.id,
        firstName: person?.firstName,
        lastName: person?.lastName,
        address: {
          lineOne: personAddress?.lines[0],
          ...personAddress,
        },
        homePhone: personPhoneNumber,
      },
    },
  };
};
