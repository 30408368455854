import useSWR from 'swr';
import { useUser } from '../..';

export interface MapiBusinessData {
  countryIso3: string;
  id: number;
  name: string;
  legalName: string;
  inceptionDate: string;
  yearsInBusiness: number;
  preferredProduct: string;
  taxId: string;
  businessIdType: string;
  maskedTaxId: string;
  phoneNumber: string;
  address1: string;
  city: string;
  stateProv: string;
  postalCode: string;
  bestContactNumber: string;
  estimatedAnnualRevenue: number;
  averageBankBalance: number;
  legalEntityType: string;
  merchant: number;
  stateOfIncorporation: string;
  ussoleProprietorWithoutTaxId: boolean;
}

export interface useMapiBusinessProps {
  isLoading: boolean;
  business: MapiBusinessData[];
  fullName: string;
  isError: any;
}

export function useMapiBusiness(): useMapiBusinessProps {
  const apiUrl = process.env['NX_PUBLIC_SPICEROAD_URL']

  const { status, isLoading: userLoading} = useUser();
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/merchant-api/business/user/3531614`)
 
  return {
    business: data,
    fullName: data?.fullName,
    isLoading: isLoading || userLoading,
    isError: error
  }
}

export default useMapiBusiness;
