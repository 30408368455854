import { Stack } from 'react-bootstrap';
import { CredentialCard } from '../../Features/Profile/Cards/CredentialCard';
import InfoCard from '../../Features/Profile/Cards/InfoCard';
import PersonalCard from '../../Features/Profile/Cards/PersonalCard';

export const PersonalProfile = () => {
  return (
    <Stack gap={4}>
      <InfoCard />
      <PersonalCard />
      <CredentialCard />
    </Stack>
  );
};

export default PersonalProfile;
