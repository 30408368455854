import React from 'react';
import { Offer } from "merchant-lib";
import { Card, Row, Col, Stack } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { codToCents } from '../codToCents';
import { TripleGreenCheck } from '@odmonorepo/core-components';
import { Tooltip, FormattedMessageDiv, FormattedNumberDiv } from '@odmonorepo/core-components';

interface LocOfferProps {
  offer: Offer;
}

const LocOfferCard = ({ offer }: LocOfferProps ) => {
  const intl = useIntl();

  return (
    <Card data-testid="term-loan-container" className="text-center p-md-6 p-4">
      <Row className="p-3 mb-6 pb-0">
        <Col>
          <Row>
            <FormattedNumberDiv
              className="h1 mb-0"
              value={offer.pricedDetails.lineAmount}
              style={'currency'}
              currency='USD'
              minimumFractionDigits={0}
            />
          </Row>
          <Row className="mt-2">
            <FormattedMessageDiv className='fw-bold' id="offerReview.locApprovedCreditLimit" />
          </Row>
        </Col>
      </Row>

      <Card className="bg-light-gray p-5 shadow-none" style={{borderRadius: 0}}>
        <Row className="flex-md-row text-start">
          <Stack gap={3} className="col-md-3">
            <FormattedMessageDiv className='fw-bold' id='offerPreview.details' />
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerReview.locApprovedCreditLimit" />
              <FormattedNumberDiv value={offer.pricedDetails.lineAmount} style="currency" currency="USD" className="ms-auto" />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerPreview.term" />
              <Tooltip
                color="blue"
                title={intl.formatMessage({ id: 'offerPreview.termTooltipText' },
                  { term: offer.pricedDetails.term })}
              />
              <FormattedNumberDiv value={offer.pricedDetails.term} suffix=" Months" className="ms-auto" />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerPreview.cod" />
              <Tooltip
                color="blue"
                title={intl.formatMessage({ id: 'offerPreview.codTooltipText' })}
              />
              <FormattedNumberDiv
                value={codToCents(offer.pricedDetails.trueCentsOnDollar)}
                suffix="&cent;"
                className="ms-auto"
                roundingMode="ceil"
                maximumFractionDigits={1}
                minimumFractionDigits={1}
              />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerPreview.apr" />
              <FormattedNumberDiv value={offer.pricedDetails.trueAPR} suffix="%" className="ms-auto" />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerReview.rid" />
              <Tooltip
                title={intl.formatMessage(
                  { id: 'offerPreview.requiredInitialDrawTooltipText' }
                )}
              />
              <FormattedNumberDiv value={offer.requiredInitialDraw} style="currency" currency="USD" className="ms-auto" />
            </Stack>
          </Stack>
          <div className="d-none d-md-block vr border border-light-gray p-0 border-opacity-50 mx-5" />
          <hr className="d-block d-md-none border border-gray my-5 border-opacity-75" />
          <Stack gap={3} className="col-md-3">
            <FormattedMessageDiv className='fw-bold' id='offerPreview.costAndFees' />
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerReview.monthlyFee" />
              <FormattedNumberDiv value={offer.pricedDetails.monthlyFee} style="currency" currency="USD" className="ms-auto" />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerPreview.originationFee" />
              <FormattedNumberDiv value={offer.pricedDetails.loanOriginationFee} style="currency" currency="USD" className="ms-auto" />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerReview.returnedPaymentFee" />
              <FormattedNumberDiv value={offer.returnedPaymentFee} style="currency" currency="USD" className="ms-auto" />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerReview.lateFee" />
              <FormattedNumberDiv value={offer.lateFee} style="currency" currency="USD" className="ms-auto" />
            </Stack>
            <Stack direction="horizontal">
              <FormattedMessageDiv id="offerPreview.paymentFrequency" />
              <div className="ms-auto">
                {offer.pricedDetails.paymentFrequency.toUpperCase() === 'WEEKLY' ? 'Weekly' : 'Monthly'}
              </div>
            </Stack>
          </Stack>
        </Row>
      </Card>

      <div className={'py-3'}>
        <TripleGreenCheck
          text1={intl.formatMessage({ id: 'offerReview.fastFunding' })}
          text2={intl.formatMessage({ id: 'offerReview.drawInterest' })}
          text3={intl.formatMessage({ id: 'offerReview.creditHistory' })}
        />
      </div>
    </Card>
  );

};

export default LocOfferCard;
