export const appState = {
  getTLProduct() {
    return tlProduct;
  },
  getLOCProduct() {
    return locProduct;
  },
  getLOCDetails() {
    return locDetails;
  },
  getUpcomingPayments() {
    return upComing;
  },
  getActivities() {
    return activities;
  },
  getDocuments() {
    return docs;
  },
  getProfile() {
    return profile;
  },
  getInfo() {
    return info;
  },
  getBusinessProfile() {
    return businessProfile;
  },
  getFilterDocuments() {
    return filterDocuments;
  },
  getOfferSelectionData() {
    return offerSelectionData;
  },
  getSelectedOffer() {
    return selectedOffer;
  },
  getOfferPreviewData() {
    return offerPreviewData;
  },
};

const activities = [
  {
    date: '2/12/48',
    type: 'Draw',
    status: 'Completed',
    product: 'Term Loan',
    amount: 78.54,
  },
  {
    date: '2/12/48',
    type: 'Loan Payment',
    status: 'Error',
    product: 'Term Loan',
    amount: 78.54,
  },
  {
    date: '2/12/48',
    type: 'Loan Payment',
    status: 'Pending',
    product: 'Term Loan',
    amount: 78.54,
  },
];

export const getActivities = () => {
  return activities;
};
const docs = [
  { name: 'Document one', date: '12/31/23' },
  { name: 'Document two', date: '8/12/23' },
  { name: 'Document three', date: '5/24/35' },
];

const tlProduct = {
  title: 'Term Loan',
  loanId: 'abcd',
  loanAmount: 25000,
  leftPosNumber: 6000,
  rightPosNumber: 19000,
};

const locProduct = {
  title: ' Line of Credit',
  loanId: 'abcd',
  loanAmount: 25000,
  leftPosNumber: 5000,
  rightPosNumber: 20000,
};
const locDetails = {
  availableCredit: 5000,
  id: '21234324ab',
  creditLimit: 4400,
  balanceOutstanding: 1400,
  status: 'ACTIVE',
  drawOnHold: false,
};

const upComing = {
  frequency: 'WEEKLY',
  loanStatus: 'ARRANGEMENT',
  onHold: false,
  payment: {
    amount: 10000,
    scheduledDate: '12/18/23',
  },
  estimatedPaymentsRemaining: 1023.34,
};

const profile = {
  'Home Phone': '6019238322',
  'Alternate Phone': '651323223',
  Email: 'profile@enova.com',
  Address: '765 Wacker St, Ste 12, Chicago, IL, 60604',
};

const info = {
  fullName: 'Abigail Riley',
  email: 'xxx@enova.com',
  businessName: 'I Love Monorepo',
};

const businessProfile = {
  'Legal Business Name': 'Guardians Neighborhood Market Co.',
  'Tax ID': '******0719',
  'Entity Type': 'C_CORP',
  Phone: '+15551118888',
  'Business Address': '123 E 4th St, Ste 12, Chicago, IL, 60604',
  'Business Bank Account': '********9485',
};

const filterDocuments = [
  {
    loanId: '5965',
    loanType: 'Line of Credit',
    documentType: 'Loan Agreement',
    fileName: 'OnDeckLoanContract',
    createdOn: '2/23/2021',
  },
  {
    loanId: '4984',
    loanType: 'Term Loan',
    documentType: 'Loan Agreement',
    fileName: 'OnDeckLoanContract',
    createdOn: '3/30/2021',
  },
  {
    loanId: '4984',
    loanType: 'Term Loan',
    documentType: 'Monthly Statement',
    fileName: 'OnDeckMonthlyStatement',
    createdOn: '3/19/2022',
  },
  {
    loanId: '4984',
    loanType: 'Term Loan',
    documentType: 'Monthly Statement',
    fileName: 'OnDeckMonthlyStatement',
    createdOn: '2/14/2022',
  },
  {
    loanId: '5965',
    loanType: 'Line of Credit',
    documentType: 'Monthly Statement',
    fileName: 'OnDeckMonthlyStatement',
    createdOn: '2/23/2022',
  },
  {
    loanId: '5965',
    loanType: 'Line of Credit',
    documentType: 'Monthly Statement',
    fileName: 'OnDeckMonthlyStatement',
    createdOn: '6/10/2021',
  },
];

const offerSelectionData = [
  {
    loanAmount: 1002,
    term: 3,
    paymentFrequency: 'DAILY',
    offerId: '123abcdefg',
    payment: 120,
    minLineAmount: 5000,
    maxLineAmount: 80000,
    prePaymentDiscountPct: null,
    previousLoan: true,
  },
  {
    loanAmount: 1200,
    term: 6,
    paymentFrequency: 'DAILY',
    offerId: 'abcdefg1234',
    payment: 200,
    minLineAmount: 10000,
    maxLineAmount: 60000,
    prePaymentDiscountPct: null,
    previousLoan: false,
  },
  {
    loanAmount: 5200,
    term: 12,
    paymentFrequency: 'DAILY',
    offerId: 'ddeefg1234',
    payment: 200,
    minLineAmount: 5000,
    maxLineAmount: 80000,
    prePaymentDiscountPct: 100,
    previousLoan: false,
  },
];

const selectedOffer = {
  loanAmount: 25000,
  term: 2,
  paymentFrequency: 'DAILY',
  offerId: '123abcdefg',
  centsOnDollar: 100,
  oFeePercentage: 2,
};

const offerPreviewData = {
  term: 2,
  paymentFrequency: 'DAILY',
  offerId: '123abcdefg',
  apr: 5,
  repayment: 0,
  loanAmount: 1200,
  disbursementAmount: 0,
  originationFee: 0,
  loanOriginationFee: 0,
  totalLoanCost: 1002,
  trueAPR: 0,
  cod: 100,
  payment: 0,
  capital: 0,
  remainingPrincipal: 0,
  prePaymentDiscountPct: 25
};

export const offerFullDataTermLoan = {
  offerId: 'new-apptype-id',
  term: 9,
  maxLineAmount: 1000,
  minLineAmount: 10000,
  defaultPaymentFrequency: "Weekly",
  originationFeePct: 5,
  prePaymentDiscountPct: 100,
  centsOnDollar: 1,
  product: "TermLoan",
  pricedDetails : {
    lineAmount: 40000,
    trueCentsOnDollar: 1.35,
    trueAPR: 137.69,
    paymentFrequency: 'Daily',
    payment: 428.57,
    disbursementAmount: 28960,
    totalAmountPaidBack: 53999.82,
    loanOriginationFee: 0,
    term: 9,
    totalLoanCost: 15039.82,
    averageMonthlyPayment: 8999.97,
    modelId: "ID",
    monthlyFee: 0
  },
  presentableDisbursementAmt: 10,
  requiredInitialDraw: 10000,
  returnedPaymentFee: 25,
  lateFee: 10,
  prePaymentDiscountRelatedOfferIds: null,
  userActionStatus: 'SELECTED'
};


export const offerFullDataLOC = {
  offerId: 'new-apptype-id',
  term: 9,
  maxLineAmount: 1000,
  minLineAmount: 10000,
  defaultPaymentFrequency: "Weekly",
  originationFeePct: 5,
  prePaymentDiscountPct: 100,
  centsOnDollar: 1,
  product: 'LineOfCredit',
  pricedDetails : {
    lineAmount: 40000,
    trueCentsOnDollar: 1.35,
    trueAPR: 137.69,
    paymentFrequency: 'Daily',
    payment: 428.57,
    disbursementAmount: 28960,
    totalAmountPaidBack: 53999.82,
    loanOriginationFee: 0,
    term: 9,
    totalLoanCost: 15039.82,
    averageMonthlyPayment: 8999.97,
    modelId: "ID",
    monthlyFee: 0
  },
  presentableDisbursementAmt: 10,
  requiredInitialDraw: 10000,
  returnedPaymentFee: 25,
  lateFee: 10,
  prePaymentDiscountRelatedOfferIds: null,
  userActionStatus: 'SELECTED'
};

export const transactions = [
  {
    loanId: '3198618732718467',
    loanType: 'FIXED_TERM',
    currency: 'USD',
    transactionSetId: '46532015',
    initiatedDate: '2021-03-11T00:00:00Z',
    clearedDate: '2021-03-11T00:00:00Z',
    type: 'Loan Payment',
    amount: 192.06,
    status: 'C',
    beginningBalance: 9189.26,
    remainingBalance: 9097.2,
    principal: 72.473877,
    interest: 19.586123,
  },
  {
    loanId: '3198618732718467',
    loanType: 'FIXED_TERM',
    currency: 'USD',
    transactionSetId: '46531926',
    initiatedDate: '2021-03-04T00:00:00Z',
    clearedDate: '2021-03-04T00:00:00Z',
    type: 'Loan Payment',
    amount: 92.06,
    status: 'P',
    beginningBalance: 9281.32,
    remainingBalance: 9189.26,
    principal: 72.300401,
    interest: 19.759599,
  },
  {
    loanId: '3198618732718467',
    loanType: 'LINE_OF_CREDIT',
    currency: 'USD',
    transactionSetId: '46531881',
    initiatedDate: '2021-02-26T00:00:00Z',
    clearedDate: '2021-02-26T00:00:00Z',
    type: 'Draw',
    amount: 92.06,
    status: 'E',
    beginningBalance: 9373.38,
    remainingBalance: 9281.32,
    principal: 72.12734,
    interest: 19.93266,
  },
];

export const mockDashboardData = {
  'http://localhost:3333/v1/parties': {
    user: {
      id: '905722e8-a2f9-4daa-bbae-97859a6d635b',
      name: 'Joshua',
      href: `/persons/905722e8-a2f9-4daa-bbae-97859a6d635b`,
    },
    salesAgentInfoEnabled: true,
    parties: [
      {
        id: '4545345',
        loans: [
          {
            href: 'http://localhost:3333/v1/loans/765432',
            id: '765432',
            loanType: 'FIXED_TERM',
          },
        ],
        businesses: [
          {
            id: '320a748d-594b-431f-adbc-d415dcb9fbab',
            legalName: 'Neighborhood Market',
            href: `/businesses/320a748d-594b-431f-adbc-d415dcb9fbab`
          }
        ],
      },
    ],
  },
  'http://localhost:3333/v3/orchestration/current_application': {
    status: 'APPROVED',
      id: 'absd123',
  },
  'http://localhost:3333/v2/offers': {
    offers: [
      {
        offerId: '1b40e99e-b963-11ee-95f8-6307f7a2265c',
        applicationId: 'c2be006d-2f44-45c3-9f9b-e727b8dbb67b',
        userActionStatus: 'NOT_SELECTED',
        presentationStatus: 'ACTIVE',
        offerName: 'Test 3',
        product: 'TermLoan',
        term: 12,
        minLineAmount: 25000.0,
        maxLineAmount: 45000.0,
        buyRate: 1.25,
        maxSellRate: 1.35,
        centsOnDollar: 1.25,
        defaultPaymentFrequency: 'Weekly',
        eligiblePaymentFrequencies: 'Daily,Weekly',
        currencyCode: 'USD',
        originationFeePct: 0.0,
        prePaymentDiscountPct: 0.0,
        collectionType: null,
        effectiveDate: '2024-01-22T20:15:19.871710Z',
        expirationDate: '2024-02-21T20:16:19.871912Z',
        rescindedDate: null,
        createdAt: '2024-01-22T20:16:20.462220Z',
        createdBy: '87iASPtkzb6Z4z5zYhUUWoexC3mz9kMB@clients',
        updatedAt: '2024-01-22T20:16:20.462220Z',
        updatedBy: '87iASPtkzb6Z4z5zYhUUWoexC3mz9kMB@clients',
        pricedDetails: null,
        presentableDisbursementAmt: null,
        requiredInitialDraw: null,
        returnedPaymentFee: null,
        lateFee: null,
        prePaymentDiscountRelatedOfferIds: null,
      }
    ],
  },
  'http://localhost:3333/v1/locs/*': {
    availableCredit: 11422,
      creditLimit: 15422,
      id: '1234567',
      status: 'ACTIVE',
      type: 'LINE_OF_CREDIT',
      balanceOutstanding: 4000,
      instantFunding: {
      cardInfo: {
        registered: false,
      },
    },
  },
  'http://localhost:3333/v1/loans/*': {
    id: '1598295487236072',
      status: 'ACTIVE',
      type: 'FIXED_TERM',
      approvedAmount: 140000.0,
      balanceOutstanding: 42209.96,
      balancePaid: 126629.88,
      percentagePaidDown: 75.0,
  },
  'http://localhost:3333/v1/loans/*/payment/next': {
    delay: 1000,
      data: {
      loanId: '1234567',
        loanStatus: 'NORMAL',
        onHold: false,
        frequency: 'MONTHLY',
        currency: 'USD',
        payment: {
        amount: 92.06,
          scheduledDate: '2021-04-30T00:00:00Z',
      },
      estimatedPaymentsRemaining: 4,
    },
  },
  'http://localhost:3333/v1/locs/*/payment/next': {
    delay: 1000,
      data: {
      loanId: '1234567',
        loanStatus: 'NORMAL',
        onHold: false,
        frequency: 'MONTHLY',
        currency: 'USD',
        nextPaymentAmount: 102.1,
        nextPaymentDate: '2021-04-30T00:00:00Z',
        estimatedPaymentsRemaining: 4,
    },
  },
  'http://localhost:3333/v1/loans/transactions': [
    ...transactions,
    ...transactions,
  ],
    'http://localhost:3333/v1/documents': [
    {
      documentId: 123,
      documentType: 'MONTHLY_STATEMENT',
      fileName: 'OnDeckMonthlyStatement.pdf',
      createdOn: '2022-03-19T22:56:53.423+00:00',
      loanId: '7534985345734984',
      loanType: 'FIXED_TERM',
    },
    {
      documentId: 456,
      documentType: 'MONTHLY_STATEMENT',
      fileName: 'OnDeckMonthlyStatement.pdf',
      createdOn: '2022-02-29T22:56:53.423+00:00',
      loanId: '',
    },
    {
      documentId: 789,
      documentType: 'MONTHLY_STATEMENT',
      fileName: 'OnDeckMonthlyStatement.pdf',
      createdOn: '2022-02-23T22:56:53.423+00:00',
      loanId: '4534985345735965',
      loanType: 'LINE_OF_CREDIT',
    },
  ],
    'http://localhost:3333/v1/renewal': {
    loceligible: false,
      termLoanEligible: true,
  },
  'http://localhost:3333/apex/renewal/login': {
    method: 'post',
      ok: true,
  },
  'http://localhost:3333/v1/sales-agent-info/current': {
    name: 'Sales Agent Name',
      phone: '5555551234'
  },
  'http://localhost:3333/v1/documents/token/*': 'jwt',
}

export const loanValidationResponseInvalid = {
  "errorMessage": [
    {
      "code": 206,
      "description": "Tax ID does not match with the given merchant",
      "type": "VALIDATION_ERROR"
    }
  ],
  "header": {
    "requestID": "string",
    "requestDate": "2024-08-16T04:00:00Z",
    "client": "CUSTOMER_PORTAL_TURBO"
  },
  "valid": false
}

export const loanValidationResponseValid = {
  "header": {
    "requestID": "1724680725313",
    "requestDate": "2024-08-26T04:00:00Z",
    "client": "CUSTOMER_PORTAL_TURBO"
  },
  "valid": false,
  "errorMessage": [
    {
      "code": 206,
      "description": "Requested loan amount is not valid",
      "type": "VALIDATION_ERROR"
    },
    {
      "code": 206,
      "description": "loan boarding is not allowed for the given funding source",
      "type": "VALIDATION_ERROR"
    }
  ]
}
