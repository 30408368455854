import { Alert, Button, Row, Stack } from 'react-bootstrap';
import { FormattedMessageDiv, LoadingCard } from '@odmonorepo/core-components';
import { Card } from '@odmonorepo/core-components';
import { useNavigate } from 'react-router-dom';
import { useLOC } from 'merchant-lib';

export function IFDebitCardDetails() {
  const { activeLOC, locLoading } = useLOC();
  const navigate = useNavigate();

  if (locLoading) return <LoadingCard />;

  const cardEnding = activeLOC?.instantFunding?.cardInfo?.account;
  const cardNetwork = activeLOC?.instantFunding?.cardInfo?.network;
  const formattedCardNetwork =
    cardNetwork &&
    cardNetwork[0].toUpperCase() + cardNetwork.substring(1).toLowerCase();

  return (
    <Stack gap={5}>
      {activeLOC && (
        <Card>
          <Alert variant="success">
            <FormattedMessageDiv
              className="fs-6 fw-bold"
              id="instantFunding.success.header"
            />
            <FormattedMessageDiv id="instantFunding.success.para" />
          </Alert>
          <FormattedMessageDiv
            id="instantfunding.draw.cardInfo"
            values={{ network: formattedCardNetwork, lastFour: cardEnding }}
          />
        </Card>
      )}
      <Row className="justify-content-center text-center g-3" xs={1} md={2}>
        <Button
          style={{ width: '311px' }}
          className="px-0 col mx-2"
          variant="outline-primary"
          onClick={() => navigate('/')}
        >
          <FormattedMessageDiv id="instantFunding.returnToDashBoard.btn" />
        </Button>
        <Button
          style={{ width: '311px' }}
          className="px-0 col mx-2"
          onClick={() => navigate('/drawfunds')}
        >
          <FormattedMessageDiv id="instantFunding.drawFunds.btn" />
        </Button>
      </Row>
    </Stack>
  );
}

export default IFDebitCardDetails;
