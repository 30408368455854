import { OnDeckLogo, PartnerLogo } from '@odmonorepo/core-components';
import { useApplication } from 'libs/merchant-lib/src/API';
import { Stack } from 'react-bootstrap';

export function CobrandedHeader() {
  const {applicationData} = useApplication();
  const cobranding = applicationData?.metadata?.createdBy;
  return (
    <Stack direction="horizontal">
      <OnDeckLogo width="150px" className="me-auto me-sm-0 py-3"/>
      {cobranding && 
        <div className="d-sm-block d-sm-flex text-center vertical-align-center">
          <div className="fs-7 p-0 ps-sm-5 pe-sm-3 pt-sm-1">in partnership with</div>
          <PartnerLogo partner={cobranding}/>
        </div>
      }
    </Stack>
  );
}

export default CobrandedHeader;
