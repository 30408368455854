export const offersMock = {
  "offers": [
    {
      "offerId": "cec84de6-e1c8-11ec-9231-059b111d1fa2",
      "term": 12,
      "maxLineAmount": 35000,
      "minLineAmount": 6000,
      "defaultPaymentFrequency": "Daily",
      "originationFeePct": 2.5,
      "centsOnDollar": 1.45,
      "product": "TermLoan",
      "pricedDetails": {
        "lineAmount": 35000,
        "trueCentsOnDollar": 1.15,
        "trueAPR": 31.8532,
        "paymentFrequency": "Daily",
        "payment": 128.17,
        "disbursementAmount": 48750,
        "totalAmountPaidBack": 57498.84,
        "loanOriginationFee": 1250,
        "term": 6,
        "totalLoanCost": 8748.84,
        "modelId": "onDeck",
        "averageMonthlyPayment": 4791.57,
        "monthlyFee": 20
      },
      "presentableDisbursementAmt": 44010.57,
      "requiredInitialDraw": 100.45,
      "returnedPaymentFee": 25.01,
      "lateFee": 10.15,
      "prePaymentDiscountPct": 100,
      "prePaymentDiscountRelatedOfferIds": {
        "25": "8de564ec-3e5d-432f-b062-c4d6f7bbe97b"
      },
      "presentationStatus" : "ACTIVE",
      "userActionStatus": "SELECTED"
    },
    {
      "offerId": "dec84de6-e1c8-ed32-1234-059b111d1fa3",
      "term": 9,
      "maxLineAmount": 45000,
      "minLineAmount": 8000,
      "defaultPaymentFrequency": "Daily",
      "originationFeePct": 2.5,
      "centsOnDollar": 1.35,
      "product": "TermLoan",
      "pricedDetails": {
        "lineAmount": 45000,
        "trueCentsOnDollar": 1.15,
        "trueAPR": 32.8532,
        "paymentFrequency": "Daily",
        "payment": 228.17,
        "disbursementAmount": 48750,
        "totalAmountPaidBack": 57498.84,
        "loanOriginationFee": 1250,
        "term": 9,
        "totalLoanCost": 8748.84,
        "modelId": "onDeck",
        "averageMonthlyPayment": 4791.57
      },
      "presentableDisbursementAmt": 44010.57,
      "prePaymentDiscountPct": 100,
      "prePaymentDiscountRelatedOfferIds": {
        "25": "b497f856-7dfd-4294-ae92-1890bdf22771"
      },
      "presentationStatus" : "ACTIVE"
    },
    {
      "offerId": "fec84de6-e1c8-ad32-8542-059b111d1fa4",
      "term": 6,
      "maxLineAmount": 50000,
      "minLineAmount": 9000,
      "defaultPaymentFrequency": "Daily",
      "originationFeePct": 2.5,
      "centsOnDollar": 1.25,
      "product": "TermLoan",
      "pricedDetails": {
        "lineAmount": 50000,
        "trueCentsOnDollar": 1.15,
        "trueAPR": 33.8532,
        "paymentFrequency": "Daily",
        "payment": 328.17,
        "disbursementAmount": 48750,
        "totalAmountPaidBack": 57498.84,
        "loanOriginationFee": 1250,
        "term": 12,
        "totalLoanCost": 8748.84,
        "modelId": "onDeck",
        "averageMonthlyPayment": 4791.57,
        "monthlyFee": 20
      },
      "presentableDisbursementAmt": 44010.57,
      "returnedPaymentFee": 25.0,
      "requiredInitialDraw": 0.0,
      "lateFee": 10.0,
      "prePaymentDiscountPct": null,
      "prePaymentDiscountRelatedOfferIds": {},
      "presentationStatus" : "ACTIVE"
    },
    {
      "offerId": "8de564ec-3e5d-432f-b062-c4d6f7bbe97b",
      "term": 12,
      "maxLineAmount": 35000,
      "minLineAmount": 6000,
      "defaultPaymentFrequency": "Daily",
      "originationFeePct": 2.5,
      "centsOnDollar": 1.45,
      "product": "TermLoan",
      "pricedDetails": {
        "lineAmount": 35000,
        "trueCentsOnDollar": 1.15,
        "trueAPR": 31.8532,
        "paymentFrequency": "Daily",
        "payment": 128.17,
        "disbursementAmount": 48750,
        "totalAmountPaidBack": 57498.84,
        "loanOriginationFee": 1250,
        "term": 6,
        "totalLoanCost": 8748.84,
        "modelId": "onDeck",
        "averageMonthlyPayment": 4791.57,
        "monthlyFee": 20
      },
      "presentableDisbursementAmt": 44010.57,
      "requiredInitialDraw": 100.45,
      "returnedPaymentFee": 25.01,
      "lateFee": 10.15,
      "prePaymentDiscountPct": null,
      "prePaymentDiscountRelatedOfferIds": {
        "100": "cec84de6-e1c8-11ec-9231-059b111d1fa2"
      },
      "presentationStatus" : "ACTIVE"
    },
    {
      "offerId": "b497f856-7dfd-4294-ae92-1890bdf22771",
      "term": 9,
      "maxLineAmount": 45000,
      "minLineAmount": 8000,
      "defaultPaymentFrequency": "Daily",
      "originationFeePct": 2.5,
      "centsOnDollar": 1.35,
      "product": "TermLoan",
      "pricedDetails": {
        "lineAmount": 45000,
        "trueCentsOnDollar": 1.15,
        "trueAPR": 32.8532,
        "paymentFrequency": "Daily",
        "payment": 228.17,
        "disbursementAmount": 48750,
        "totalAmountPaidBack": 57498.84,
        "loanOriginationFee": 1250,
        "term": 9,
        "totalLoanCost": 8748.84,
        "modelId": "onDeck",
        "averageMonthlyPayment": 4791.57
      },
      "presentableDisbursementAmt": 44010.57,
      "prePaymentDiscountPct": null,
      "prePaymentDiscountRelatedOfferIds": {
        "100": "dec84de6-e1c8-ed32-1234-059b111d1fa3"
      },
      "presentationStatus" : "ACTIVE"
    }]
}
